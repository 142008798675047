import functions from "./functions";
var expenses = {
  async getAll(priCod, filCod) {
    const start = Date.now();

    var filters = {
      PRI_COD: { eq: parseInt(priCod) },
      FIL_COD: { eq: parseInt(filCod) },

      // PRI_COD: { eq: 16832 },
      // FIL_COD: { eq: 3 },
    };

    var query = `
    query expenses($filters:ExpenseFiltersInput!){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
          expenses(filters:$filters){
            data{
              id
              attributes{
                FIL_COD
                PRI_COD
                IMP_DES_NOME
                CTP_DES_NOME
                PID_MNY_VALORMN
                
              }
            }
          }
      }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination expenses time: ${(end - start) / 1000} s`
      );
    console.log(JSON.stringify(res));
    const pagination = res?.data?.expenses?.meta?.pagination;
    const values = functions.sanitize(res.data.expenses.data);
    return { values, pagination };
  },
  async getWithPagination(priCod, filCod, page, pageSize) {
    const start = Date.now();

    var filters = {
      PRI_COD: { eq: parseInt(priCod) },
      FIL_COD: { eq: parseInt(filCod) },
      PID_VLD_STATUS: { eq: 1 },
    };

    var query = `
    query expenses($filters:ExpenseFiltersInput!){
          expenses(filters:$filters, pagination:{page:${page},pageSize:${pageSize}}){
            meta{
              pagination{
                total
                pageSize
                page
              }
            }
            data{
              id
              attributes{
                FIL_COD
                PRI_COD
                IMP_DES_NOME
                CTP_DES_NOME
                PID_MNY_VALORMN
                PID_VLD_STATUS
                
              }
            }
          }
      }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination expenses time: ${(end - start) / 1000} s`
      );
    console.log(JSON.stringify(res));
    const pagination = res?.data?.expenses?.meta?.pagination;
    const values = functions.sanitize(res.data.expenses.data);
    return { values, pagination };
  },
};

export default expenses;
