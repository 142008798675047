<template>
  <Bar
    v-if="chartOptions && chartData"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin
);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: [
    "chartId",
    "datasetIdKey",
    "width",
    "height",
    "cssClasses",
    "styles",
    "plugins",
    "chartData",
    "chartOptions",
  ],

  data() {
    return {};
  },
};
</script>
