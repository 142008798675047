<template>
  <div class="mt-2 mx-1" style="width: calc(100% - 5px)">
    <span style="font-size: 12px">{{ label }}</span>
    <div style="border: 1px solid lightblue; border-radius: 2px; padding: 10px">
      <span style="margin: 5px">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Grid-Field",
  props: ["label", "value"],

  components: {},

  data() {
    return {};
  },

  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  async mounted() {},
  created() {},
  //loadedCity
  watch: {},
  methods: {},
};
</script>

<style scoped></style>
