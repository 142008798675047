import Vue from "vue";
import Vuetify from "vuetify/lib";
import i18n from "@/i18n";
import "@/sass/overrides.sass";

Vue.use(Vuetify);

// const theme = {
//   primary: "#0E8CBB", //012935 , hebel:008F85
//   actionSecundary: "#EBD146",
//   accent: "#00b7e3",
//   actionButton: "#263352",
// };

const theme = {
  //primary: "#015384", //sainte mari ,
  primary: "#171B66", //global comex
  secundary: "#3E8AC7",
  actionSecundary: "#f85c2c",
  accent: "#00b7e3",
  actionButton: "#263352",
  //chartPrimaryColor: "#015384",
  chartPrimaryColor: "#015384",
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
