<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
</style>
