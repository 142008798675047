<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container r v-if="user?.role?.id == 6" fluid>
        <v-row>
          <h4 style="margin: auto">Pagamentos</h4>
        </v-row>

        <!-- <v-row>
        <img
          width="600px"
          src="@/assets/img/banner4.png"
          style="margin: auto"
        />
      </v-row> -->
        <v-row>
          <v-file-input
            small-chips
            multiple
            label="Arquivos CNAB"
            v-model="files"
            @change="handleFileLoaded"
          ></v-file-input>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="jsonData"
          item-key="raw"
          class="custom-table elevation-1 font-weight-black elevation-1"
          color="blue"
          hide-default-footer
          :server-items-length.sync="jsonData.length"
        >
        </v-data-table>
      </v-container>
      <v-container v-else>
        <p>Acesso não autorizado</p>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "Finance",

  data() {
    return {
      files: [],
      jsonData: [],
      headers: [
        { text: "ControleBanco", value: "ControleBanco" },
        { text: "ControleLote", value: "ControleLote" },
        { text: "ControleRegistro", value: "ControleRegistro" },
        { text: "ServicoNumDoRegistro", value: "ServicoNumDoRegistro" },
        { text: "ServicoSegmento", value: "ServicoSegmento" },
        { text: "ServicoCnab", value: "ServicoCnab" },
        { text: "ServicoMovimento", value: "ServicoMovimento" },
        { text: "CodigoDeBarras", value: "CodigoDeBarras" },
        // { text: "ServicoCnab", value: "ServicoCnab"},
        // { text: "ServicoCnab", value: "ServicoCnab"},

        // ControleBanco: line.substring(0, 3),
        //     ControleLote: line.substring(3, 7),
        //     ControleRegistro: line.substring(7, 8),
        //     ServicoNumDoRegistro: line.substring(8, 13),
        //     ServicoSegmento: line.substring(13, 14),
        //     ServicoCnab: line.substring(14, 15),
        //     ServicoMovimento: line.substring(15, 17),
        //     CodigoDeBarras: line.substring(17, 61),
      ],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {},
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          const lines = content.split("\n");
          const jsonData = lines.map((line) => ({
            text: line.trim(),
          }));

          // Emit the JSON data to the parent component
          this.$emit("file-loaded", jsonData);
        };

        reader.readAsText(file);
      }
    },
    handleFileLoaded(data) {
      console.log("handleFileLoaded");
      // this.jsonData = data;

      const file = this.files?.[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const content = e.target.result;
          const lines = content.split("\r\n");
          const jsonData = lines.map((line) => ({
            ControleBanco: line.substring(0, 3),
            ControleLote: line.substring(3, 7),
            ControleRegistro: line.substring(7, 8),
            ServicoNumDoRegistro: line.substring(8, 13),
            ServicoSegmento: line.substring(13, 14),
            ServicoCnab: line.substring(14, 15),
            ServicoMovimento: line.substring(15, 17),
            CodigoDeBarras: line.substring(17, 61),
            BeneficiarioTipo: line.substring(61, 62),
            BeneficiarioNumero: line.substring(62, 77),
            BeneficiarioNome: line.substring(77, 107),
            Vencimento: line.substring(107, 115),

            ValorDoTitulo: line.substring(115, 130),
            //Vencimento: line.substring(115, 130),
            QtdMoeda: line.substring(130, 145),
            CodigoMoeda: line.substring(145, 147),
            NumeroDoDocumento: line.substring(147, 162),

            AgenciaCobradora: line.substring(162, 167),
            Dv: line.substring(167, 168),
            Praca: line.substring(168, 178),

            Carteira: line.substring(178, 179),

            EspecieTitulo: line.substring(179, 181),

            DataEmissao: line.substring(181, 189),

            Juros: line.substring(189, 204),

            CodDesconto: line.substring(204, 205),

            DataDesconto: line.substring(205, 213),

            Desconto: line.substring(213, 228),

            CodProtesto: line.substring(228, 229),

            PrazoProtesto: line.substring(229, 231),

            DataLimite: line.substring(231, 239),

            Cnab: line.substring(239, 240),

            raw: line,
          }));

          let keys = Object.keys(jsonData[0]);
          this.headers = keys.map((el) => ({ text: el, value: el }));

          // Emit the JSON data to the parent component
          //this.$emit("file-loaded", jsonData);

          //console.log(JSON.stringify({ jsonData }));
          this.jsonData = jsonData;
          console.log(JSON.stringify({ lines }));
        };

        reader.readAsText(file);
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
