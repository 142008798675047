<template>
  <v-text-field
    v-model="password"
    :id="id"
    :type="showPassword ? 'text' : 'password'"
    :error-messages="errorMessages"
    name="password"
    :label="label"
    ref="password"
    prepend-icon="mdi-lock"
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    @click:append="showPassword = !showPassword"
    color="primary"
  ></v-text-field>
</template>

<script>
//  //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
export default {
  name: "v-password-field",
  props: ["errorMessages", "value", "id", "label"],
  data() {
    return {
      showPassword: false,
    };
  },

  computed: {
    password: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
