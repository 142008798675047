<template>
  <v-container fluid class="ma-0 pa-0" style="background-color: #f5f6fd">
    <v-container
      fluid
      v-if="user?.role?.id !== 6"
      style="height: 100vh; width: 100%"
    >
      <v-row class="mt-10">
        <h4 style="margin: auto">Acesso não autorizado</h4>
      </v-row>

      <v-row>
        <img
          width="600px"
          src="@/assets/img/banner4.png"
          style="margin: auto"
        />
      </v-row>
    </v-container>

    <v-container v-else fluid style="">
      <v-container
        v-if="user && user.cmn025 && user.profileId == 3"
        fluid
        style="display: flex"
      >
        <v-row>
          <h2 style="margin-left: 50px" class="primary--text">
            {{ user.cmn025.dpeNomFantasia }}
          </h2></v-row
        >
      </v-container>

      <v-container
        fluid
        style="display: flex"
        class="dashboard-with;min-heigth:100vh;"
      >
        <v-container style="width: 400px; margin: 0px">
          <v-card class="mt-6"
            ><v-card-title style="font-color: #6613d0!"
              ><span>Filtros</span>
            </v-card-title>
            <v-card-text class="mt-4">
              <AutocompleteSelect
                label="Encomendante"
                :items="optionsEncomendantes"
                v-model="chipsEncomendantes"
                v-if="!(user && user.cmn025 && user.profileId == 3)"
              ></AutocompleteSelect>

              <AutocompleteSelect
                label="Exportador"
                :items="optionsExportadores"
                v-model="chipsExportadores"
              ></AutocompleteSelect>

              <v-select
                class="mx-5"
                :items="optionsShipped"
                v-model="filterShipped"
                item-text="text"
                item-value="value"
                style="
                  color: black;
                  font-size: 16px !important;
                  font-weight: 600 !important;
                "
              >
              </v-select>

              <v-container>
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                  class="mt-4"
                >
                  <template class="mt-3" v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Início"
                      v-model="dateFilterStart"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      color="actionButton"
                      event-color="actionButton"
                      style="
                        color: black;
                        font-size: 16px !important;
                        font-weight: 600 !important;
                      "
                    ></v-text-field>
                  </template>
                  <v-card class="ma-0 pa-0">
                    <v-date-picker
                      v-model="dateFilterStart"
                      type="date"
                      no-title
                      @input="menu = false"
                      reactive
                      scrollable
                      color="actionButton"
                    ></v-date-picker>
                  </v-card>
                </v-menu>
              </v-container>
              <v-container>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                  class="mt-3"
                >
                  <template class="mt-3" v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Fim"
                      v-model="dateFilterEnd"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      color="actionButton"
                      event-color="actionButton"
                      style="
                        color: black;
                        font-size: 16px !important;
                        font-weight: 600 !important;
                      "
                    ></v-text-field>
                  </template>
                  <v-card class="ma-0 pa-0">
                    <v-date-picker
                      v-model="dateFilterEnd"
                      type="date"
                      no-title
                      @input="menu2 = false"
                      reactive
                      scrollable
                      color="actionButton"
                    ></v-date-picker>
                  </v-card>
                </v-menu>
              </v-container>
              <v-alert
                :value="alert"
                prominent
                text
                type="info"
                icon="mdi-information-outline"
                @click="alert = false"
              >
                Favor selecionar ao menos um filtro Encomendante ou Exportador
              </v-alert>
              <v-btn class="primary" @click="getData" :disabled="loading"
                >Buscar
                <v-progress-circular
                  v-show="loading"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn> </v-card-text
          ></v-card>
        </v-container>

        <v-container fluid style="">
          <v-container v-if="mounting">
            <v-progress-circular
              indeterminate
              color="blue"
            ></v-progress-circular>
          </v-container>
          <v-row v-if="!kpis.dateStart || mounting" style="">
            <v-container style="margin: auto; width: 50%; margin-top: 20px">
              <img
                width="600px"
                src="@/assets/img/banner4.png"
                style="border-radius: 20px"
              />
            </v-container>
          </v-row>

          <v-container fluid v-if="kpis.dateStart" style="right: 0px">
            <!-- <p>Abertura -> Embarque</p>
            <v-row>
              <v-col cols="7">
                <v-data-table
                  :headers="kpiHeaders"
                  :items="kpis.aberturaEmbarqueAcc"
                  item-key="name"
                  class="custom-table"
                  ref="dataTable"
                ></v-data-table>
              </v-col>
              <v-col cols="5">
                <bar-chart
                  chart-id="chartAberturaEmbarque"
                  :chart-data="chartDataCollection"
                  :chart-options="chartOptions"
                ></bar-chart>
              </v-col>
            </v-row> -->

            <v-container v-for="(sla, index) in slas">
              <h4>{{ sla.title }}</h4>
              <br />
              <v-row>
                <v-col cols="7">
                  <v-data-table
                    :headers="kpiHeaders"
                    :items="sla?.items"
                    item-key="name"
                    class="custom-table"
                    ref="dataTable"
                  ></v-data-table>
                </v-col>
                <v-col cols="5">
                  <bar-chart
                    chart-id="chartAberturaEmbarque"
                    :chart-data="sla.chartData"
                    :chart-options="chartOptions"
                  ></bar-chart>
                </v-col>
              </v-row>
            </v-container>

            <br />

            <!-- <p>Embarque -> Chegada</p>
            <v-row v-if="kpis?.ansEmbarqueChegadaAcc">
              <v-col cols="7">
                <v-data-table
                  :headers="kpiHeaders"
                  :items="kpis.ansEmbarqueChegadaAcc"
                  item-key="name"
                  class="custom-table"
                  ref="dataTable2"
                ></v-data-table>
              </v-col>
              <v-col cols="5">
                <p>
                  <bar-chart
                    chart-id="chartAberturaEmbarque2"
                    :chart-data="chartDataCollection"
                    :chart-options="chartOptions"
                  ></bar-chart>
                </p>
              </v-col>
            </v-row> -->
          </v-container>
        </v-container>
      </v-container>
      <v-container
        v-if="processes.length > 0"
        class="dashboard-with"
        style=""
        pa-15
      >
        <v-data-table
          :headers="processesHeaders"
          :items="processes"
          item-key="name"
          class="custom-table"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
          <template v-slot:item.CANAL="{ item }">
            <!-- <span v-date-format="item.CANAL.seconds * 1000"></span> -->
            <v-icon v-if="item.CANAL == 'VERMELHO'" color="red">
              mdi-circle
            </v-icon>
            <v-icon v-if="item.CANAL == 'VERDE'" color="green">
              mdi-circle
            </v-icon>
            <v-icon v-if="item.CANAL == 'AMARELO'" color="#FFD700">
              mdi-circle
            </v-icon>
            {{ item.CANAL }}
          </template>

          <template v-slot:item.REGISTRO_DI="{ item }">
            <span
              v-if="item.REGISTRO_DI"
              v-date-format="item.REGISTRO_DI"
            ></span>
          </template>

          <template v-slot:item.PRI_DTA_ABERTURA="{ item }">
            <span v-date-format="item.PRI_DTA_ABERTURA"></span>
          </template>

          <template v-slot:item.CNT_DTA_PREV_EMB="{ item }">
            <span v-date-format="item.CNT_DTA_PREV_EMB"></span>
          </template>

          <template v-slot:item.CNT_DTA_EMBARQUE="{ item }">
            <span v-date-format="item.CNT_DTA_EMBARQUE"></span>
          </template>

          <template v-slot:item.DTA_EMBARQUE_BL="{ item }">
            <span v-date-format="item.DTA_EMBARQUE_BL"></span>
          </template>

          <template v-slot:item.PREVISAO_CHEGADA="{ item }">
            <span v-date-format="item.PREVISAO_CHEGADA"></span>
          </template>

          <template v-slot:item.CNT_DTA_CHEGADA="{ item }">
            <span v-date-format="item.CNT_DTA_CHEGADA"></span>
          </template>

          <template v-slot:item.aberturaEmbarque="{ item }">
            <span
              v-decimal-format="
                getDayIntervals(item.PRI_DTA_ABERTURA, item.DTA_EMBARQUE_BL)
              "
            ></span>
          </template>
          <template v-slot:item.embarqueChegada="{ item }">
            <span
              v-decimal-format="
                getDayIntervals(item.DTA_EMBARQUE_BL, item.DTA_CHEGADA_BL)
              "
            ></span>
          </template>
        </v-data-table>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
//import Chartist from "chartist";
import en from "vuetify/lib/locale/en";
import { mapState, mapMutations } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import DatePicker from "../../components/fields/DatePicker.vue";
import ProgressLinearInfo from "../../components/fields/ProgressLinearInfo.vue";
import ChartCard from "../../components/charts/ChartCard.vue";
import PieChart from "../../components/charts/PieChart.vue";
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import orderers from "../../../../libraries/data/orderers";
import countries from "../../../../libraries/data/countries";
import BarChart from "../../components/charts/BarChart0.vue";

export default {
  name: "SLA",
  components: {
    DatePicker,
    ProgressLinearInfo,
    ChartCard,
    PieChart,
    DoughnutChart,
    BarChart,
    AutocompleteSelect,
  },
  created() {},
  data() {
    return {
      alert: false,
      encomendantes: orderers,
      optionsEncomendantes: [],
      optionsShipped: [
        { value: 0, text: "TODOS" },

        { value: 2, text: "A EMBARCAR" },
        { value: 1, text: "EM TRÂNSITO" },
        { value: 3, text: "CHEGOU" },
      ],
      optionsExportadores: [],
      loading: false,
      menu: false,
      menu2: false,

      countries,
      mounting: true,
      //kpis: {},
      kpiHeaders: [
        {
          text: "Range (dias)",

          align: "start",
          sortable: false,
          value: "range",
          width: "20px",
        },
        {
          text: "Contagem",
          value: "count",
          width: "20px",
          sortable: false,
        },
      ],

      slas: [
        {
          title: "ABERTURA -> EMBARQUE",
          value: "aberturaEmbarqueAcc",
          chartData: {},
          items: [],
        },
        {
          title: "REGISTRO -> DEFERIMENTO LI",
          value: "registroDeferimentoLiAcc",
          chartData: {},
          items: [],
        },
        {
          title: "EMBARQUE -> CHEGADA",
          value: "embarqueChegadaAcc",
          chartData: {},
          items: [],
        },

        {
          title: "CHEGADA -> REGISTRO DI",
          value: "chegadaRegistroDiAcc",
          chartData: {},
          items: [],
        },

        {
          title: "REGISTRO -> DESEMBARAÇO DI",
          value: "registroDesembaracoDiAcc",
          chartData: {},
          items: [],
        },

        {
          title: "DESEMBARAÇO -> FATURAMENTO",
          value: "desembaracoFaturadoAcc",
          chartData: {},
          items: [],
        },

        {
          title: "FATURAMENTO -> ENTREGA",
          value: "faturadoEntregaAcc",
          chartData: {},
          items: [],
        },
      ],

      datalogs: [],

      processesHeaders: [
        // {
        //   text: "Referência interna",
        //   value: "PRI_ESP_REFCLIENTE",
        //   width: "20px",
        //   sortable: false,
        // },
        // {
        //   text: "Referência do cliente",
        //   value: "INV_ESP_PO",
        //   width: "20px",
        //   sortable: false,
        // },
        { text: "PRI_COD`", value: "PRI_COD", width: "100px" },
        { text: "Canal", value: "CANAL", width: "100px" },
        { text: "Data de abertura", value: "PRI_DTA_ABERTURA" },

        { text: "Registro DI", value: "REGISTRO_DI" },

        {
          text: "Previsão embarque",
          value: "CNT_DTA_PREV_EMB",
          sortable: false,
        },
        { text: "CNT Embarque", value: "CNT_DTA_EMBARQUE", sortable: false },

        { text: "Embarque BL", value: "DTA_EMBARQUE_BL", sortable: false },

        {
          text: "Previsão chegada",
          value: "PREVISAO_CHEGADA",
          sortable: false,
        },
        { text: "Chegada", value: "CNT_DTA_CHEGADA", sortable: false },

        {
          text: "Abertura -> Embarque",
          value: "aberturaEmbarque",
          sortable: false,
        },
        {
          text: "Embarque -> Chegada",
          value: "embarqueChegada",
          sortable: false,
        },
      ],

      //Table

      page: 1,
      itemsPerPage: 10,
      search: "",
      sortBy: "PRI_DTA_ABERTURA",
      sortDesc: true,
      //

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
        scales: {
          x: {
            ticks: {
              color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
            },
          },
          y: {
            ticks: {
              color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              return "";
            },
          },
        },
      },
    };
  },
  async mounted() {
    this.encomendantes = await strapiApi.enterprises.getOptions();

    const adminView = this.user?.role?.id == 1 || this.user?.role?.id == 6;
    if (!adminView) {
      this.optionsEncomendantes = this.user?.enterprises?.map(
        (el) => el && el.dpeNomPessoa?.substring(0, 20)
      );
      if (this.user?.email == "demo@stmarie.com.br") {
        this.optionsEncomendantes = this.optionsEncomendantes.map((el) =>
          el?.includes("ARTEX") ? "DEMO" : el
        );
      }

      if (this.optionsEncomendantes?.length == 1) {
        this.chipsEncomendantes = this.user?.enterprises.map(
          (el) => el && el.dpeNomPessoa?.substring(0, 20)
        );
      }
      await this.updateExporters(this.chipsEncomendantes);
      //Update processes headers
    } else {
      this.optionsEncomendantes = this.encomendantes.map(
        (el) => el && el.substring(0, 20)
      );

      await this.updateExporters(this.chipsEncomendantes);
    }

    if (this.kpis?.aberturaEmbarqueAcc) {
      this.updateGui();
    }
    this.mounting = false;
  },

  watch: {
    dateFilterStart(val, oldVal) {
      if (val === oldVal) return;
      this.updateExporters();
    },
    dateFilterStart(val, oldVal) {
      if (val === oldVal) return;
      this.updateExporters();
    },
  },

  computed: {
    ...mapState([
      "invoice",
      "processes",
      "invoices_items",
      "transports",
      "containers",
      "countriesAcc",
      "summaryData",
      "kpis",
    ]),
    ...mapState({
      user: (state) => state.auth.user,
    }),

    modeAdmin() {
      return (
        this.user &&
        this.user.role &&
        this.user.role.id &&
        (this.user.role.id == 6 || this.user.role.id == 1)
      );
    },

    chipsEncomendantes: {
      get() {
        return this.$store.state.chipsEncomendantes;
      },
      async set(value) {
        await this.updateExporters(value);
        this.setChipsEncomendantes(value);
      },
    },
    serverItemsLength: {
      get() {
        return this.summaryData?.totals && this.summaryData?.totals[0]?.count;
      },
    },

    chipsExportadores: {
      get() {
        return this.$store.state.chipsExportadores;
      },
      async set(value) {
        this.setChipsExportadores(value);
      },
    },

    filterShipped: {
      get() {
        return this.$store.state.filterShipped;
      },
      async set(value) {
        this.setFilterShipped(value);
      },
    },

    dateFilterStart: {
      get() {
        return this.$store.state.dateFilterStart;
      },
      set(value) {
        this.setDateFilterStart(value);
      },
    },
    dateFilterEnd: {
      get() {
        return this.$store.state.dateFilterEnd;
      },
      set(value) {
        this.setDateFilterEnd(value);
      },
    },
  },

  methods: {
    ...mapMutations({
      setInvoices: "SET_INVOICES",
      setProcesses: "SET_PROCESSES",
      setInvoicesItems: "SET_INVOICES_ITEMS",
      setChipsEncomendantes: "SET_CHIPS_ENCOMENDANTES",
      setDateFilterStart: "SET_DATE_FILTER_START",
      setDateFilterEnd: "SET_DATE_FILTER_END",
      setTransports: "SET_TRANSPORTS",
      setContainers: "SET_CONTAINERS",
      setCountriesAcc: "SET_COUNTRIES_ACC",
      setChipsExportadores: "SET_CHIPS_EXPORTADORES",
      setFilterShipped: "SET_FILTER_SHIPPED",
      setSummaryData: "SET_SUMMARY_DATA",
      setKpis: "SET_KPIS",
    }),

    getDayIntervals(d1, d2) {
      const date1 = new Date(d1);
      const date2 = new Date(d2);
      const diffTime = date2 - date1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },

    async updateExporters(encomendantes) {
      var myExporters = await strapiApi.processes.getExporters(
        new Date(`${this.dateFilterStart}T03:00:00.000Z`),
        new Date(`${this.dateFilterEnd}T03:00:00.000Z`),
        encomendantes
      );
      this.optionsExportadores = myExporters.map((el) => el.substring(0, 20));

      for (var a = 0; a < this.chipsExportadores.length; a++) {
        var check =
          this.optionsExportadores &&
          this.optionsExportadores.find(
            (el) => el == this.chipsExportadores[a]
          );
        if (!check) {
          this.chipsExportadores.splice(
            this.chipsExportadores.indexOf(this.chipsExportadores[a]),
            1
          );
        }
      }
    },

    activateAlert() {
      this.alert = true;
    },
    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      var dateStart = new Date(`${this.dateFilterStart}T03:00:00.000Z`);
      var dateEnd = new Date(`${this.dateFilterEnd}T03:00:00.000Z`);

      let enterprises = this.chipsEncomendantes;
      if (
        this.chipsEncomendantes.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        enterprises = this.optionsEncomendantes;
      }

      //AJUSTE Ricardo 30/06/23
      if (this.user?.email == "demo@stmarie.com.br") {
        enterprises = enterprises.map((el) =>
          el?.includes("DEMO") ? "ARTEX" : el
        );
      }

      //alert(JSON.stringify(enterprises));
      let exporters = this.chipsExportadores;
      if (
        this.chipsExportadores.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        exporters = this.optionsExportadores;
      }

      let { values, pagination } = await strapiApi.processes.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.search,
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped
      );

      if (this.user?.email == "demo@stmarie.com.br") {
        values = values.map((el) => ({
          ...el,
          DPE_NOM_PESSOA_LIG: el?.DPE_NOM_PESSOA_LIG?.includes("ARTEX")
            ? "DEMO"
            : el.DPE_NOM_PESSOA_LIG,
        }));
      }

      let processes = values;
      let transports = [];
      let invoices = [];
      let containers = [];
      let invoiceItems = [];

      for (var i = 0; i < processes.length; i++) {
        var process = processes[i];
        processes[i].STATUS_EMBARQUE = processes[i].DTA_EMBARQUE_BL
          ? "EM TRÂNSITO"
          : "A EMBARCAR";

        if (processes[i].DTA_CHEGADA_BL)
          processes[i].STATUS_EMBARQUE = "CHEGOU";

        if (
          processes[i] &&
          processes[i].invoices &&
          processes[i].invoices.length > 0
        ) {
          processes[i].INC_ESP_SIGLA = processes[i].invoices[0].INC_ESP_SIGLA;
          processes[i].INV_ESP_PO = processes[i].invoices[0].INV_ESP_PO;
        }
        processes[i].QTY_TRANSPORTS =
          process.transports && process.transports.length;

        var transportsFromProcesses = process.transports;
        if (transportsFromProcesses && transportsFromProcesses.length > 0) {
          processes[i].CNT_DTA_PREV_EMB =
            transportsFromProcesses[0].CNT_DTA_PREV_EMB;
          processes[i].CNT_DTA_EMBARQUE =
            transportsFromProcesses[0].CNT_DTA_EMBARQUE;
          processes[i].PREVISAO_CHEGADA =
            transportsFromProcesses[0].PREVISAO_CHEGADA;
          processes[i].CNT_DTA_CHEGADA =
            transportsFromProcesses[0].CNT_DTA_CHEGADA;

          for (var b = 0; b < transportsFromProcesses.length; b++) {
            var transport = transportsFromProcesses[b];
            var containersFromProcesses = transport.containers;
            for (var c = 0; c < containersFromProcesses.length; c++) {
              containersFromProcesses[c].ARMADOR = transport.ARMADOR;
            }
            if (containersFromProcesses && containersFromProcesses.length > 0)
              containers = containers.concat(containersFromProcesses);
          }
        }

        transports = transports.concat(transportsFromProcesses);

        //invoices
        var invoicesFromProcesses = process.invoices;
        if (invoicesFromProcesses && invoicesFromProcesses.length > 0)
          for (var b = 0; b < invoicesFromProcesses.length; b++) {
            var invoice = invoicesFromProcesses[b];
            var itemsFromProcesses = invoice.items;

            if (itemsFromProcesses && itemsFromProcesses.length > 0)
              invoiceItems = invoiceItems.concat(itemsFromProcesses);
          }
        invoices = invoices.concat(invoicesFromProcesses);
      }

      this.setInvoices(invoices);

      this.setInvoicesItems(invoiceItems);

      this.setTransports(transports);

      this.setContainers(containers);

      this.setProcesses(processes);

      this.setProcesses(values);

      //alert(JSON.stringify({ values }));
    },

    async getData() {
      this.alert = false;
      this.loading = true;
      var dateStart = new Date(`${this.dateFilterStart}T03:00:00.000Z`);
      var dateEnd = new Date(`${this.dateFilterEnd}T03:00:00.000Z`);

      this.setInvoices([]);

      this.setInvoicesItems([]);

      let enterprises = this.chipsEncomendantes;
      if (
        this.chipsEncomendantes.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        enterprises = this.optionsEncomendantes;
      }

      let exporters = this.chipsExportadores;
      if (
        this.chipsExportadores.length == 0 &&
        this?.user?.role?.id !== strapiApi.roleIds.admin
      ) {
        exporters = this.optionsExportadores;
      }

      //Get Summary

      //Get Summary
      let summaryData = await strapiApi.processes.getSummary(
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped
      );
      // this.summaryData = summaryData;
      this.setSummaryData(summaryData);

      //get kpis
      let kpis = await strapiApi.processes.getKpis(
        dateStart,
        dateEnd,
        enterprises,
        exporters,
        this.filterShipped
      );
      this.setKpis(kpis);

      this.doSearch();

      this.loading = false;

      this.updateGui();
    },

    updateGui() {
      for (let i = 0; i < this.slas.length; i++) {
        //Setup data
        let sla = this.slas[i];

        let values = this.kpis[sla.value];

        let labels = [
          "<=0",
          "1-15",
          "16-30",
          "31-45",
          "46-60",
          "> 60",
          "Não definido",
        ];

        let chartData = {
          labels: [],
          datasets: [
            {
              lineTension: 0,
              label: 2023,
              fontColor: "#FFB116",
              backgroundColor: "#FFB116",
              borderColor: "#FFB116",
              pointBackgroundColor: "#FFB116",
              fill: false,
              data: [],
            },
          ],
        };

        //chartData.labels = values.map((el) => el.range);
        //chartData.datasets[0].data = values.map((el) => parseInt(el.count));
        chartData.labels = labels;
        for (var j = 0; j < labels.length; j++) {
          let label = labels[j];
          let value = values.find((el) => el.range == label);
          console.log(JSON.stringify({ value }));
          let count = value?.count ? parseInt(value.count) : 0;
          chartData.datasets[0].data.push(count);
        }

        this.slas[i].chartData = chartData;
        this.slas[i].items = values;
      }
    },
  },
};
</script>

<style>
body {
}
.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}

.dashboard-with {
  max-width: 2400px;
}

table {
  font-family: "Poppins";
}
td {
  border-right: 1px solid #dddddd;
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: "Poppins";
}

th {
  border-right: 1px solid #dddddd;
  font-size: 16px !important;
  font-weight: 500 !important;
  vertical-align: top;
  padding: 10px !important;
}

.subtitle {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.text-highlight {
  color: #37a7b6;
  font-size: 32px !important;
  font-weight: 800 !important;
}
/* p {
  font-weight: 500 !important;
} */

/* span {
  font-weight: 500 !important;
} */
h4 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

.frozen-columns {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff; /* Adjust background color if needed */
}

.dashboard-background {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}

.header-frozen {
  background-color: white;
  margin: 10px;
  border-bottom: 0.1px solid #dddddd;
  border-left: 0.1px solid #dddddd;
  position: absolute;

  z-index: 2;
  border-width: 0.01px;
  border-color: #dddddd;

  height: 93px;
  width: 91px;
  margin-top: -34px;
  margin-left: -11px;
  padding: 0px;
  padding-top: 10px;
  padding-left: 6px;
}
</style>
