<template>
  <v-container fluid style="width: 100%; height: 100%">
    <br />
    <br />
    <v-tabs color="primary accent-4" active-class="primary white--text">
      <v-tab style="background-color: lightgray">Meus dados</v-tab>
      <v-tab style="background-color: lightgray">Rastreios</v-tab>
      <v-tab v-if="adminHomolog" style="background-color: lightgray"
        >Notificações</v-tab
      >

      <v-tab-item pa-2>
        <v-card v-if="selectedIndex == 0" class="mx-auto" max-width="1000">
          <v-toolbar class="primary" dark> Dados de usuário</v-toolbar>

          <v-container v-if="userEdit" class="pa-4">
            <v-text-field v-model="userEdit.username" label="Nome:">
            </v-text-field>
            <v-text-field v-model="userEdit.email" label="Email:" disabled>
            </v-text-field>

            <v-checkbox
              v-model="userEdit.emailNotificationActive"
              label="Desejo receber notificações via email"
            ></v-checkbox>

            <v-checkbox
              v-model="userEdit.whatsappNotificationActive"
              label="Desejo receber notificações via whatsapp"
            ></v-checkbox>

            <v-text-field
              v-show="userEdit.whatsappNotificationActive"
              v-model="userEdit.phoneNumber"
              label="Celular"
              v-mask="'+##(##)#########'"
              hint="Entre com o número no formato +55(##)#########"
              :rules="[phoneNumberRule]"
            >
            </v-text-field>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="actionButton"
                @click="saveUserData()"
                :disabled="saving"
                ><span v-show="!saving && !saveAlert">Salvar</span>
                <span v-show="saveAlert"
                  >Salvo <v-icon color="green">mdi-check</v-icon></span
                >
                <v-progress-circular
                  v-show="saving"
                  indeterminate
                  color="white"
                ></v-progress-circular
              ></v-btn>
            </v-card-actions>
            <v-alert
              type="success"
              icon="mdi-check"
              class="pa-2"
              v-model="saveAlert"
            >
              Usuário atualizado com sucesso
            </v-alert>
            <v-alert
              type="error"
              icon="mdi-alert"
              class="pa-2"
              v-model="errorAlert"
              >{{ errorMsg }}</v-alert
            >
            <br />
            <br />
            <v-card>
              <v-toolbar class="primary" dark> Alterar senha:</v-toolbar>
              <v-card-text>
                <v-password-field v-model="currentPassword" label="Senha atual">
                </v-password-field>
                <v-password-field v-model="newPassword" label="Nova senha">
                </v-password-field>
                <p
                  class="red--text"
                  v-if="
                    newPassword != '' &&
                    confirmNewPassword != '' &&
                    newPassword != confirmNewPassword
                  "
                >
                  As senhas precisam ser iguais!
                </p>
                <p
                  class="red--text"
                  v-if="newPassword != '' && newPassword.length < 6"
                >
                  A senha precisa ter mais de 6 caracteres
                </p>

                <v-password-field
                  v-model="confirmNewPassword"
                  label="Confirmar nova senha"
                >
                </v-password-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="actionButton"
                  :disabled="updatingPassword"
                  @click="
                    changePassword(
                      currentPassword,
                      newPassword,
                      confirmNewPassword
                    )
                  "
                  ><span v-show="!updatingPassword && !successAlert"
                    >Salvar</span
                  >

                  <v-progress-circular
                    v-show="updatingPassword"
                    indeterminate
                    color="white"
                  ></v-progress-circular
                ></v-btn>
              </v-card-actions>
              <v-alert
                type="success"
                icon="mdi-check"
                class="pa-2"
                v-model="successAlert"
              >
                Senha atualizada com sucesso!
              </v-alert>
              <v-alert
                type="error"
                icon="mdi-alert"
                class="pa-2"
                v-model="errorAlert2"
                >{{ errorMsg2 }}</v-alert
              >
            </v-card>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item style="">
        <CardTrackings> </CardTrackings>
      </v-tab-item>
      <v-tab-item v-if="adminHomolog" style="">
        <v-container r v-if="user?.role?.id == 6">
          <!-- <v-row class="ma-2">
            <h4 style="margin: auto">Notificações</h4>
          </v-row> -->
          <UserNotifications></UserNotifications>
        </v-container>
      </v-tab-item>
    </v-tabs>

    <v-row>
      <v-col>
        <v-container fluid> </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import CardTrackings from "./CardTrackings.vue";
import UserNotifications from "./UserNotifications.vue";
import VPasswordField from "../../components/fields/VPasswordField.vue";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "Account",
  components: {
    CardTrackings,
    VPasswordField,
    UserNotifications,
  },
  data() {
    return {
      selectedIndex: 0,
      items: [
        {
          title: "Usuário",
          value: 0,
        },
        {
          title: "Rastreios",
          value: 1,
        },
      ],
      userStations: [],
      usinaSelecionada: null,
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      actualPassword: null,
      userObj: null,
      userEdit: null,
      credentials: null,
      successAlert: false,
      saveAlert: false,
      errorAlert: false,
      errorAlert2: false,
      errorMsg: "",
      stations: [],
      saving: false,
      updatingPassword: false,
      trackings: [],
      trackingHeaders: [
        { text: "Data de criação", value: "createdAt" },
        {
          text: "Usuário",
          align: "start",
          sortable: false,
          value: "userName",
        },
        {
          text: "Encomendante",
          value: "encomendante",
        },
        { text: "BL", value: "blContainersRef" },
        { text: "Container", value: "containerNumber" },
      ],

      errorAlert2: false,
      errorMsg2: "",
    };
  },
  async mounted() {
    let user = await strapiApi.users.getById(this.user.id);

    this.$store.commit("SET_USER", user);
    this.userEdit = Object.assign({}, this.user);
  },
  methods: {
    phoneNumberRule(value) {
      const phoneRegex = /^\+\d{2}\(\d{2}\)\d{8,9}$/; // Regex para o formato +55(19)98415-0291

      if (!phoneRegex.test(value)) {
        return "Formato de telefone inválido. Use o formato +55(11)999999999.";
      }

      return true; // A regra de validação passou
    },
    closeAlerts() {
      this.saveAlert = false;
      this.errorAlert = false;
      this.errorAlert2 = false;
      this.successAlert = false;
    },
    async saveUserData() {
      this.saving = true;
      let ans;
      if (this.phoneNumberRule(this.userEdit.phoneNumber) === true) {
        ans = await strapiApi.users.update(this.userEdit);
      } else {
        ans = {
          error: {
            message: "Formato de telefone incorreto",
          },
        };
      }

      this.saving = false;
      console.log(JSON.stringify({ ans }));

      await this.openAlertsSave(ans);
    },

    async openAlertsSave(ans) {
      if (ans.id) {
        this.user.username = this.userEdit.username;
        this.user.emailNotificationActive =
          this.userEdit.emailNotificationActive;

        this.user.whatsappNotificationActive =
          this.userEdit.whatsappNotificationActive;

        this.user.phoneNumber = this.userEdit.phoneNumber;
        this.$store.commit("SET_USER", this.user);
        this.saveAlert = true;
      } else if (ans?.error) {
        this.errorAlert = true;
        this.errorMsg = ans?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
    goToSite(siteId) {
      this.$router.push({ path: `/site/${siteId}` });
    },
    async changePassword(currentPassword, newPassword, confirmNewPassword) {
      if (currentPassword == "") {
        this.setAlert({
          alertMsg: "Necessário senha atual para atualizar informações",
          alertType: "error",
        });

        return;
      } else if (
        newPassword == "" ||
        newPassword.length < 6 ||
        newPassword !== confirmNewPassword
      ) {
        this.setAlert({
          alertMsg: "Verifique sua nova senha e tente novamente!",
          alertType: "error",
        });
        return;
      }
      this.updatingPassword = true;

      const ans = await strapiApi.users.changePassword(
        currentPassword,
        newPassword,
        confirmNewPassword
      );

      console.log(JSON.stringify(ans));
      this.openAlerts(ans);
      this.updatingPassword = false;
    },
    openAlerts(condition) {
      if (condition.jwt) {
        this.currentPassword = "";
        this.newPassword = "";
        this.confirmNewPassword = "";
        this.successAlert = true;
      } else if (condition?.error) {
        this.errorAlert2 = true;
        this.errorMsg2 = condition?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    adminHomolog() {
      return (
        `${process.env.VUE_APP_ENV}` == "HOMOLOG" && this.user?.role?.id == 6
      );
    },

    envVars() {
      return {
        VUE_APP_ENV: `${process.env.VUE_APP_ENV}`,
        NODE_ENV: `${process.env.NODE_ENV}`,
        // VUE_APP_ENV : `${process.env.VUE_APP_ENV}`,
      };
      // var VUE_APP_ENV = `${process.env.VUE_APP_ENV}`;
      // var VUE_APP_ENV = `${process.env.VUE_APP_ENV}`;
    },
  },
};
</script>

<style scoped></style>
