<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
  <!-- :height="height" -->
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  ChartJSPluginDatalabels
);

export default {
  name: "DoughnutChart",
  components: {
    Doughnut,
  },
  props: [
    "chartId",
    "datasetIdKey",
    "width",
    "height",
    "cssClasses",
    "styles",
    "plugins",
    "chartData",
    "legendPosition",
    "aspectRatio",
    "showAbsolute",
  ],

  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: this.aspectRatio ? this.aspectRatio : 1,
        color: "black",
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage =
                value && (value * 100) / sum > 5
                  ? " " + ((value * 100) / sum).toFixed(2) + "%"
                  : "";
              if (this.showAbsolute && value && (value * 100) / sum > 5)
                percentage += `\n(${value})`;
              return percentage;
            },
            color: "#FFF",
            font: {
              size: 10,
              weight: "bold",
            },
          },
          //   datalabels: {
          //     formatter: (value) => {
          //       return value + "%";
          //     },
          //   },
          legend: {
            position: this.legendPosition ? this.legendPosition : "right", // 'left' or 'right'
            align: "start",

            labels: {
              boxWidth: 20,
              boxHeight: 15,
              padding: 10,
              usePointStyle: true,
              pointStyle: "solid",
              font: {
                family: "Poppins",
                size: 12,
                weight: "500",
              }, //"12px", // Set the font size to 16 pixels
            },
          },
        },
      },
    };
  },
};
</script>
