import processes from "./processes";
import users from "./users";
import trackings from "./trackings";
import roleIds from "./rolesIds";
import enterprises from "./enterprises";
import nfses from "./nfses";
import notifications from "./notifications";
import expenses from "./expenses";
import nfsesServicos from "./nfsesServicos";
import receivables from "./receivables";
import projectedReceivables from "./projectedReceivables";
//window.strapiUrl = "localhost";
//window.strapiUrl = "https://3.14.147.137";
//window.strapiToken =
//  "251234dd75e2b217c6f0c0fec0b0cd0197cbf66a83b704bada6fc7344ad7dd3892e67de003454f4b52e32ca16b421cc4171687c1233027cbf6744bee57369301b354681bf21c69e7f0315e9e9621b48bb42feee763304158202867060212053a491ff672d2a51bbb8400aa963336091e94f5a3dd14b524a27cb73472c3672797";
window.strapiToken =
  "01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

var strapiApi = {
  processes,
  users,
  trackings,
  roleIds,
  enterprises,
  nfses,
  notifications,
  expenses,
  nfsesServicos,
  receivables,
  projectedReceivables,
};

export default strapiApi;
