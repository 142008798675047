import functions from "./functions";
var trackings = {
  async getAll() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const trackings = functions.sanitize(res && res.data && res.data);

    return trackings;
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    roleId,
    enterprises
  ) {
    if (window.debug) console.log(`sortDesc:${sortDesc}`);
    if (window.debug) console.log(JSON.stringify({ roleId }));
    if (window.debug) console.log(JSON.stringify({ enterprises }));

    if (roleId !== 6 && enterprises.length == 0) {
      return { values: [], pagination: { total: 0 } };
    }

    var textSort = sort ? `&sort[0]=${sort}${sortDesc ? ":desc" : ""}` : "";
    var textFilterName = filterName
      ? `&filters[name][$containsi]=${filterName}`
      : "";
    let url;
    if (pageSize && pageSize !== -1)
      url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}${textFilterName}${textSort}`;
    else
      url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings?populate=*&pagination[page]=${page}&pagination[pageSize]=1000${textFilterName}${textSort}`;

    if (roleId !== 6) {
      for (var e = 0; e < enterprises.length; e++) {
        let enterprise = enterprises[e];
        url += `&filters[enterprise][id][$in][${e}]=${enterprise.id}`;
      }
    }

    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let values = functions.sanitize(res && res.data && res.data);
    values = values.map((el) => ({
      username: el.user?.username,
      enterpriseName: el.enterprise?.dpeNomPessoa,
      ...el,
    }));
    //console.log(JSON.stringify({ stations }));
    const pagination = res.meta && res.meta.pagination;
    return { values, pagination };
  },

  async getById(trackingId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings/${trackingId}`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getByPriCodAndFilCod(priCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings?filters[priCod]=${priCod}&filters[filCod]=${filCod}`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const trackings = functions.sanitize(res.data);
    if (trackings.length > 0) return trackings[0];
    else return {};
    return res;
  },

  async post(tracking) {
    var body = JSON.stringify({
      data: tracking,
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings`;
    if (window.debug) console.log(JSON.stringify({ url }));

    if (window.debug) console.log(`body:${body}`);
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getUsers(trackingId, profileId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[tracking][id][$eq]=${trackingId}&populate[0]=*`;

    // var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=${profileId}&filters[$and][1][tracking][id][$eq]=${trackingId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[tracking][id][$eq]=${trackingId}&populate[0]=*`;

    if (window.debug) console.log(JSON.stringify({ url }));

    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getClients(trackingId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[$and][0][profile][id][$eq]=4&filters[$and][1][tracking][id][$eq]=${trackingId}&populate[0]=*`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/users?filters[tracking][id][$eq]=${trackingId}&populate[0]=*`;

    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });
    //const users = functions.sanitize(res && res.data && res.data.users);

    return res;
  },

  async getStations(trackingId) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/stations?filters[trackings][id][$eq]=${trackingId}`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    let stations = functions.sanitize(res && res.data && res.data);

    stations = stations.map((el) => ({
      ...el,
      address: `${el.streetAddress ? el.streetAddress + "," : ""}${
        el.neighborhood ? el.neighborhood + "," : ""
      }${el.cityFullName ? el.cityFullName + "," : ""}`,
    }));
    if (window.debug) console.log(JSON.stringify({ stations }));

    return stations;
  },

  async put(value) {
    if (window.debug) console.log("PUT" + JSON.stringify({ tracking }));
    const tracking = JSON.parse(JSON.stringify(value));
    if (tracking.cnpj) tracking.cnpj = tracking.cnpj.replace(/\D/g, "");

    if (tracking.cpj) tracking.cpj = tracking.cpj.replace(/\D/g, "");

    //var url = "http://localhost:1337/graphql";
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/trackings/${tracking.id}`;
    delete tracking["id"];

    var body = JSON.stringify({
      data: tracking,
    });

    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default trackings;
