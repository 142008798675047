var orderers = [
  "ACESUR DO BRASIL IMPORTACAO E EXPORTACAO LTDA",
  "ACO INOXIDAVEL ARTEX LTDA",
  "AÇOS CONTINENTE INDUSTRIA E COMERCIO LTDA",
  "AÇOS TREFITA LTDA",
  "AÇOTUBO INDÚSTRIA E COMÉRCIO LTDA",
  "ACRO CABOS DE ACO INDUSTRIA E COMERCIO LTDA",
  "ACSO - CENTRAL DE SERVIÇO DO AÇO LTDA",
  "ACTUA IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "AC3 COMERCIAL LTDA",
  "ADAPTO COMPANY DO BRASIL SUPLEMENTOS ALIMENTARES LTDA",
  "ADM. COMÉRCIO DE ROUPAS LTDA.",
  "AGRA 8000 SHANGHAI IMPORTACAO & EXPORTACAO LTDA",
  "ALBASTEEL IND. E COM. DE LIGAS PARA FUNDIÇÃO LTDA.",
  "ALLUMÉ COSMÉTICOS IND. E COM. LTDA",
  "ALUMTOR COMERCIO DE ALUMINIO EIRELI",
  "AMBEV S/A",
  "AMERICAN STORE DISTR. DE SUPLEMENTOS ALIMENTARES L",
  "AMR IND. E COM. DE PRODUTOS SIDERURGICOS LTDA",
  "ANANDA METAIS LTDA",
  "APOTHEKA I D BRASIL LTDA",
  "ARAPLAS IND E COM DE ART DE MAT.  PLASTICO LTD EPP",
  "ARGOS GLOBAL PARTNER SERVICES LTDA",
  "ASPERBRAS TUBOS E CONEXOES LTDA",
  "ASSOCIAÇÃO CATARINENSE DE AVICULTURA - ACAV",
  "ATITUBOS COMERCIAL DE ACOS LTDA",
  "ATLANTE SHIPPING DO BRASIL",
  "AVANEX INDUSTRIA E COMERCIO LTDA",
  "AWR DISTRIBUIDORA, IMPORTADORA E EXPORTADORA LTD",
  "BANDEIRA & GOMES LTDA ME",
  "BANDEIRANTES COMPANHIA DE PNEUS S/A.",
  "BENAFER S/A COMERCIO E INDUSTRIA",
  "BILU - NEW CONFECCOES LTDA",
  "BIOGEOENERGY FABRICACAO E LOCACAO DE EQUIPAMENTOS",
  "BLASS COMERCIO, IMPORTACAO E EXPORTACAO LTDA - ME",
  "BLUEQUEST RESOURCES DO BRASIL IMP. EXP. LTDA",
  "BOLIVIAN ELECTRIC",
  "BOX DO BRASIL LTDA",
  "BRAK COMÉRCIO E IMPORTAÇÃO DE ARTIGOS ESPORTIVOS L",
  "BRASGUAY S.R.L",
  "BRASILNET DISTRIB. DE PROD. P/ INFORMATICA LTDA.",
  "BROTHER INTERNATIONAL CORPORATION DO BRASIL LTDA",
  "BRTEC EQUIPAMENTOS DE TELECOMUNICAÇÕES LTDA",
  "BRZ COMERCIO E DISTRIBUIDORA DE MOTOPECAS LTDA",
  "BUZOLIN PARTICIPAÇÕES LTDA",
  "CAC DISTRIBUIDORA LTDA",
  "CALCULO IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "CARAJAS COM DE EQUIP E ROUPAS PROFISSIONAIS LTDA",
  "CARGILL AGRICOLA SA",
  "CARREFOUR COMÉRCIO E INDÚSTRIA LTDA",
  "CASA DA CERVEJA SÃO PAULO LTDA - ME",
  "CECIL S/A - LAMINAÇÃO DE METAIS",
  "CENTRAL TELHA IND COM LT",
  "CEREPLAST, INC",
  "CHARGE POINT LTDA - ME",
  "CHINT ELETRICOS AMERICA DO SUL LTDA",
  "CIA ULTRAGAZ S A",
  "CIMENFORT INDUSTRIAL LDA",
  "CITRA DO BRASIL COMERCIO INTERNACIONAL S.A.",
  "CLASSIC BRASIL COMERCIAL LTDA",
  "CMQ BRASIL IMPORTACAO EXPORTACAO LTDA.",
  "COFEL COMERCIAL E INDUSTRIAL DE FERRO LIGAS LTDA",
  "COMAT DO BRASIL EQUIPAMENTOS INDUSTRIAIS LTDA",
  "COMERCIAL TEDDIM IMPORT EXPORT",
  "COMPANHIA BRASILEIRA DE DISTRIBUIÇÃO",
  "CONDLIGHT IND. E COM. DE CONDUTORES ELÉTRICOS LTDA",
  "CONTUFLEX CONEXOES TUBOS E FLEXIVEIS LTDA",
  "COPPER INDUSTRIA E COMERCIO DE VERGALHÕES EIRELI",
  "COR SOLAR INDUSTRIA E COMERCIO LTDA",
  "CORDEIRO CABOS ELECTRICOS PARAGUAY S/A",
  "CORDEIRO CABOS ELETRICOS S.A",
  "CORDEIRO FIOS E CABOS ELÉTRICOS LTDA",
  "CORDEIRO FIOS E CABOS ELÉTRICOS LTDA (SUL)",
  "COREAS DISTRIBUTION LTD",
  "CORR PLASTIK INDUSTRIAL LTDA",
  "CP COMERCIAL S/A",
  "C-PACK CREATIVE PACKAGING S/A",
  "CPM BRAXIS TECNOLOGIA LTDA.",
  "CRCJ DISTRIBUIDORA EIRELI",
  "CRUISER IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "DAIHATSU IND E COMERCIO DE MOVEIS E APAR ELETR LTD",
  "DE LONGHI BRASIL - COMERCIO E IMPORTACAO LTDA",
  "DHK DISTRIBUIDORA DE PNEUS E ACESSORIOS EIRELI",
  "DISTRIBUIDORA DE COMODITTIES BRASIL LTDA",
  "DISTRIBUIDORA NEW LIFE LTDA",
  "DJBJ COMERCIAL E IMPORTADORA LTDA - ME",
  "DLD COMERCIO VAREJISTA LTDA",
  "D-LINK BRASIL LTDA.",
  "DORTE DISTRIBUIDORA IMPORTADORA E EXPORTADORA LTDA",
  "D.P. MARTINI - PRODUTOS ELETRONICOS LTDA",
  "DUFERCO DO BRASIL DISTRIBUICAO LTDA.",
  "DURENO COMERCIO E DISTRIBUIÇÃO DE METAIS LTDA",
  "DUX COMERCIO E IMPORTACAO LTDA",
  "DVG INDUSTRIAL LTDA",
  "ECOALUMI ALUMINIO S/A",
  "ECOWHITE TRADING LTDA",
  "EDR COMÉRCIO, IMPORTAÇÃO  E EXPORTAÇÃO DE POLIMERO",
  "ELECON INDUSTRIA E COMERCIO LTDA",
  "ELECTRIC MUNDIAL S.R.L",
  "ELECTROGIS S.R.L",
  "ELECTROPAR S.A.",
  "ELETRIC TECK IMPORTACAO, EXPORTACAO E COMERCIO DE",
  "ELETRO ZAGONEL LTDA",
  "EMPORIO DO MARMORE IMPORTACAO E EXPORTACAO LTDA",
  "EMPREENDIMENTO COMERCIAL INDUSTRIAL ECIL LTDA",
  "EMPRESA BRASILEIRA ART. PROF. LTDA.",
  "EMTECO - MOTORES TUBULARES E COMPONENTES LTDA",
  "ENCOPEL COMERCIO DE ROLAMENTO E PEÇAS LTDA",
  "ENTREPOSTO IMPORTAÇÃO E EXPORTAÇÃO LTDA.",
  "EPEX IND. E COM. DE PLASTICOS LTDA",
  "EQUIPO.COM COMERCIO, IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "ESFERA EQUIPAMENTOS LTDA - EPP",
  "ESTOK COMERCIO E REPRESENTAÇÕES S.A.",
  "EUROS DISTRIBUIDORA DE MATERIAIS P/ CONSTRUÇÃO LTD",
  "EUROS INDUSTRIA E COMERCIO DE AÇO LTDA",
  "EUROSTAR PRODUTOS GRAFICOS E COMUNICACAO VISUAL LT",
  "EXTRAPRINT COMÉRCIO DE ARTIGOS DE PAPELARIA LTDA",
  "FARMACLIN DROGARIA E PERFUMARIA LTDA",
  "FATOR TRADING LTDA",
  "FIC INDUSTRIA E COMERCIO DE MATERIAIS ELETRICOS LT",
  "FITOWAY LABORATORIO NUTRICIONAL",
  "FOOD CENTRE ST. KITTS LTD",
  "FORECAST COMERCIO, IMPORTACAO E EXPORTACAO DE PRO",
  "FORTLEV INDUSTRIA E COMERCIO DE PLASTICOS LTDA",
  "FORZA BR INDUSTRIA E COMERCIO DE MAQUINAS E EQUIPA",
  "FRANCO COMERCIO E REPRESENTACOES LTDA",
  "FRASQUIM INDUSTRIA E COMERCIO LTDA",
  "FREFER METAL PLUS IND E COM DE METAIS LTDA",
  "FRIGORIFICO JAHU LTDA",
  "FRISHER DO BRASIL LTDA",
  "FROUDE HOFMANN IND.E COM.DE EQUIP. DE TESTE LTDA",
  "GALVISA PRODUTOS SIDERURGICOS LTDA",
  "GAREN AUTOMAÇÃO S/A",
  "GDS GROW DIETARY SUPPLEMENTS DO BRASIL S/A",
  "GEO. F. HUGGINS & CO (G'DA) LTD.",
  "GEOGRUPO ENGENHARIA GEOTECNICA AMBIENTAL LTDA",
  "GLISMAR BECERRA Y ASOCIADOS S.R.L",
  "GLOBAL BRASIL - TECNOLOGIA EM QUIMICA E MODA LTDA.",
  "GLOBAL TIRE DO BRASIL COMÉRCIO DE PNEUS E AFINS LT",
  "GR IMPORTADORA E DISTR. DE PAPEIS E EMB. LTDA",
  "GUAIBA QUIMICA INDÚSTRIA E COMÉRCIO LTDA",
  "GUNNEBO INDUSTRIES PROD. PARA MOVI. DE CARGAS LTDA",
  "GZT COMERCIO E IMPORTAÇÃO SA",
  "HANGZHOU EASY ELECTRIC WIRE AND CABLE CO.,LTD",
  "HAURATON IMP. E REPR. DE PROD. INDUSTRIAIS LTDA",
  "HAVITA IMPORTACAO E EXPORTACAO LTDA",
  "HDCAR LOCAÇÕES E TRANSPORTES LTDA",
  "HIDRAU TORQUE IND E COM IMPORTAÇÃO E EXP LTDA",
  "HIDROMECÂNICA GERMEK LTDA",
  "HILLER ELETRIC S.A.",
  "HIRAÇO PRODUTOS SIDERURGICOS IMPORTACAO E EXPORTAC",
  "HK & JL IMPORTAÇÃO, EXPORTAÇÃO E COMÉRCIO EIRELI",
  "IESI & D PAULA LTDA ME",
  "ILC INDUSTRIA E COMERCIO LTDA",
  "IMPORTADORA TORRA TORRA LTDA.",
  "INABRA ABRASIVOS E FERRAMENTAS LTDA",
  "INCOMP IMPORTAÇÃO E EXPORTAÇÃO LTDA.",
  "IND BANDEIRANTE DE PLASTICOS LTDA",
  "INDUSTRIA DE CAIXAS FORMATT LTDA",
  "INDUSTRIA E COMERCIO DE ARAMES ROGINI PERES LTDA",
  "INDUSTRIA QUIMICA ANASTACIO S/A",
  "INECEL ESTRUTURAS ELETRICAS LTDA",
  "INELCOM BRASIL DE TELECOMUNICAÇÕES LTDA",
  "INFORMATICA TECHNOLOGY ELETRONICA LTDA",
  "INFRANER MONTAGEM E CONSTRUÇÃO LTDA",
  "ISHELA IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "ISSA TRADING LTD",
  "ITACA COMERCIAL LTDA",
  "ITR SOUTH AMERICA COM IMP E EXP LTDA",
  "ITÚ MÁRMORES E GRANITOS LTDA",
  "ITW - COMERCIAL IMPORTADORA E EXPORTADORA LTDA",
  "JADE DRAGAO COMERCIO TEXTEIS LTDA",
  "JBS S/A",
  "J.C.P. IMP E EXP DE ALIMENTOS LTDA",
  "JEFER PRODUTOS SIDERURGICOS LTDA",
  "JETLINE INDÚSTRIA E COMÉRCIO DE PLÁSTICOS LTDA",
  "JFN - IMPORTAÇÃO E EXPORTAÇÃO  LTDA",
  "JINGJING GUO",
  "JOFEGE FIACAO E TECELAGEM LTDA",
  "KALAY DO BRASIL LTDA",
  "KHAMEL REPRESENTACOES IMPORTAÇÃO E EXPORTAÇÃO EIRE",
  "L & B INDÚSTRIA E COMÉRCIO DE ARTIGOS DO VESTUÁRIO",
  "LAMEDID COMERCIAL E SERVIÇOS LTDA",
  "LCA LAMINAÇÃO DE COBRE E ALUMINIO LTDA",
  "LE SYSTEM IMPORT - IMPORTACAO EXPORTACAO E COMERC",
  "LEMARC IMPORTAÇÃO,COMÉRCIO E SERVIÇOS LTDA.",
  "LIBERMAC COMERCIO DE MAQUINAS E ACESSORIOS LTDA",
  "LIFTCARGAS GUINDASTES E OPERAÇÕES TECNICAS LTDA",
  "LIMER-CART IND.COMERCIO DE EMBALAGENS LTDA",
  "LM COMERCIO DE LINHAS TEXTIL - EIRELI",
  "LM IMPORTACAO E EXPORTACAO LTDA",
  "LODISA LOGISTICA E DISTRIBUICAO S.A",
  "LOJAS ESKALA COMÉRCIO DE TECIDOS E CONFECÇÕES LTDA",
  "LT COMERCIAL LTDA",
  "LUIZ HENRIQUE SHAFFER",
  "LUIZ RENATO SCHAFFER",
  "MAFER IND E COM DE ARTEFATOS DE BORRACHA LTDA",
  "MAGAZINE TORRA TORRA LTDA",
  "MAKTUB IMPORT COMERCIO IMP E EXP DE ALIM E BEB LTD",
  "MAR CABO - PRODUTOS CONGELADOS, LDA",
  "MARCA REPRESENTACOES LTDA",
  "MARFRIG GLOBAL FOOD S.A.",
  "MARTINI TELECOM LTDA",
  "MASSY DISTRIBUTION (BARBADOS) LTD.",
  "MCN IND ENCAPSULAMENTO,COM, IMP,EXP,DIST LTDA",
  "MELCOR DISTRIBUIDORA EIRELLI",
  "MENDES, RIGONATTI & CIA LTDA.",
  "METALCORP IMPORTAÇÃO E EXPORTAÇÃO LTDA.",
  "METALURGICA QUASAR LTDA",
  "METALÚRGICA SCHIOPPA LTDA",
  "MEXIM COMERCIAL IMP. E EXP. DE GENEROS ALIM. LTDA",
  "MFC INDUSTRIA E COMERCIO LTDA",
  "MGI TECH COMÉRCIO, IMPORTAÇÃO E EXPORTAÇÃO LTDA.",
  "M.G.I.INFORMATICA LTDA",
  "MHM DISTRIBUIDORA DE ALIMENTOS LTDA",
  "MIGUEL OSCAR ATALA AGUILERA",
  "MINAPLAST MAQUINAS IND ARTEFATOS PLAST LTDA",
  "MINERVA S.A.",
  "MITCHELL A. MICHAEL LTD",
  "MJB ATACADISTA LTDA",
  "MMS MULTIACOS METAIS E SERVICOS LTDA",
  "MONKYI IMPORTADORA E EXPORTADORA LTDA",
  "MOTTA SOLUCOES TUBULARES LTDA",
  "MRT COMÉRCIO DE ROUPAS E CALÇADOS LTDA.",
  "MULTIAÇOS INDUSTRIA E COMERCIO DE PRODUTOS TECNICO",
  "MULTILOG SUL ARMAZENS GERAIS LTDA",
  "MX IMPORTS - DISTRIBUIDORA, IMP. E EXP. EIRELI",
  "M2A INDUSTRIA E COMERCIO DE METAIS EIRELI",
  "NELODIR S.A - RUT: 21 609881 0013",
  "NEO RODAS S.A.",
  "NEW FISH COM DE PESCADOS LTDA",
  "NEW MILLEN PRODUTOS ALIMENTICIOS LTDA",
  "NICBRASIL IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "NITEK INDUSTRIAL LTDA",
  "NOBILIS INDUSTRIA COSMETICA LTDA. - EPP",
  "NORDSEE COML IMP EXP LT",
  "NOVELIS DO BRASIL LTDA",
  "NUTRY ONE COMERCIO ATACADISTA DE SUPLEMENTOS LTDA",
  "OLIVIERS IMPORTAÇÃO E COMÉRCIO DE ALIMENTOS LTDA",
  "OREMA INDUSTRIA E COMERCIO S/A",
  "ORLEPLAST IND E COM DE PLASTICOS LTDA",
  "OURO PRETO COMERCIO DE FERRO E AÇO",
  "PANHO FRUTAS LTDA",
  "PARIZOTTO, PARIZOTTO LTDA",
  "PAULIMAC COMÉRCIO DE INSUMOS XEROGRÁFICOS LTDA",
  "PEDRO VONO FERREIRA - ME",
  "PERFIMEC S/A - CENTRO DE SERVIÇOS EM AÇO",
  "PHOENIX COMPANY IMP., EXP E DIST. DE PRODUTOS LTDA",
  "PHOTON NEGOCIOS DE SAUDE E BEM ESTAR LTDA.",
  "PLASH COMERCIAL LTDA",
  "PLASMAR IND E COMERCIO DE PLASTICOS LTDA",
  "PLASTVINIL INDÚSTRIA E COMÉRCIO DE PLASTICOS LTDA",
  "PNEUTRAT COMERCIO E VULCANIZAÇÃO",
  "POLIMPORT - COMERCIO E EXPORTACAO LTDA",
  "POSH DIGITAL COMERCIO ELETRONICO - ME",
  "PPLF REPRESENTAÇÕES LTDA",
  "PREMIER PESCADOS COMERCIO IMPORTAÇÃO E EXPORTAÇÃO",
  "PREMIX BRASIL RESINAS LTDA",
  "PRINCETON-LEMITAR INDUSTRIA E COMERCIO LTDA",
  "PROACTIVA MEIO AMBIENTE BRASIL LTDA",
  "PRODHIGI COMERCIO E REPRESENTACAO LTDA",
  "PSA TECHNOLOGY LTDA",
  "QUANTUM STEEL ACOS INDUSTRIAIS LTDA",
  "QUIMICOLLA IND QUIMICA LTDA - EPP",
  "QUIRIOS PRODUTOS QUIMICOS LTDA",
  "R & D COM. E IMP. EXP. DE MATERIAIS ELETRICOS LTDA",
  "RB COMERCIO DE MAQUINAS TREFILA LTDA",
  "RC NUNES EMPREENDIMENTOS S/A.",
  "REDE BRAZIL MAQUINAS S A",
  "REIFASA COMERCIAL LTDA",
  "REIS OFFICE PRODUCTS COMERCIAL LTDA",
  "RELIPEL FILMES FLEXÍVEIS LTDA",
  "RESITECH DO BRASIL LTDA",
  "RGS9 TECNOLOGIA, IMPORTACAO E CONSTRUCOES LTDA",
  "RICARDO DE OLIVEIRA PEDRO IMPORTAÇÃO EPP",
  "RICHARD DA SILVA DOURADO",
  "RIO BRANCO ALIMENTOS S/A",
  "RN PNEUS LTDA",
  "ROSSETTI EQUIPAMENTOS RODOVIARIOS LTDA",
  "ROWEDER & ANTONIO LTDA",
  "R.V. BRAZIL COMERCIAL IMPORTACAO E EXPORTACAO LTDA",
  "SAINTE MARIE IMPORTACAO E EXPORTACAO LTDA.",
  "SAMSONITE BRASIL LTDA",
  "SANTA CRUZ TRANSPORTES COLIGADOS LTDA",
  "SANTA MARTA DISTRIBUIDORA DE DROGAS LTDA",
  "SANTO ANDRE DISTRIBUIDORA INDUSTRIAL LTDA",
  "SASIL COMERCIAL E INDUSTRIAL DE PETROQUIMICOS LTDA",
  "SATURNIA SISTEMAS DE ENERGIA LTDA",
  "SELTA COMERCIO DE METAIS EIRELI",
  "SICES BRASIL LTDA",
  "SM COMERCIO E SERVICOS LTDA - EPP",
  "SMART SUPPLY COM. E DIST. DE PROD. ARTISTICOS LTDA",
  "SONAEX S/A INDUSTRIA E COMÉRCIO DE AÇO",
  "SPORTS NUTRI INDUSTRIA COMERCIO E IMPORTACAO LTDA",
  "STARPAC COMERCIAL LTDA",
  "STEEL PRODUCTS DO BRASIL LTDA ME",
  "STRATUS COMERCIAL TEXTIL LTDA",
  "TAGIA COMERCIO E IMPORTACAO LTDA",
  "TAUA HOTEL E CONVENTION ATIBAIA LTDA",
  "TAVRIDA ELECTRIC DO BRASIL EQUIPAMENTOS ELETRICOS",
  "TDC - COMERCIO, IMPORTACAO, EXP. E REPRES LTDA ME",
  "TECMASTER COMPONENTES INDUSTRIAIS LTDA",
  "TECNOELECTRIC URCULLO SRL",
  "TEKNOLOGIO PRODUTOS TECNOLOGICOS LTDA",
  "TERMOMECANICA SP S.A.",
  "TERRAFIRMA IMPORTACAO E EXPORTACAO LTDA",
  "TEXTIL TABACOW S/A",
  "TP LINK TECNOLOGIA DO BRASIL LTDA",
  "TRAFIGURA DO BRASIL IMPORTAÇÃO EXPORTAÇÃO E COM LT",
  "TREE COMERCIAL DISTRIB E IMP DE PRODUD ALIM LTDA M",
  "TRELICAMP INDUSTRIA E COMERCIO LTDA",
  "TREVO COMERCIAL IMPORTADORA E EXPORTADORA LTDA",
  "TRIAFA IND. COM. E REP. DE PROD. METAL. LTDA.",
  "TRICON ENERGY DO BRASIL COM DE PROD QUIMÍCOS LTDA",
  "UGO COMERCIAL LTDA",
  "ULDRY DO BRASIL AÇOS ESPECIAIS LTDA",
  "UP-STEEL PRODUTOS SIDERURGICOS EIRELI",
  "USINA METAIS LTDA",
  "VALE DO SOL COML. IMPORTADORA E EXPORTADORA LTDA",
  "VENKO MOTORS DO BRASIL IMP. E EXP. DE VEICULOS LTD",
  "VEQUIS INDÚSTRIA, COMÉRCIO, REPRESENTAÇÃO E DISTRI",
  "VIDA EM GRAOS COM E IND DE PROD AL IMP  EXP EIRELI",
  "VILLEFER COMÉRCIO DE PRODUTOS SIDERURGICOS LIMITAD",
  "VINIGAS IND E COM DE COMPONENTES PARA GAS LTDA",
  "VITTALAVE HIGIENIZACAO E GESTAO DE ARTIGOS TEXTEIS",
  "WALDMAN COMERCIO IMPORTAÇÃO E EXPORTAÇÃO LTDA",
  "WINEBRANDS COM. IMPORT. BEBIDAS E ALIMENTOS LTDA",
];

export default orderers;
