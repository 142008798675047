<template>
  <v-container fluid>
    <v-dialog v-model="showDialog" width="600px">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orimary" text @click="showDialog = false">
            Fechar
          </v-btn>
        </v-card-actions>
        <v-card-title>
          <span class="headline">Duplicatas</span>
        </v-card-title>
        <v-card-text>
          <!-- <p>{{ JSON.stringify({ itemSelected }) }}</p> -->
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Data de vencimento</th>
                  <th class="text-right">DOC_TIP</th>
                  <th class="text-right">Valor total</th>
                  <th class="text-right">Valor pago</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(duplicata, indexField) in itemSelected?.receivables"
                  :key="indexField"
                >
                  <!-- <td>{{ duplicata.TIT_DTA_VENCIMENTO }}</td> -->
                  <td>
                    <span v-date-format="duplicata.TIT_DTA_VENCIMENTO"></span>
                  </td>
                  <td>{{ duplicata.DOC_TIP }}</td>
                  <!-- <td>{{ duplicata.VLR_TOT }}</td> -->
                  <!-- <td>{{ duplicata.VLR_PAGO }}</td> -->
                  <td><span v-money-format="duplicata.VLR_TOT"></span></td>

                  <td><span v-money-format="duplicata.VLR_PAGO"></span></td>
                  <!-- <td class="text-right">
                    <v-btn class="primary" @click="addField(indexField)"
                      >+</v-btn
                    >
                  </td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="custom-table"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length.sync="serverItemsLength"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      @update:items-per-page="updateRowsPerPage"
      @update:page="updatePage"
      ref="dataTable"
    >
      <template v-slot:item.action="{ item }">
        <v-btn @click="openDialog(item)" class="primary"> Duplicatas</v-btn>
      </template>
      <template v-slot:item.TIT_DTA_VENCIMENTO="{ item }">
        <span v-date-format="item.TIT_DTA_VENCIMENTO"></span>
      </template>
      <template v-slot:item.VLR_TOT="{ item }">
        <span v-money-format="item.VLR_TOT"></span>
      </template>
      <template v-slot:item.VLR_PAGO="{ item }">
        <span v-money-format="item.VLR_PAGO"></span>
      </template>

      <template v-slot:item.VLR_ABERTO="{ item }">
        <span v-money-format="item.VLR_ABERTO"></span>
      </template>
      <template v-slot:item.VLR_NF_SAIDA="{ item }">
        <span v-money-format="item.VLR_NF_SAIDA"></span>
      </template>
      <template v-slot:item.PRI_ESP_REFERENCIA="{ item }">
        <span> {{ item.process?.PRI_ESP_REFERENCIA }}</span>
      </template>
      <template v-slot:item.PRI_ESP_REFCLIENTE="{ item }">
        <span> {{ item.process?.PRI_ESP_REFCLIENTE }}</span>
      </template>

      <!-- <template v-slot:item.DOC_TIP="{ item }">
        <span> 1-Recebimento </span>
      </template> -->
      <template v-slot:item.DOC_TIP="{ item }">
        <span> {{ item.DOC_TIP == 1 ? "Receber" : "Pagar" }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<!-- { text: "Vl. pedido", align: "start", sortable: false, value:
"VLR_PEDIDO_COMPRA", }, { text: "Vl. proforma", align: "start", sortable: false,
value: "", }, { text: "Vl. Invoice", align: "start", sortable:
false, value: "VLR_INVOICE", }, -->

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
export default {
  name: "EnterpriseDetails",
  props: ["enterprise"],
  components: {},

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  data() {
    return {
      menu: false,
      showDialog: false,
      itemSelected: {},
      sortBy: "TIT_DTA_VENCIMENTO",
      sortDesc: false,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      items: [],
      headers: [
        {
          text: "Doc. Cod.",
          align: "start",
          sortable: false,
          value: "DOC_COD",
        },

        {
          text: "Ref. Externa",
          align: "start",
          sortable: false,
          value: "PRI_ESP_REFCLIENTE",
        },
        {
          text: "Ref. Cliente",
          align: "start",
          sortable: false,
          value: "PRI_ESP_REFERENCIA",
        },
        {
          text: "Dt. Vencimento",
          align: "start",
          sortable: false,
          value: "TIT_DTA_VENCIMENTO",
        },
        //(1 SAÍDA / 2 ENTRADA)
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "DOC_TIP", //1-Saída, 2-Entrada , (1-Recebimento , 2-Pagamento)
        },
        // {
        //   text: "Valor",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOT",
        // },

        // {
        //   text: "Vl. Pago",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_PAGO",
        // },

        {
          text: "Vl. Aberto",
          align: "start",
          sortable: false,
          value: "VLR_ABERTO",
        },

        {
          text: "Vl. Total",
          align: "start",
          sortable: false,
          value: "VLR_NF_SAIDA",
        },
      ],
    };
  },

  async mounted() {
    if (this.user?.role?.id == strapiApi.roleIds.admin) {
      this.headers = [
        ...this.headers,

        {
          text: "View",
          align: "start",
          sortable: false,
          value: "viewName",
        },
      ];
    }

    await this.doSearch();
  },
  methods: {
    openDialog(item) {
      this.itemSelected = item;

      this.showDialog = true;
    },
    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      this.loading = true;

      // alert("doSearch");

      //  async getWithReceivables(page, pageSize, sort, sortDesc, orderers) {
      let { values, pagination } =
        await strapiApi.projectedReceivables.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          this.enterprise?.id
        );

      console.log(JSON.stringify({ values }));

      this.items = values;

      this.serverItemsLength = pagination?.total;

      this.loading = false;
    },
  },
};
</script>

<style></style>
