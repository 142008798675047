<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-row>
        <h4 style="margin: auto">Acesso não autorizado</h4>
      </v-row>

      <v-row>
        <img
          width="600px"
          src="@/assets/img/banner4.png"
          style="margin: auto"
        />
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "construction",

  data() {
    return {};
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {},
  methods: {},
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
