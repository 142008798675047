<template>
  <v-container fluid class="pa-10">
    <br />
    <v-row style="margin: 0px">
      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="N° do House"
          :value="transport && transport.CNT_ESP_NUMERO_HOUSE"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="N° do Master"
          :value="transport && transport.CNT_ESP_NUMERO_MASTER"
        >
        </grid-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Tipo"
          :value="transport && transport.CNT_VLD_TP_DOC_CARGA_DESC"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Data emissão"
          :value="
            transport &&
            transport.CNT_DTA_EMISSAO &&
            formatDate(transport.CNT_DTA_EMISSAO)
          "
        >
        </grid-field>
      </v-col>
    </v-row>
    <v-row style="margin: 0px">
      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="N° do CE"
          :value="transport && transport.CE_MERCANTE"
        >
        </grid-field>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Via de transporte"
          :value="transport && transport.VIA_DES_NOME"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Exportador"
          :value="transport && transport.EXPORTADOR"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field label="Navio" :value="transport && transport.NOME_NAVIO">
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Previsão embarque"
          :value="formatDate(transport && transport.CNT_DTA_PREV_EMB)"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Embarque"
          :value="formatDate(transport && transport.CNT_DTA_EMBARQUE)"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Previsão chegada"
          :value="formatDate(transport && transport.PREVISAO_CHEGADA)"
        >
        </grid-field>
      </v-col>

      <v-col cols="3" class="ma-0 pa-0">
        <grid-field
          label="Chegada"
          :value="formatDate(transport && transport.CNT_DTA_CHEGADA)"
        >
        </grid-field>
      </v-col>
    </v-row>

    <v-card>
      <v-toolbar class="primary" dark> Containers</v-toolbar>

      <div
        style="margin: 0px"
        v-for="(vb_container, indexContainer) in vb_containers"
        :key="indexContainer"
      >
        <div style="background-color: lightblue; padding: 5px" class="mt-2">
          <p style="margin: 2px">{{ vb_container.CNT_ESP_NUMERO }}</p>
        </div>
        <v-row style="margin: 0px">
          <v-col cols="3" class="ma-0 pa-0">
            <grid-field
              label="N° do Container"
              :value="vb_container && vb_container.CNT_ESP_NUMERO"
            >
            </grid-field>
          </v-col>

          <v-col cols="3" class="ma-0 pa-0">
            <grid-field
              label="N° do Lacre"
              :value="vb_container.CTO_ESP_NRLACRE"
            >
            </grid-field>
          </v-col>

          <v-col cols="3" class="ma-0 pa-0">
            <grid-field
              label="Data Venc. Free Time"
              :value="
                formatDate(vb_container && vb_container.CTO_DTA_VENCTO_FREETIME)
              "
            >
            </grid-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0">
            <grid-field
              label="Free Time"
              :value="vb_container && vb_container.PRE_DEMURGE"
            >
            </grid-field>
          </v-col>
        </v-row>

        <v-row style="margin: 0px">
          <v-col cols="3" class="ma-0 pa-0">
            <grid-field
              label="Data Chegada"
              :value="formatDate(vb_container && vb_container.DTA_CHEGADA)"
            >
            </grid-field>
          </v-col>
          <v-col cols="3" class="ma-0 pa-0">
            <grid-field label="Tipo" :value="vb_container.CTO_DES_NOME">
            </grid-field>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import GridField from "../../components/fields/GridField.vue";
import moment from "moment";
export default {
  name: "ProcessoConhecimento",
  props: ["transport", "vb_containers"], // object: quote, product ...+
  components: {
    GridField,
  },
  data() {
    return {};
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {},
  methods: {
    // formatDate(value) {
    //   if (value) return moment(value + 3 * 60 * 60 * 1000).format("DD/MM/YYYY");
    //   else return "";
    // },
    formatDate(value) {
      if (value) return moment(value).format("DD/MM/YYYY");
      else return "";
    },
  },
};
</script>

<style></style>
