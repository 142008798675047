import functions from "./functions";
var nfseServicos = {
  async getWithPagination(page, pageSize, sort, sortDesc, processId, search) {
    const start = Date.now();

    var filters = {};

    if (processId) {
      filters = {
        nfse: {
          process: {
            id: {
              eq: parseInt(processId),
            },
          },
        },
      };
    }

    var letters = search?.replace(/[^A-Za-z]/g, "");
    var numbersAndSpecial = search?.replace(/[A-Za-z]/g, "");
    var numbers = numbersAndSpecial?.split("/");

    if (search && (letters || numbers?.length > 0)) {
      filters = {
        and: [{ nfse: { provider: { id: { ne: null } } } }],
        //and: [],
      };
      if (letters) {
        filters.and.push({
          nfse: {
            processRef: {
              contains: letters,
            },
          },
        });
      }
      for (let i = 0; i < numbers.length; i++) {
        let numberSearch = numbers[i];
        if (i == 0) {
          numberSearch = parseInt(numberSearch).toString();
        }

        filters.and.push({
          nfse: {
            processRef: {
              contains: numberSearch,
            },
          },
        });
      }
    }
    console.log(JSON.stringify({ filters }));

    var query = `query nfseServicos($filters:NfseServicoFiltersInput!){
      nfseServicos( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            Descricao
            ItemServico
            Quantidade
            ValorBaseCalculo
            ValorServico
            ValorUnitario
            Aliquota
            Deducoes
            DescontoCondicionado
            DescontoIncondicionado
            nfse{
              data{
                attributes{
                  Numero
                  PrestadorRazaoSocial
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination nfseServicos time: ${
          (end - start) / 1000
        } s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.nfseServicos?.meta?.pagination;
    let values = functions.sanitize(res.data.nfseServicos.data);

    values = values.map((el) => ({
      Nfse: el?.nfse?.Numero,
      Prestador: el?.nfse?.PrestadorRazaoSocial,
      ...el,
    }));

    // Nfse: value.Numero,
    // Prestador: value.PrestadorRazaoSocial,
    return { values, pagination };
  },
};

export default nfseServicos;
