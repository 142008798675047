<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container r v-if="user?.role?.id == 6">
        <v-row class="ma-2">
          <h4 style="margin: auto">Nfse's</h4>
          <br />
        </v-row>

        <v-data-table
          :headers="headers"
          :items="nfses"
          item-key="name"
          class="custom-table"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
        </v-data-table>
      </v-container>
      <v-container v-else>
        <p>Acesso não autorizado</p>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "Nfses",

  data() {
    return {
      files: [],
      jsonData: [],
      headers: ["PrestadorRazaoSocial", "DataEmissao"],

      headers: [
        {
          text: "Data emissão",

          align: "start",
          sortable: false,
          value: "DataEmissao",
        },

        {
          text: "Razão Social",

          align: "start",
          sortable: false,
          value: "PrestadorRazaoSocial",
        },
      ],

      nfses: [],
      sortBy: "DataEmissao",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    // serverItemsLength: {
    //   // get() {
    //   //   return this.summaryData?.totals && this.summaryData?.totals[0]?.count;
    //   // },
    // },
  },

  async mounted() {
    await this.getData();
  },
  methods: {
    // updateSortBy() {},
    // updateSortDesc() {},
    // updateRowsPerPage() {},
    async updatePage() {
      await this.getData();
    },

    async updateSortBy() {
      await this.getData();
    },

    async updateSortDesc() {
      await this.getData();
    },

    async updatePage() {
      await this.getData();
    },

    async updateRowsPerPage() {
      await this.getData();
    },

    async getData() {
      let { values, pagination } = await strapiApi.nfses.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc
        // this.search,
        // dateStart,
        // dateEnd,
        // enterprises,
        // exporters
        //this.filterShipped
      );

      // if (this.user?.email == "demo@stmarie.com.br") {
      //   values = values.map((el) => ({
      //     ...el,
      //     DPE_NOM_PESSOA_LIG: el?.DPE_NOM_PESSOA_LIG?.includes("ARTEX")
      //       ? "DEMO"
      //       : el.DPE_NOM_PESSOA_LIG,
      //   }));
      // }

      let nfses = values;
      console.log(JSON.stringify({ pagination }));

      this.serverItemsLength = pagination?.total;

      this.nfses = nfses;
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
