<template>
  <v-card class="mx-auto" max-width="1000">
    <v-toolbar class="primary" dark> Histórico de rastreios</v-toolbar>

    <!-- <v-data-table
              :headers="trackingHeaders"
              :items="trackings"
              item-key="id"
              class="custom-table"
            >
              <template v-slot:item.createdAt="{ item }">
                <span v-date-format="item.createdAt"></span>
              </template>
            </v-data-table> -->

    <v-data-table
      :headers="headers"
      :items="trackings"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length.sync="serverItemsLength"
      @update:sort-by="updateSortBy"
      @update:sort-desc="updateSortDesc"
      @update:items-per-page="updateRowsPerPage"
      @update:page="updatePage"
    >
      <template v-slot:item.createdAt="{ item }">
        <span v-date-format="item.createdAt"></span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editIntegrator(item.id)"
          style="font-size: 30px"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
export default {
  name: "CardTrackings",
  data() {
    return {
      trackings: [],
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: 15,
      headers: [
        {
          text: "Data de criação",
          value: "createdAt",
          width: "20px",
          sortable: true,
        },
        {
          text: "Usuário",
          value: "username",
          align: "start",
          sortable: false,
        },
        {
          text: "BL",
          value: "blContainersRef",
          align: "start",
          sortable: false,
        },
        { text: "Container", value: "containerNumber", sortable: false },
        { text: "Encomendante", value: "enterpriseName", sortable: false },
        // { text: "Cidade", value: "cityId" },
        // { text: "Staus", value: "status" },
        // { text: "Ação", value: "action" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    await this.doSearch();
    //alert("mounted");
  },
  methods: {
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      const { values, pagination } =
        await strapiApi.trackings.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          this.search,
          this.user?.role?.id,
          this.user?.enterprises
        );

      //alert(JSON.stringify({ values, pagination }));
      this.trackings = values;
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>

<style></style>
