const roleIds = {
  authenticated: 1,
  public: 2,
  client: 3,
  trader: 4,
  operations: 5,
  admin: 6,
  manager: 10,
};

export default roleIds;
