<template>
  <v-container fluid>
    <!-- <v-toolbar class="primary" dark> Histórico de rastreios</v-toolbar> -->

    <!-- <v-row v-for="(notification, index) in expenses" :key="index">
      <p></p>
    </v-row> -->
    <SearchNfseDialog
      v-model="showNfseSearchDialog"
      :process-ref="process?.PRI_ESP_REFCLIENTE"
      @selectNfse="selectNfse"
      @selectNfseServico="selectNfseServico"
    ></SearchNfseDialog>

    <v-card class="mx-auto">
      <v-toolbar dark color="primary"
        ><h4>Despesas</h4>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="expenses"
        item-key="name"
        class="custom-table"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length.sync="serverItemsLength"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
        @update:items-per-page="updateRowsPerPage"
        @update:page="updatePage"
        ref="dataTable"
      >
        <template v-slot:item.nfse="{ item }">
          <!-- <span> {{ JSON.stringify(item.nfse) }}</span> -->

          <div v-if="item?.nfse?.Numero">
            <v-icon style="color: green">mdi-check-circle-outline </v-icon>

            <span>
              {{
                `${
                  item?.nfse?.Numero ? `Nota NFSEº:${item?.nfse?.Numero}:` : ""
                } ${item?.nfse?.PrestadorRazaoSocial ?? ""}`
              }}
            </span>
          </div>

          <div v-if="item?.servico?.ValorServico">
            <v-icon style="color: green">mdi-check-circle-outline </v-icon>
            <span>
              {{
                `${
                  item?.servico?.Nfse
                    ? `Serviço NFSEº:${item?.servico?.Nfse}:`
                    : ""
                } ${item?.servico?.Prestador ?? ""}`
              }}
            </span>
          </div>

          <v-btn @click="selectExpenseAndSearchNfse(item.id)">Buscar</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import SearchNfseDialog from "../../components/dialogs/SearchNfseDialog.vue";

export default {
  name: "ProcessExpenses",
  props: ["priCod", "filCod", "nfses", "process", "servicos"], // object: quote, product ...
  components: {
    SearchNfseDialog,
  },
  data() {
    return {
      expenses: [],
      expenseSelId: 0,
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 50,
      serverItemsLength: 15,

      showNfseSearchDialog: false,
      headers: [
        {
          text: "Idq",
          value: "id",
          align: "start",
          sortable: false,
        },
        {
          text: "Conta do projeto",
          value: "CTP_DES_NOME",
          align: "start",
          sortable: false,
        },
        { text: "Valor", value: "PID_MNY_VALORMN", sortable: false },
        // { text: "Status", value: "PID_VLD_STATUS", sortable: false },
        { text: "Nfse/Servico", value: "nfse", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    await this.doSearch();
  },
  methods: {
    selectExpenseAndSearchNfse(id) {
      this.expenseSelId = id;
      this.showNfseSearchDialog = true;
    },
    selectNfse(nfse) {
      //alert(this.expenseSelId);
      let expenseSel = this.expenses.find((el) => el.id == this.expenseSelId);
      expenseSel.nfse = nfse;
      this.showNfseSearchDialog = false;
    },

    selectNfseServico(servico) {
      let expenseSel = this.expenses.find((el) => el.id == this.expenseSelId);
      expenseSel.servico = servico;
      this.showNfseSearchDialog = false;
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      const { values, pagination } = await strapiApi.expenses.getWithPagination(
        this.priCod,
        this.filCod,
        this.page,
        this.itemsPerPage
      );

      let expenses = values.map((expense) => ({
        nfse:
          expense.nfse ??
          this.nfses.find((nfse) =>
            this.compareExpenseValues(
              nfse.ServicoValorServicos,
              expense.PID_MNY_VALORMN
            )
          ),

        servico:
          expense.servico ??
          this.servicos.find((servico) =>
            this.compareExpenseValues(
              servico.ValorServico,
              expense.PID_MNY_VALORMN
            )
          ),
        ...expense,
      }));

      expenses = expenses.map((expense) => ({
        ...expense,
        servico: expense.nfse ? null : expense.servico,
      }));

      this.expenses = expenses;
      this.serverItemsLength = pagination && pagination.total;
    },
    compareExpenseValues(v1, v2) {
      return Math.round(v1 * 100) / 100 == Math.round(v2 * 100) / 100;
    },
  },
};
</script>

<style></style>
