<template>
  <div>
    <v-dialog v-model="showDialog" width="1800px" class="pa-0 ma-0">
      <!-- <v-card class="ma-0 pa-0">
        <v-toolbar class="primary" dark
          >Buscar NFSE

          <v-spacer></v-spacer>
          <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
        >

        <v-card-text>
          <v-row>
            <v-text-field
              label="Processo ref"
              placeholder="Referência de processo"
              v-model="search"
            ></v-text-field>

            <v-btn class="primary" @click="doSearch">Buscar</v-btn>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="nfses"
            item-key="name"
            class="custom-table"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :server-items-length.sync="serverItemsLength"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            @update:items-per-page="updateRowsPerPage"
            @update:page="updatePage"
            ref="dataTable"
          >
            <template v-slot:item.action="{ item }">
              <v-btn class="primary" @click="selectNfse(item)"
                >Selecionar</v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card> -->

      <v-card style="min-height: 60vh">
        <v-toolbar class="primary" dark
          >Buscar NFSE
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
        >
        <v-tabs fluid class="pa-8">
          <v-tab>Nfses</v-tab>
          <v-tab>Ítens da nota</v-tab>

          <v-tab-item>
            <br />
            <CardSearchNfse
              :process-ref="processRef"
              @select="selectNfse"
              showAction="true"
            ></CardSearchNfse>
          </v-tab-item>

          <br />

          <v-tab-item>
            <br />
            <!-- <CardSearchNfse :process-ref="processRef"></CardSearchNfse> -->
            <CardSearchNfsesServicos
              :process-ref="processRef"
              @select="selectNfseServico"
              showAction="true"
            ></CardSearchNfsesServicos>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GridField from "../fields/GridField.vue";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import CardSearchNfse from "./CardSearchNfse.vue";
import CardSearchNfsesServicos from "./CardSearchNfsesServicos.vue";

export default {
  name: "search-nfse-dialog",
  props: ["value", "showError", "processRef"],
  components: {
    GridField,
    CardSearchNfse,
    CardSearchNfsesServicos,
  },
  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 50,
      serverItemsLength: 15,
      nfses: [],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "20px",
          sortable: true,
        },
        {
          text: "Prestador",
          value: "PrestadorRazaoSocial",
          align: "start",
          sortable: false,
        },
        {
          text: "Data emissão",
          value: "DataEmissao",
          align: "start",
          sortable: false,
        },
        //{ text: "Pdf link", value: "pdfLink", sortable: false },

        { text: "Ref", value: "processRef", sortable: false },

        {
          text: "Valor Serviços",
          value: "ServicoValorServicos",
          sortable: false,
        },
        { text: "Número", value: "Numero", sortable: false },
        { text: "Ação", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  async mounted() {
    //alert(this.processRef);
    // this.search = this.processRef;
    // await this.doSearch();
  },
  methods: {
    selectNfse(nfse) {
      this.$emit("selectNfse", nfse);
    },

    selectNfseServico(nfse) {
      //alert(JSON.stringify({ nfse }));
      this.$emit("selectNfseServico", nfse);
    },

    closeError() {
      this.$emit("closeError");
    },
    closeModal() {
      this.showDialog = false;
    },

    yes() {
      if (this.containers[0] && this.containers[0].containerNumber)
        this.$emit("yes", {
          containerNumber:
            this.containers[0] && this.containers[0].containerNumber,
          blContainersRef: this.blContainersRef ? this.blContainersRef : "",
        });
      else alert("Informar container e BL");
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("do search");
      const { values, pagination } = await strapiApi.nfses.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.processId,
        this.search
      );

      //alert(JSON.stringify({ values, pagination }));
      this.nfses = values;
      //alert(JSON.stringify({  pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>
