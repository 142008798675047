//19/04/23-check impacts
//import { directive as vClickOutside } from "vue-clickaway";

import { text } from "body-parser";
import moment from "moment";
import "moment-timezone";
import Vue from "vue";
/**
import Vue from 'vue'
 * You can register global components here and use them as a plugin in your main Vue instance
 */

Vue.directive("money-format", (el, { value }) => {
  //console.log(value);
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 2,
  }).format(value || 0);
});

Vue.directive("money-format-zero", (el, { value }) => {
  //console.log(value);
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value || 0);
});

Vue.directive("decimal-format", (el, { value }) => {
  //console.log(value);
  var d = Math.round(value * 100) / 100;
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    //  currency: "BRL",
    // style: "currency",
    minimumFractionDigits: 2,
  }).format(d || 0);
});

Vue.directive("decimal-format-3", (el, { value }) => {
  //console.log(value);
  var d = Math.round(value * 1000) / 1000;
  el.innerHTML = new Intl.NumberFormat("pt-BR", {
    //  currency: "BRL",
    // style: "currency",
    minimumFractionDigits: 2,
  }).format(d || 0);
});

Vue.directive("date-format", (el, { value }) => {
  let d = new Date(value);
  d.setHours(d.getHours() + 3);
  if (value) el.innerHTML = moment(d).format("DD/MM/YYYY");
  //el.innerHTML = moment.tz(value, "America/Los_Angeles").format("DD/MM/YYYY");
  else el.innerHTML = "";
});

Vue.directive("cnpj-format", (el, { value }) => {
  var v = value;
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/^(\d{2})(\d)/, "$1.$2"); //Coloca ponto entre o segundo e o terceiro dígitos
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3"); //Coloca ponto entre o quinto e o sexto dígitos
  v = v.replace(/\.(\d{3})(\d)/, ".$1/$2"); //Coloca uma barra entre o oitavo e o nono dígitos
  v = v.replace(/(\d{4})(\d)/, "$1-$2"); //Coloca um hífen depois do bloco de quatro dígitos
  el.innerHTML = v;
});

Vue.directive("cpf-format", (el, { value }) => {
  var v = value;
  v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos
  v = v.replace(/(\d{3})(\d)/, "$1.$2"); //Coloca um ponto entre o terceiro e o quarto dígitos                                         //de novo (para o segundo bloco de números)
  v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
  el.innerHTML = v;
});

Vue.directive("date-format-iso", (el, { value }) => {
  var d = new Date(value);
  d.setHours(d.getHours() + 3);
  el.innerHTML = d.toLocaleDateString("pt-BR");
});

// var d = new Date(object.Date);
// return d.toLocaleDateString('pt-BR')
Vue.decimalFormat = function (value) {
  var d = Math.round(value * 100) / 100;
  return new Intl.NumberFormat("pt-BR", { minimumFractionDigits: 2 }).format(
    d || 0
  );
};

Vue.directive("big-money-format", (el, { value }) => {
  let textValue = "";
  textValue = formatNumberWithSymbols(value);
  el.innerHTML = textValue;
});

function formatUsd(value, maximumFractionDigits, minimumFractionDigits) {
  var d = Math.round(value * 100) / 100;
  if (maximumFractionDigits < 0) maximumFractionDigits = 0;
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits:
      maximumFractionDigits !== null ? maximumFractionDigits : 3,
    minimumFractionDigits:
      maximumFractionDigits !== null ? maximumFractionDigits : 3,
  }).format(d || 0);
}
// formatLabelChartUsd(value) {
//   if (value >= 10 ** 9) {
//     return `$ ${formatUsd(
//       value / 10 ** 9,
//       11 - Math.floor(Math.log(value) / Math.log(10))
//     )} b`;
//   } else {
//     if (value >= 10 ** 6) {
//       return `$ ${formatUsd(
//         value / 10 ** 6,
//         8 - Math.floor(Math.log(value) / Math.log(10))
//       )} M`;
//     } else {
//       return `$ ${formatUsd(
//         value / 10 ** 3,
//         5 - Math.floor(Math.log(value) / Math.log(10))
//       )} K`;
//     }
//   }
// },

function formatNumberWithSymbols(number) {
  const symbols = ["", "M", "B"]; // Thousand, Million, Billion symbols

  if (isNaN(number)) {
    return "Invalid number";
  }

  // Check if the number is greater than or equal to 1 billion
  if (number >= 1e9) {
    return formatUsd(number / 1e9, 3, 3) + symbols[2];
  }
  // Check if the number is greater than or equal to 1 million
  else if (number >= 1e6) {
    return formatUsd(number / 1e6, 3, 3) + symbols[1];
  }
  // Check if the number is greater than or equal to 1 thousand
  else if (number >= 1e3) {
    return formatUsd(number / 1e3, 3, 3) + symbols[0];
  } else {
    return formatUsd(number, 3, 3); // Return the number with 3 decimal places
  }
}

const GlobalDirectives = {
  // install(Vue) {
  //   Vue.directive("click-outside", vClickOutside);
  // },
};

export default GlobalDirectives;
