<template>
  <v-container
    fluid
    style="display: flex; overflow-x: scroll; width: 100%"
    ma-0
  >
    <!-- {
          text: "Embarque",
          dateField: "DTA_EMBARQUE_BL",
          expectedDateField: "DTA_PREV_EMBARQUE_BL",
          imagePath: "@/assets/icons/ship.png",
        }, -->
    <div
      v-for="(item, index) in items"
      :key="index"
      style="
        background-color: transparent;
        min-width: 230px;
        min-height: 300px;
        margin-top: 50px;
        margin-left: 0px;
        position: relative;
        display: flex;
        flex-direction: column;

        align-items: center;
      "
    >
      <div
        :class="{ primary: item.date }"
        style="
          background-color: gray;
          margin: 0px;
          border-radius: 100%;
          position: relative;
          top: 0px;
          width: 80px;
          height: 80px;
          z-index: 4;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <!-- src="@/assets/icons/handshake.png" -->
        <img
          :src="item.imagePath"
          class=""
          style="width: 40px; height: 40px; padding: 0px"
        />
        <!-- {{ item.imagePath }} -->
      </div>
      <div
        :class="{ green: item.date }"
        style="
          background-color: gray;
          margin-bottom: -20px;
          min-width: 30px;
          min-height: 30px;
          border-radius: 25px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          z-index: 3;
        "
      >
        <img
          v-if="item.date"
          src="@/assets/icons/check.png"
          class=""
          style="width: 25px; height: 25px; padding: 0px"
        />
      </div>
      <div>
        <div
          style="
            background-color: #e0dcdc;
            min-width: 300px;
            min-height: 10px;
            z-index: 2;
          "
        ></div>
        <div
          style="
            position: relative;
            margin: 25px;
            z-index: 4;
            text-align: center;
          "
        >
          <b style="margin: 2px">
            {{ item?.text?.toUpperCase() }}
          </b>
          <!-- <p style="margin: 2px">{{ item && item.date }}</p> -->
          <p><span v-if="item.date" v-date-format="item.date"></span></p>

          <b v-if="item.expectedDate" style="margin: 2px">DATA PREVISÃO</b>

          <p>
            <span
              v-if="item.expectedDate"
              v-date-format="item.expectedDate"
            ></span>
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "HorizontalTimeline",
  props: ["items"],
};
</script>

<style>
.green {
  background-color: green;
}
</style>
