<template>
  <v-app id="inspire">
    <v-container
      v-if="user?.role?.id == 6 || true"
      fluid
      style="min-height: 100vh; width: 100%; margin-top: 50px"
    >
      <h2>Distribuição</h2>
      <v-container fluid class="dashboard-width">
        <v-row class="pa-2">
          <v-col cols="12" lg="3" md="5" sm="5" class="pa-0 ma-0">
            <v-container class="ma-0 pa-0">
              <v-card class="ma-0 mt-6"
                ><v-card-title style="font-color: #6613d0!"
                  ><span>Filtros</span>
                </v-card-title>

                <v-card-text class="mt-4">
                  <!-- v-if="!(user && user.cmn025 && user.profileId == 3)" -->
                  <AutocompleteEnterprise
                    label="Cliente"
                    :items="optionsEncomendantes"
                    v-model="chipsEncomendantes"
                  ></AutocompleteEnterprise>
                  <DateInput
                    v-model="filters.dateStart"
                    label="Período de"
                  ></DateInput>
                  <DateInput
                    v-model="filters.dateEnd"
                    label="Período Até"
                  ></DateInput>

                  <v-btn class="primary" @click="doSearch" :disabled="loading"
                    >Buscar
                    <v-progress-circular
                      v-show="loading"
                      indeterminate
                      color="white"
                    ></v-progress-circular>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-container>
          </v-col>

          <v-col cols="12" lg="9" md="7" sm="7" class="pa-0 ma-0">
            <v-container fluid style="">
              <v-row>
                <v-col cols="4" class="pa-2 mx-0">
                  <h4>Comitê</h4>
                  <DashboardValueCard
                    title="Limite"
                    icon="mdi-handshake-outline"
                    iconColor="#37a7b6"
                    :value="summary?.comite.limite"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Consumo"
                    icon="mdi-currency-usd"
                    :value="summary?.comite.consumo"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Saldo"
                    icon="mdi-currency-usd"
                    iconColor="#a5e348"
                    :value="summary?.comite.saldo"
                  ></DashboardValueCard>
                </v-col>
                <v-col cols="4" class="pa-2 mx-0">
                  <h4>Seguradora</h4>
                  <DashboardValueCard
                    title="Limite"
                    icon="mdi-handshake-outline"
                    iconColor="#37a7b6"
                    :value="summary?.seguradora.limite"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Consumo"
                    icon="mdi-currency-usd"
                    :value="summary?.seguradora.consumo"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Saldo"
                    icon="mdi-currency-usd"
                    iconColor="#a5e348"
                    :value="summary?.seguradora.saldo"
                  ></DashboardValueCard>
                </v-col>
                <v-col cols="4" class="pa-2 mx-0">
                  <h4>Outros</h4>
                  <DashboardValueCard
                    title="Limite"
                    icon="mdi-handshake-outline"
                    iconColor="#37a7b6"
                    :value="summary?.outros.limite"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Consumo"
                    icon="mdi-currency-usd"
                    :value="summary?.outros.consumo"
                  ></DashboardValueCard>
                  <DashboardValueCard
                    title="Saldo"
                    icon="mdi-currency-usd"
                    iconColor="#a5e348"
                    :value="summary?.outros.saldo"
                  ></DashboardValueCard>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid>
        <v-card class="my-0">
          <v-tabs
            style="min-height: 600px"
            color="primary accent-4"
            active-class="primary white--text"
          >
            <v-tab style="background-color: lightgray">Empresas</v-tab>

            <v-tab
              style="background-color: lightgray"
              v-for="(item, index) in enterprisesDetails"
              :key="index"
            >
              <span>
                Detalhes - {{ item.dpeNomPessoa?.substring(0, 20) }} -
                <button style="font-size: large" @click="closeDetails(item)">
                  x
                </button>
              </span>
            </v-tab>
            <!-- Invoices -->
            <v-tab-item>
              <v-container fluid>
                <v-data-table
                  :headers="headers"
                  :items="enterprises"
                  item-key="name"
                  class="custom-table"
                  :page.sync="page"
                  :items-per-page.sync="itemsPerPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :server-items-length.sync="serverItemsLength"
                  :footer-props="{
                    'items-per-page-options': [10, 25, 50],
                  }"
                  @update:sort-by="updateSortBy"
                  @update:sort-desc="updateSortDesc"
                  @update:items-per-page="updateRowsPerPage"
                  @update:page="updatePage"
                  ref="dataTable"
                >
                  <template v-slot:item.id="{ item }">
                    <!-- <span v-money-format-zero="item.id"></span> -->
                    <!-- <v-btn class="primary" @click="showDetails(item)">
         </v-btn
                    > -->
                    <v-icon color="primary" @click="showDetails(item)">
                      mdi-magnify
                    </v-icon>
                  </template>

                  <template v-slot:item.LIMITE_SIMPLES="{ item }">
                    <span v-money-format-zero="item.LIMITE_SIMPLES"></span>
                  </template>
                  <template v-slot:item.LIMITE_COMITE="{ item }">
                    <span v-money-format-zero="item.LIMITE_COMITE"></span>
                  </template>
                  <template v-slot:item.LIMITE_SEGUR="{ item }">
                    <span v-money-format-zero="item.LIMITE_SEGUR"></span>
                  </template>

                  <template v-slot:item.LIMITE_OUTROS="{ item }">
                    <span v-money-format-zero="item.LIMITE_OUTROS"></span>
                  </template>

                  <template v-slot:item.VLR_TOTAL_PEDIDO_COMPRA_CONV="{ item }">
                    <span
                      v-money-format-zero="item.VLR_TOTAL_PEDIDO_COMPRA_CONV"
                    ></span>
                  </template>
                  <template v-slot:item.VLR_TOTAL_PROFORMA_CONV="{ item }">
                    <span
                      v-money-format-zero="item.VLR_TOTAL_PROFORMA_CONV"
                    ></span>
                  </template>
                  <template v-slot:item.VLR_TOTAL_INVOICE_CONV="{ item }">
                    <span
                      v-money-format-zero="item.VLR_TOTAL_INVOICE_CONV"
                    ></span>
                  </template>
                  <template v-slot:item.VLR_TOTAL_NF="{ item }">
                    <span v-money-format-zero="item.VLR_TOTAL_NF"></span>
                  </template>
                  <template v-slot:item.VLR_TOTAL_PAGO="{ item }">
                    <span v-money-format-zero="item.VLR_TOTAL_PAGO"></span>
                  </template>

                  <template v-slot:item.SALDO_LIMITE_COMITE="{ item }">
                    <span v-money-format-zero="item.SALDO_LIMITE_COMITE"></span>
                  </template>
                  <template v-slot:item.SALDO_LIMITE_SEGURADORA="{ item }">
                    <span
                      v-money-format-zero="item.SALDO_LIMITE_SEGURADORA"
                    ></span>
                  </template>
                  <template v-slot:item.SALDO_TOTAL_LIMITE="{ item }">
                    <span v-money-format-zero="item.SALDO_TOTAL_LIMITE"></span>
                  </template>

                  <template v-slot:item.SALDO_LIMITE_TOTAL="{ item }">
                    <span v-money-format-zero="item.SALDO_LIMITE_TOTAL"></span>
                  </template>

                  <template v-slot:item.SALDO_LIMITE_OUTROS="{ item }">
                    <span v-money-format-zero="item.SALDO_LIMITE_OUTROS"></span>
                  </template>

                  <template v-slot:item.totalDuplicatasPrevistas="{ item }">
                    <span
                      v-money-format-zero="item.totalDuplicatasPrevistas"
                    ></span>
                  </template>

                  <template v-slot:item.VL_PED_CARTEIRA="{ item }">
                    <span v-money-format-zero="item.VL_PED_CARTEIRA"></span>
                  </template>

                  <template v-slot:item.VL_PED_FATURADOS="{ item }">
                    <span v-money-format-zero="item.VL_PED_FATURADOS"></span>
                  </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
            <v-tab-item
              v-for="(item, index) in enterprisesDetails"
              :key="index"
            >
              <br />
              <v-tabs
                style="min-height: 600px"
                color="primary accent-4"
                active-class="primary white--text"
              >
                <v-tab style="background-color: lightgray">Realizado</v-tab>
                <v-tab style="background-color: lightgray">Projeção</v-tab>

                <!-- Duplicatas -->
                <v-tab-item>
                  <EnterpriseDetailsDistributionDuplicatas
                    :enterprise="item"
                  ></EnterpriseDetailsDistributionDuplicatas>
                </v-tab-item>
                <!-- Projeção -->
                <v-tab-item>
                  <EnterpriseDetailsDistributionDuplicatasPrevistas
                    :enterprise="item"
                  ></EnterpriseDetailsDistributionDuplicatasPrevistas>
                </v-tab-item>
              </v-tabs>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-container>

      <!-- Dados  -->
      <v-container class="ma-0 pa-8" fluid>
        <v-card class="mx-auto my-0" style="height: 100%">
          <v-card-text><h4>Dados em R$</h4> </v-card-text>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th v-for="(item, index) in data" :key="index">
                      {{ item.month }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Contas a receber Máximo do Período</td>
                    <td v-for="(item, index) in data" :key="index">
                      <span
                        v-money-format-zero="item.recebiveisNPrevistos"
                      ></span>
                    </td>
                  </tr>

                  <tr>
                    <td>Contas a receber Máximo do Período - Projeção</td>
                    <td v-for="(item, index) in data" :key="index">
                      <span
                        v-money-format-zero="item.recebiveisPrevistos"
                      ></span>
                    </td>
                  </tr>

                  <tr>
                    <td>Total</td>
                    <td v-for="(item, index) in data" :key="index">
                      <span v-money-format-zero="item.recebiveis"></span>
                    </td>
                  </tr>

                  <tr>
                    <td v-for="(item, index) in data" :key="index"></td>
                  </tr>

                  <!-- <tr>
                    <td>Faturamento</td>
                    <td v-for="(item, index) in data" :key="index">
                      <span v-money-format-zero="item.faturamentosNPrevistos"></span>
                    </td>
                  </tr> -->

                  <tr>
                    <td>Faturamento projetado</td>
                    <td v-for="(item, index) in data" :key="index">
                      <span v-money-format-zero="item.faturamentos"></span>
                    </td>
                  </tr>

                  <tr>
                    <td>Saldo total</td>
                    <td v-for="(item, index) in data" :key="index">
                      <!-- {{  item.recebiveis }} -->
                      <span
                        v-money-format-zero="
                          item.saldo_acumulado_comite +
                          item.saldo_acumulado_seguradora
                        "
                      ></span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-container>
      <!-- Gráficos -->
      <v-container class="ma-0 pa-8" fluid>
        <v-row class="pa-2">
          <v-col cols="12" style="" class="mt-4">
            <!-- <v-btn @click="loadData">Load data</v-btn> -->
            <v-row>
              <v-spacer></v-spacer>
              <!-- 
              <v-switch
                label="Projeção futura"
                v-model="showProjected"
              ></v-switch> -->
            </v-row>
            <v-card class="mx-auto my-0" style="height: 100%">
              <v-card-text><h4>Dados em R$</h4> </v-card-text>
              <v-card-text style="display: flex; justify-content: center">
                <CustomChart
                  :chart-data="chartData"
                  :chart-options="chartOptions"
                ></CustomChart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container v-else>
      <p>Acesso não autorizado</p>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import DashboardValueCard from "../../components/cards/DashboardValueCard.vue";
import DateInput from "../../components/fields/DateInput";
import CustomChart from "../../components/charts/CustomChart";
import enterprises from "../../../../libraries/strapi/enterprises";
import EnterpriseDetailsDistributionDuplicatas from "../enterprises/EnterpriseDetailsDistributionDuplicatas";
import EnterpriseDetailsDistributionDuplicatasPrevistas from "../enterprises/EnterpriseDetailsDistributionDuplicatasPrevistas";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import AutocompleteEnterprise from "../../components/select/AutocompleteEnterprise.vue";
export default {
  name: "EnterprisesDistribution",
  components: {
    DashboardValueCard,
    DateInput,
    CustomChart,
    EnterpriseDetailsDistributionDuplicatas,
    EnterpriseDetailsDistributionDuplicatasPrevistas,
    AutocompleteSelect,
    AutocompleteEnterprise,
  },
  data() {
    return {
      showProjected: true,
      encomendantes: [],
      optionsEncomendantes: [],
      ansTotals: {},
      duplicatasPedidos: [],
      year_months: [],
      chipsEncomendantes: [],
      files: [],
      jsonData: [],
      search: "BLUEQUEST",
      loading: false,
      summaryData: {},
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "dpeNomPessoa",
        },
        // {
        //   text: "Vl Pedidos",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOTAL_PEDIDO_COMPRA_CONV",
        // },
        {
          text: "Vl Pedidos Fat.",
          align: "start",
          sortable: false,
          value: "VL_PED_FATURADOS",
        },
        // {
        //   text: "Vl. Pedidos Carteira",
        //   align: "start",
        //   sortable: false,
        //   value: "totalDuplicatasPrevistas",
        // },
        {
          text: "Vl. Pedidos Carteira",
          align: "start",
          sortable: false,
          value: "VL_PED_CARTEIRA",
        },

        //

        // {
        //   text: "Total proforma",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOTAL_PROFORMA_CONV",
        // },

        // {
        //   text: "Total invoice",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOTAL_INVOICE_CONV",
        // },

        // {
        //   text: "Total faturado",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOTAL_NF",
        // },

        // {
        //   text: "Total pago",
        //   align: "start",
        //   sortable: false,
        //   value: "VLR_TOTAL_PAGO",
        // },
        {
          text: "Limite comitê",
          align: "start",
          sortable: false,
          value: "LIMITE_COMITE",
        },

        {
          text: "Limite seguradora",
          align: "start",
          sortable: false,
          value: "LIMITE_SEGUR",
        },
        {
          text: "Limite outros",
          align: "start",
          sortable: false,
          value: "LIMITE_OUTROS",
        },
        {
          text: "Saldo comitê",
          align: "start",
          sortable: false,
          value: "SALDO_LIMITE_COMITE",
        },
        {
          text: "Saldo seguradora",
          align: "start",
          sortable: false,
          value: "SALDO_LIMITE_SEGURADORA",
        },
        {
          text: "Saldo outros",
          align: "start",
          sortable: false,
          value: "SALDO_LIMITE_OUTROS",
        },
        {
          text: "Saldo total",
          align: "start",
          sortable: false,
          value: "SALDO_LIMITE_TOTAL",
        },
        // total_vlr_pedido_compra: 0,
        //   total_vlr_proforma: 0,
        //   total_vlr_invoice: 0,
        //   total_consumo_comite: 0,
        //   total_consumo_seguradora: 0,
        //   total_vlr_faturado: 0,
        //   total_vlr_pago: 0,
      ],
      enterprises: [],
      sortBy: "dpeNomPessoa",
      sortDesc: false,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      filters: {
        dateStart: "",
        dateEnd: "",
        projected: false,
      },
      summary: {
        comite: {
          limite: 0,
          consumoImportacao: 0,
          consumoDistribuicao: 0,
          consumo: 0,
          saldo: 0,
        },
        seguradora: {
          limite: 0,
          consumoImportacao: 0,
          consumoDistribuicao: 0,
          consumo: 0,
          saldo: 0,
        },
        outros: {
          limite: 0,
          consumoImportacao: 0,
          consumoDistribuicao: 0,
          consumo: 0,
          saldo: 0,
        },
      },

      data: [],
      chartData: {
        type: "bar",
        data: {
          labels: [],
          datasets: [
            {
              label: "Contas a receber Máximo do Período",
              backgroundColor: "lightgray",
              borderColor: "lightgray",
              borderWidth: 1,
              data: [
                // 10909, 12866, 11032, 12811, 12782, 5105, 4046, 10501, 10158,
                // 10126, 4500, 11443,
              ],
              animations: { numbers: {}, colors: {} },
            },
            // {
            //   type: "line",
            //   label: "Desembolso Máximo do Período",
            //   borderColor: "rgb(31,78,121)",
            //   backgroundColor: "rgb(31,78,121)",
            //   data: [
            //     // 16140, 21756, 26016, 15520, 16383, 25010, 15566, 16242, 15669,
            //     // 22454, 19817, 24409,
            //   ],
            //   animations: { colors: {}, numbers: {} },
            // },
          ],
        },
      },
      chartOptions: {
        responsive: true,
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if (value && length <= 1) {
                return this.formatLabelChartMoney(value);
              } else return "";
            },
            anchor: "end",
            align: "top",
            clamp: true,
            color: "#000",
            offset: 5,
            padding: {
              bottom: 0,
              top: 0,
            },
            font: {
              family: "Poppins",
              size: 10,
              weight: "bold",
            },
          },

          legend: {
            position: "top",
            fontColor: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,

            color: this?.$vuetify?.theme?.themes?.light?.chartPrimaryColor,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },

      enterprisesDetails: [],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  watch: {
    filters(val, oldVal) {
      // alert("changeFilter");
    },

    showProjected(val, oldVal) {
      this.loadChart();
    },
  },

  async mounted() {
    await this.loadEncomendantes();

    // await this.doSearch();
    //  this.loadData();
  },
  methods: {
    formatLabelChartMoney(value) {
      if (value >= 10 ** 9) {
        return `${this.formatMoney(
          value / 10 ** 9,
          11 - Math.floor(Math.log(value) / Math.log(10))
        )} b`;
      } else {
        if (value >= 10 ** 6) {
          return `${this.formatMoney(
            value / 10 ** 6,
            8 - Math.floor(Math.log(value) / Math.log(10))
          )} M`;
        } else {
          return `${this.formatMoney(
            value / 10 ** 3,
            5 - Math.floor(Math.log(value) / Math.log(10))
          )} K`;
        }
      }
    },
    formatMoney(value, maximumFractionDigits, minimumFractionDigits) {
      var d = Math.round(value * 100) / 100;
      if (maximumFractionDigits < 0) maximumFractionDigits = 0;
      return new Intl.NumberFormat("pt-BR", {
        maximumFractionDigits:
          maximumFractionDigits !== null ? maximumFractionDigits : 2,
        minimumFractionDigits:
          maximumFractionDigits !== null ? maximumFractionDigits : 2,
      }).format(d || 0);
    },
    async loadEncomendantes() {
      let encomendantes = await strapiApi.enterprises.getOptionsDistribution();

      this.encomendantes = encomendantes;

      const adminView =
        this.user?.role?.id == 1 ||
        this.user?.role?.id == strapiApi.roleIds.admin ||
        this.user?.role?.id == strapiApi.roleIds.manager;
      if (!adminView) {
        this.optionsEncomendantes = this.user?.enterprises?.map((el) => ({
          id: el?.id,
          dpeNomPessoa: el?.dpeNomPessoa?.substring(0, 20),
        }));

        if (this.optionsEncomendantes?.length == 1) {
          // this.chipsEncomendantes = this.user?.enterprises.map((el) => ({
          //   id: el?.id,
          //   dpeNomPessoa: el?.dpeNomPessoa?.substring(0, 20),
          // }));
          this.chipsEncomendantes = this.user?.enterprises.map((el) => el?.id);
        }
      } else {
        this.optionsEncomendantes = this.encomendantes.map((el) => ({
          id: el?.id,
          dpeNomPessoa: el?.dpeNomPessoa?.substring(0, 20),
        }));
      }
    },
    closeDetails(item) {
      this.enterprisesDetails = this.enterprisesDetails.filter(
        (el) => el.id !== item.id
      );
    },
    showDetails(item) {
      this.enterprisesDetails.push({
        id: item.id,
        dpeNomPessoa: item.dpeNomPessoa,
      });
    },

    formatMonthString(monthString) {
      //monthString = 01-2024
      let monthsShort = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];

      let items = monthString?.split("-");

      if (items?.length > 0) {
        let monthIndex = parseInt(items[1]);
        let year = items[0]?.slice(2);

        if (monthIndex !== null && year) {
          return `${monthsShort[monthIndex - 1]}/${year}`;
        }
      }
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      this.loading = true;

      if (this.optionsEncomendantes.length == 0) {
        alert("Solicitar acesso ao TI");
        this.loading = false;

        return;
      }

      let encomendantes = this.chipsEncomendantes;
      if (this.chipsEncomendantes.length == 0) {
        // encomendantes = this.optionsEncomendantes;
        encomendantes = this.optionsEncomendantes.map((el) => el.id);
      }

      await this.getSummary(
        encomendantes
        //  encomendantes?.map((el) => el.id)
      );

      let { values, pagination } =
        await strapiApi.enterprises.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          encomendantes
          // encomendantes?.map((el) => el.id)
        );

      let enterprises = values;

      this.enterprisesDetails = this.enterprisesDetails?.filter(
        (detailEnterprise) => {
          let foundEnterprise = enterprises.find(
            (el) => el.id == detailEnterprise.id
          );
          return foundEnterprise ? true : false;
        }
      );

      this.serverItemsLength = pagination?.total;

      let dateStart;
      let dateEnd;

      if (this.filters.dateStart) {
        dateStart = new Date(`${this.filters.dateStart}-01T03:00:00.000Z`);
      }

      if (this.filters.dateEnd) {
        dateEnd = new Date(`${this.filters.dateEnd}-01T03:00:00.000Z`);
        dateEnd.setMonth(dateEnd.getMonth() + 1);
        dateEnd.setDate(1);
      }

      // let ans = await strapiApi.enterprises.getTotals(
      //   encomendantes,
      //   dateStart?.toISOString(),
      //   dateEnd?.toISOString()
      // );

      let ans = await strapiApi.enterprises.getTotalsDistribution(
        encomendantes,
        // encomendantes?.map((el) => el.id),
        dateStart?.toISOString(),
        dateEnd?.toISOString()
      );

      let { saldoDuplicatas, saldoFaturamentos, totalDuplicatasPrevistas } =
        ans;

      this.ansTotals = ans;

      this.loadChart();

      this.enterprises = enterprises.map((enterprise) => ({
        ...enterprise,
        saldoContasReceber: saldoDuplicatas
          ?.filter((el) => el.pes_cod == enterprise.id && el.doc_tip == 1)
          ?.reduce((acc, curr) => acc + (curr.aberto ?? 0), 0),

        saldoPagar: saldoDuplicatas
          ?.filter((el) => el.pes_cod == enterprise.id && el.doc_tip == 2)
          ?.reduce((acc, curr) => acc + (curr.aberto ?? 0), 0),

        saldoFaturamentos: saldoFaturamentos
          ?.filter((el) => el.pes_cod == enterprise.id && el.doc_tip == 1)
          ?.reduce((acc, curr) => acc + (curr.total ?? 0), 0),
        totalDuplicatasPrevistas: totalDuplicatasPrevistas
          ?.filter((el) => el.pes_cod == enterprise.id && el.doc_tip == 1)
          ?.reduce((acc, curr) => acc + (curr.aberto ?? 0), 0),
      }));

      this.loading = false;
    },
    getUniqueItems(array) {
      return [...new Set(array)];
    },

    loadChart() {
      let ans = this.ansTotals;
      let {
        duplicatasPedidos,
        duplicatasPrevistasPedidos,
        faturamentos,
        faturamentosPrevistos,
        saldoDuplicatas,
        saldoFaturamentos,
      } = ans;

      this.duplicatasPedidos = duplicatasPedidos;

      let year_months_db;

      if (this.showProjected) {
        year_months_db = this.getUniqueItems([
          ...duplicatasPedidos.map((el) => el.year_month),
          ...duplicatasPrevistasPedidos.map((el) => el.year_month),
          // ...faturamentos.map((el) => el.year_month),
          ...faturamentosPrevistos.map((el) => el.year_month),
        ]);
      } else {
        year_months_db = this.getUniqueItems([
          ...duplicatasPedidos.map((el) => el.year_month),
          //   ...faturamentos.map((el) => el.year_month),
        ]);
      }
      year_months_db = year_months_db
        .filter((el) => el)
        .sort(
          (a, b) =>
            a &&
            b &&
            new Date(`${a}-01T03:00:00.000Z`).getTime() -
              new Date(`${b}-01T03:00:00.000Z`).getTime()
        );

      let startYearMonth = year_months_db[0];
      let endYearMonth = year_months_db[year_months_db.length - 1];

      let year_months = [];
      let d = new Date(`${startYearMonth}-01T03:00:00.000Z`);
      let dEnd = new Date(`${endYearMonth}-01T03:00:00.000Z`);
      //dates.push(d);

      while (d.getTime() <= dEnd.getTime()) {
        year_months.push(d.toISOString().substring(0, 7));
        d.setMonth(d.getMonth() + 1);
      }

      this.year_months = year_months;

      if (this.showProjected) {
        this.loadDuplicatasPedidos(
          year_months,
          duplicatasPedidos,
          duplicatasPrevistasPedidos,
          faturamentos,
          faturamentosPrevistos,
          saldoDuplicatas,
          saldoFaturamentos
        );
      } else {
        this.loadDuplicatasPedidos(
          year_months,
          duplicatasPedidos,
          null,
          faturamentos,
          null,
          saldoDuplicatas,
          saldoFaturamentos
        );
      }
    },
    loadDuplicatasPedidos(
      year_months,
      duplicatasPedidos,
      duplicatasPrevistasPedidos,
      dadosFaturamentos,
      dadosFaturamentosPrevistos,
      saldoDuplicatas,
      saldoFaturamentos
    ) {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      let data = [];
      // let saldo_acumulado_comite = this.summary?.comite?.saldo;
      // let saldo_acumulado_seguradora = this.summary?.seguradora?.saldo;

      let vlrSaldoPagar = saldoDuplicatas
        ?.filter((el) => el.doc_tip == 2)
        ?.reduce((acc, curr) => acc + (curr.aberto ?? 0), 0);

      let vlrSaldoContasReceber = saldoDuplicatas
        ?.filter((el) => el.doc_tip == 1)
        ?.reduce((acc, curr) => acc + (curr.aberto ?? 0), 0);

      let vlrSaldoFaturamentos = saldoFaturamentos
        ?.filter((el) => el.doc_tip == 1)
        ?.reduce((acc, curr) => acc + (curr.total ?? 0), 0);

      if (this.summary?.comite) {
        this.summary.comite.vlrSaldoPagar = vlrSaldoPagar;
      }

      if (this.summary?.seguradora) {
        this.summary.seguradora.vlrSaldoFaturamentos = vlrSaldoFaturamentos;
        this.summary.seguradora.vlrSaldoContasReceber = vlrSaldoContasReceber;
      }

      let saldo_acumulado_comite = this.summary?.comite?.limite - vlrSaldoPagar;
      let saldo_acumulado_seguradora =
        this.summary?.seguradora?.limite -
        vlrSaldoFaturamentos +
        vlrSaldoContasReceber;

      for (let m = 0; m <= year_months.length; m++) {
        let year_month = year_months[m];

        if (year_month) {
          let monthString = year_month;
          let pagamentos =
            duplicatasPedidos?.find(
              (el) => el.year_month == year_month && el.doc_tip == 2
            )?.aberto ?? 0;

          let pagamentosPrevistos =
            duplicatasPedidos?.find(
              (el) => el.year_month == year_month && el.doc_tip == 2
            )?.aberto ?? 0;

          pagamentos = pagamentos + pagamentosPrevistos;

          let recebiveisNPrevistos =
            duplicatasPedidos?.find(
              (el) => el.year_month == year_month && el.doc_tip == 1
            )?.aberto ?? 0;

          let recebiveisPrevistos =
            duplicatasPrevistasPedidos?.find(
              (el) => el.year_month == year_month && el.doc_tip == 1
            )?.aberto ?? 0;

          let recebiveis = recebiveisNPrevistos + recebiveisPrevistos;
          let limiteComite = 0;
          let limiteSeguradora = 0;

          // let faturamentosNPrevistos =
          //   dadosFaturamentos?.find(
          //     (el) => el.year_month == year_month && el.doc_tip == 1
          //   )?.total ?? 0;
          let faturamentosNPrevistos = 0;

          // let faturamentosPrevistos =
          //   dadosFaturamentosPrevistos?.find(
          //     (el) => el.year_month == year_month && el.doc_tip == 1
          //   )?.total ?? 0;

          let faturamentosPrevistos =
            dadosFaturamentosPrevistos?.find(
              (el) => el.year_month == year_month && el.doc_tip == 1
            )?.aberto ?? 0;

          let faturamentos = faturamentosNPrevistos + faturamentosPrevistos;

          saldo_acumulado_comite = saldo_acumulado_comite - pagamentos;

          // saldo_acumulado_seguradora =
          //   saldo_acumulado_seguradora - faturamentos + recebiveis;

          //Ajuste 13/09/04 - correção proposta por Cristian e fábio
          saldo_acumulado_seguradora =
            saldo_acumulado_seguradora -
            faturamentos +
            (m > 0 ? recebiveis : 0);

          data.push({
            monthString: monthString,
            month: this.formatMonthString(monthString),
            pagamentos,
            recebiveis,
            recebiveisNPrevistos,
            recebiveisPrevistos,
            faturamentosNPrevistos,
            faturamentosPrevistos,
            faturamentos,
            limiteComite,
            limiteSeguradora,
            saldo_acumulado_comite,
            saldo_acumulado_seguradora,
          });
        }
      }

      this.data = data;
      console.log(JSON.stringify({ data }));
      //return;
      this.chartData = {
        type: "bar",
        data: {
          labels: data.map((el) => el.month),
          datasets: [
            // {
            //   label: "Contas a receber Máximo do Período (R$)",
            //   backgroundColor: "lightgray",
            //   borderColor: "lightgray",
            //   borderWidth: 1,
            //   data: data.map((el) => el.recebiveis),
            // },
            // {
            //   type: "line",
            //   label: "Desembolso Máximo do Período (R$)",
            //   borderColor: "rgb(31,78,121)",
            //   backgroundColor: "rgb(31,78,121)",
            //   data: data.map((el) => el.pagamentos),
            // },
            {
              type: "line",
              label: "Contas a receber projeção Máximo do Período (R$)",
              borderColor: "rgb(31,78,121)",
              backgroundColor: "rgb(31,78,121)",
              data: data.map((el) => el.recebiveisPrevistos),
            },
            {
              label: "Contas a receber Máximo do Período (R$)",
              backgroundColor: "lightgray",
              borderColor: "lightgray",
              borderWidth: 1,
              data: data.map((el) => el.recebiveisNPrevistos),
            },
          ],
        },
      };
    },

    async getSummary(enterprises) {
      this.summaryData = await strapiApi.enterprises.getSummary2(enterprises);

      if (this.summaryData) {
        this.summary.comite.limite =
          this.summaryData?.totals[0]?.sum_limite_comite;

        this.summary.comite.consumo =
          this.summaryData?.totals[0]?.sum_limite_comite -
          this.summaryData?.totals[0]?.sum_saldo_limite_comite;

        this.summary.comite.saldo =
          this.summaryData?.totals[0]?.sum_saldo_limite_comite;

        this.summary.seguradora.limite =
          this.summaryData?.totals[0]?.sum_limite_segur;

        this.summary.seguradora.consumo =
          this.summaryData?.totals[0]?.sum_limite_segur -
          this.summaryData?.totals[0]?.sum_saldo_limite_seguradora;

        this.summary.seguradora.saldo =
          this.summaryData?.totals[0]?.sum_saldo_limite_seguradora;
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}

.dashboard-width {
  max-width: 2400px;
}
</style>
