const shisgoApi = {
  async createTracking(containerNumber, blContainersRef, emailAddress) {
    var url =
      "https://shipsgo.com/api/v1.1/ContainerService/PostContainerInfoWithBl";
    var authCode = "b8e05f7d0ca300b594509b113ad53002";

    var headers = { "Content-Type": "application/x-www-form-urlencoded" };

    // var payload = {};
    // payload["containerNumber"] = containerNumber;
    // payload["authCode"] = authCode;
    // payload["shippingLine"] = "";
    // payload["blContainersRef"] = blContainersRef;
    // payload["emailAddress"] = "lucas.issayama@gmail.com";
    // payload["referenceNo"] = "";
    var urlencoded = new URLSearchParams();
    urlencoded.append("containerNumber", containerNumber);
    urlencoded.append("authCode", "b8e05f7d0ca300b594509b113ad53002");
    urlencoded.append("shippingLine", "");
    urlencoded.append("blContainersRef", blContainersRef);
    urlencoded.append("emailAddress", emailAddress);
    urlencoded.append("referenceNo", "");

    var ans = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers,
      // body: JSON.stringify(dataSend),,
      //body: JSON.stringify(payload),
      body: urlencoded,
    })
      .then((res) => {
        console.log("first ans");
        console.log(res);

        //return res.text();
        const contentType = res.headers.get("Content-Type");
        if (contentType.includes("application/json")) {
          // Response is in JSON format
          return res.json();
        } else if (contentType.includes("text/plain")) {
          // Response is plain text
          return res.text();
        } else {
          // Handle other response types as needed
          throw new Error(`Unsupported response type: ${contentType}`);
        }
      })
      .then((json) => {
        console.log("sec ans");
        console.log(json);
        console.log("User added");

        return json;
      });

    return ans;
  },

  async createTrackingWithContainer(containerNumber, emailAddress) {
    var url =
      "https://shipsgo.com/api/v1.1/ContainerService/PostCustomContainerForm";
    var authCode = "b8e05f7d0ca300b594509b113ad53002";

    var headers = { "Content-Type": "application/x-www-form-urlencoded" };

    var urlencoded = new URLSearchParams();
    urlencoded.append("containerNumber", containerNumber);
    urlencoded.append("authCode", "b8e05f7d0ca300b594509b113ad53002");
    urlencoded.append("shippingLine", "");
    urlencoded.append("emailAddress", emailAddress);
    urlencoded.append("referenceNo", "");

    var ans = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers,
      // body: JSON.stringify(dataSend),,
      //body: JSON.stringify(payload),
      body: urlencoded,
    })
      .then((res) => {
        console.log("first ans");
        console.log(res);

        //return res.text();
        const contentType = res.headers.get("Content-Type");
        if (contentType.includes("application/json")) {
          // Response is in JSON format
          return res.json();
        } else if (contentType.includes("text/plain")) {
          // Response is plain text
          return res.text();
        } else {
          // Handle other response types as needed
          throw new Error(`Unsupported response type: ${contentType}`);
        }
      })
      .then((json) => {
        console.log("sec ans");
        console.log(json);
        console.log("User added");

        return json;
      });

    return ans;
  },

  async listDocs(filCod, priCod) {
    var url = "http://saintemarie.conexos.cloud/api/arq020/TelaProcesso/list";

    var dataSend = {
      parameters: { codeView: "imp021", filCod: filCod, priCod: priCod },
    };
    var myHeaders = {};
    myHeaders["Accept"] = "application/json, text/plain, */*";
    myHeaders["Accept-Language"] = "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7";
    myHeaders["Cnx-dataLanguage"] = "pt";
    myHeaders["Cnx-filCod"] = "3";
    myHeaders["Cnx-usnCod"] = "659";
    myHeaders["Connection"] = "keep-alive";
    myHeaders["Content-Type"] = "application/json;charset=UTF-8";
    myHeaders["Cookie"] = "sid=b2ba6dda-9126-4985-aa10-858691105a4c";

    var ans = await fetch(url, {
      method: "POST",
      mode: "cors",
      // headers: {
      //   "Content-Type": "application/json",
      //   // "Cnx-usnCod": window.conexosUsnCod,
      //   // "Cnx-filCod": filCod,
      //   "Cnx-usnCod": "659",
      //   "Cnx-filCod": "3",
      //   "Cnx-dataLanguage": "pt",
      // },
      headers: myHeaders,
      // body: JSON.stringify(dataSend),,
      body: '{"parameters":{"codeView":"imp021","filCod":3,"priCod":16079}}',
    })
      .then((res) => {
        console.log("first ans");
        console.log(res);

        return res.text();
      })
      .then((json) => {
        console.log("sec ans");
        console.log(json);
        console.log("User added");

        return json;
      });
    return ans;
  },
};

export default shisgoApi;
