<template>
  <v-card class="mt-4">
    <v-card-text style="max-height: 500px; background-color: white">
      <!-- background: linear-gradient(70deg, #153a5f, #00abc0); -->
      <v-container v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>

      <bar-chart
        :chart-data="dataCollection"
        :chart-options="options"
      ></bar-chart>
    </v-card-text>
    <!-- <v-card-text>
      <v-row>
        <v-col cols="6"> {{ label }}</v-col>
        <v-col cols="6">
          <v-row>
            <p>{{ sum }}</p>
            <p>Average{{ average }}</p>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="goTo(detailsPath)">Detalhes</v-btn>
      
          </v-row>
        </v-col>
      </v-row>
    </v-card-text> -->
  </v-card>
</template>

<script>
import BarChart from "./BarChart2.vue";
export default {
  name: "chart-card",
  components: {
    // LineChart,
    // BarChart,
    BarChart,
  },
  props: [
    "datalogs",
    "max",
    "min",
    "label",
    "chartType",
    "chartPeriod",
    "detailsPath",
    "loading",
  ],
  data() {
    return {
      dataCollection: null,
      options: null,
      sum: 0,
      average: 0,
    };
  },
  watch: {
    // whenever question changes, this function will run
    datalogs(newDatalogs, oldDataLogs) {
      console.log("will update chart");
      this.updateChartCollection(newDatalogs);
    },
    // loading(newLoading, oldLoading) {
    //   console.log("will update chart loading");
    //   this.updateChartCollection(this.datalogs);
    // }
  },
  mounted() {
    //this.updateChartCollection(this.datalogs);
  },
  methods: {
    goTo(detailsPath) {
      this.$router.push(detailsPath);
    },
    updateChartCollection(datalogs) {
      average =
        datalogs.reduce(function (acc, val) {
          return acc + val.value;
        }, 0) / datalogs.length;

      datalogs = datalogs.filter((el) => el.value < average * 5);

      var sum = datalogs.reduce(function (acc, val) {
        return acc + val.value;
      }, 0);
      this.sum = sum;

      var average = sum / datalogs.length;

      this.average = average;

      var dataCollection = {
        datasets: [
          {
            lineTension: 0,
            // label: this.label ? this.label : "",
            label: "2023",
            fontColor: "#015384",
            backgroundColor: "#015384",
            borderColor: "#015384",
            //pointStyle: "rect",
            pointBackgroundColor: "#015384",
            fill: false,
            data: [],
          },

          {
            lineTension: 0,
            label: "2022",
            fontColor: "#08b4ec",
            backgroundColor: "#08b4ec",
            borderColor: "#08b4ec",
            //pointStyle: "rect",
            pointBackgroundColor: "#08b4ec",
            fill: false,
            data: [],
          },
        ],
      };

      var options = {
        responsive: true,
        maintainAspectRatio: false,
        color: "#015384",
        scales: {
          x: {
            type: "time",
            ticks: { color: "#015384" },
            time: {
              unit: "month",
              //unit: "day",
            },
          },
          y: {
            ticks: { color: "#015384" },
          },
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data) => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(2) + "%";
              return percentage;
            },
            color: "#000",
          },
          legend: {
            position: "top",
            fontColor: "#015384",

            color: "#015384",
          },
        },
      };

      if (this.max) options.scales.yAxes[0].ticks.max = parseFloat(this.max);
      if (this.min) options.scales.yAxes[0].ticks.min = parseFloat(this.min);
      // if (this.max) options.scales.yAxes.ticks.max = this.max;

      for (var a = 0; a < datalogs.length; a++) {
        dataCollection.datasets[0].data.push({
          x: new Date(datalogs[a].createdAt),
          //   y: value[a][this.formSearch.tagId]
          y: datalogs[a].value,
        });

        dataCollection.datasets[1].data.push({
          x: new Date(datalogs[a].createdAt),
          //   y: value[a][this.formSearch.tagId]
          y: datalogs[a].value,
        });
      }
      this.dataCollection = dataCollection;
      this.options = options;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped></style>
