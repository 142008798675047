import functions from "./functions";
var notifications = {
  async getAll() {
    const start = Date.now();

    var filters = {};

    var query = `query notifications{
      notifications(sort:"id"){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            description
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination notifications time: ${
          (end - start) / 1000
        } s`
      );

    const pagination = res?.data?.notifications?.meta?.pagination;
    const values = functions.sanitize(res.data.notifications.data);
    return { values, pagination };
  },
};

export default notifications;
