export default {
    state: {
        user: null,
        token: null,
        status: "Desativado",
    },
    mutations: {
        SET_USER(state, payload) {
            state.user = payload;
        },
        SET_TOKEN(state, payload) {
            state.token = payload;
        },
        SET_ACCESS(state, payload) {
            state.status = payload;
        },

        
        CLEAR_USER(state) {
            state.user = null
        },
        CLEAR_TOKEN(state) {
            state.token = null
        },
        CLEAR_ACCESS(state) {
            state.status = "Desativado"
        }
    },
    actions: {

    }
}