<template>
  <v-container fluid class="pa-4">
    <v-card class="mx-auto">
      <v-toolbar dark color="primary"
        ><h4>Ítens</h4>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="exportToExcel"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="white"> mdi-download </v-icon></v-btn
            >
          </template>
          <span>Exportar para excel</span>
        </v-tooltip>
      </v-toolbar>

      <div v-for="(invoice, index) in invoices">
        <v-toolbar style="background-color: lightgray">
          <b>Invoice: {{ invoice.INV_COD }}</b></v-toolbar
        >
        <v-data-table
          :headers="vbInvoiceHeaders"
          :items="invoice.items"
          item-key="name"
          class="custom-table elevation-1 font-weight-black elevation-1"
          color="blue"
          hide-default-footer
        >
          <template v-slot:item.PRD_LNG_COMPLEMENTO="{ item }">
            <p class="" style="margin: 10px">
              {{
                item.PRD_LNG_COMPLEMENTO
                  ? item.PRD_LNG_COMPLEMENTO
                  : item.PRD_DES_NOME
              }}
            </p>
          </template>

          <template v-slot:item.VLR_FINAL="{ item }">
            <span v-decimal-format="item.VLR_FINAL"></span>
            <!-- <b class="font-weight-black"> {{   item.MOE_ESP_SIGLA }}</b> -->
            <span> {{ getMoedaSigla(invoice) }}</span>
          </template>

          <template v-slot:item.INI_PCT_II="{ item }">
            <span v-decimal-format="item.INI_PCT_II"></span>%
          </template>
          <template v-slot:item.INI_PCT_IPI="{ item }">
            <span v-decimal-format="item.INI_PCT_IPI"></span>%
          </template>
          <template v-slot:item.INI_PCT_PIS="{ item }">
            <span v-decimal-format="item.INI_PCT_PIS"></span>%
          </template>
          <template v-slot:item.INI_PCT_COFINS="{ item }">
            <span v-decimal-format="item.INI_PCT_COFINS"></span>%
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import GridField from "../../components/fields/GridField.vue";
import moment from "moment";
import { read, utils, writeFile } from "xlsx";

export default {
  name: "ProcessInvoices",
  props: ["invoices", "invoices_items"], // object: quote, product ...+
  components: {
    GridField,
  },
  data() {
    return {
      vbInvoiceHeaders: [
        { text: "Ítem", value: "INI_ITEM", width: "50px" },
        { text: "Ref. Interna", value: "PRD_COD", width: "50px" },
        { text: "Ref. Cliente", value: "INI_ESP_COD_EXTERNO", width: "50px" },
        { text: "Descrição", value: "PRD_LNG_COMPLEMENTO", width: "600px" },
        { text: "NCM", value: "TEC_ESP_COD", width: "50px" },
        { text: "Seq. NCM", value: "TEC_ESP_COD_SEQ", width: "20px" },
        { text: "Qtd", value: "INI_QTD_QUANTIDADE" },
        { text: "Unidade", value: "UND_DES_NOME", width: "50px" },
        { text: "Peso Bruto", value: "INI_PRE_PESO_BRUTO", width: "50px" },
        { text: "Peso Líquido", value: "INI_PRE_PESO_LIQUIDO", width: "50px" },
        { text: "Valor", value: "VLR_FINAL", width: "50px" },
        { text: "II", value: "INI_PCT_II", width: "20px" },
        { text: "IPI", value: "INI_PCT_IPI", width: "20px" },
        { text: "PIS", value: "INI_PCT_PIS", width: "20px" },
        { text: "COFINS", value: "INI_PCT_COFINS", width: "20px" },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {},
  methods: {
    getMoedaSigla(invoice) {
      if (invoice?.MOE_ESP_SIGLA) return invoice.MOE_ESP_SIGLA;
      if (invoice?.MOEDA_INV?.includes("EUR")) {
        return "EUR";
      }

      if (invoice?.MOEDA_INV?.includes("DOLAR")) {
        return "USD";
      }
      return invoice?.MOEDA_INV;
    },

    async exportToExcel() {
      // { text: "Invoice", value: "INV_COD" },
      //   { text: "Ítem", value: "INI_ITEM" },

      //{ text: "Ref. Interna", value: "PRD_COD", width: "50px" },
      //{ text: "Ref. Cliente", value: "INI_ESP_COD_EXTERNO", width: "50px" },

      let data = this.invoices_items.map((el) => {
        let row = {};
        row["Invoice"] = el.INV_COD;
        row["Ítem"] = el.INI_ITEM;

        row["Ref. Interna"] = el.PRD_COD;
        row["Ref. Cliente"] = el.INI_ESP_COD_EXTERNO;

        row["Descrição"] = el.PRD_LNG_COMPLEMENTO
          ? el.PRD_LNG_COMPLEMENTO
          : el.PRD_DES_NOME;
        row["NCM"] = el.TEC_ESP_COD;
        row["Qtd"] = el.PRU_QTD_EMBALAGEM;
        row["Valor"] = el.VLR_FINAL;

        row["Quantidade"] = el.INI_QTD_QUANTIDADE;
        row["Peso Bruto"] = el.INI_PRE_PESO_BRUTO;
        row["Peso Líquido"] = el.INI_PRE_PESO_LIQUIDO;
        row["Valor"] = el.VLR_FINAL;
        row["Valor"] = el.VLR_FINAL;
        row["Valor total"] = el.INI_PRE_VALORTOTAL;

        row["II"] = el.INI_PCT_II;

        row["IIP"] = el.INI_PCT_IPI;
        row["PIS"] = el.INI_PCT_PIS;
        row["COFINS"] = el.INI_PCT_COFINS;

        // INI_QTD_QUANTIDADE
        //                 INI_PRE_PESO_BRUTO
        //                 INI_PRE_PESO_LIQUIDO
        //                 INI_PRE_VALORTOTAL
        //                 INI_PCT_II
        //                 INI_PCT_IPI
        //                 INI_PCT_PIS
        //                 INI_PCT_COFINS
        return row;
      });

      const max_width = this.invoices_items.reduce(
        (w, r) => Math.max(w, r.PRD_DES_NOME.length),
        10
      );
      const ws = utils.json_to_sheet([
        {
          Processo:
            this.process &&
            this.process.invoices &&
            this.process.invoices.length > 0 &&
            this.process.invoices[0].INV_ESP_PO,
        },
      ]);

      ws["A1"].s = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "00dce6f1" },
          bgColor: { rgb: "00dce6f1" },
        },
        font: {
          sz: 24,
          bold: true,
          color: { rgb: "FFFFAA00" },
        },
      };

      const wb = utils.book_new();

      // Add a header row

      // utils.sheet_add_json(ws, [{ processo: "Processo" }], { origin: -1 });

      utils.sheet_add_json(ws, [{ "": "" }], { origin: -1 });

      const headerStyle = {
        font: { bold: true },
        alignment: { horizontal: "center" },
      };
      utils.sheet_add_json(ws, data, { origin: -1 });

      ws["!cols"] = [{ wch: max_width }];

      utils.book_append_sheet(wb, ws, "Data");

      writeFile(wb, "Ítens.xlsx");
    },

    formatDate(value) {
      if (value) return moment(value + 3 * 60 * 60 * 1000).format("DD/MM/YYYY");
      else return "";
    },
    formatDecimal(value) {
      //  var d = Math.round(value * 100) / 100;
      return new Intl.NumberFormat("pt-BR", {
        //  currency: "BRL",
        // style: "currency",
        // minimumFractionDigits: 2,
      }).format(value || 0);
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      // const { values, pagination } = await strapiApi.nfses.getWithPagination(
      //   this.page,
      //   this.itemsPerPage,
      //   this.sortBy,
      //   this.sortDesc,
      //   this.processId
      // );
      // //alert(JSON.stringify({ values, pagination }));
      // this.nfses = values;
      // //alert(JSON.stringify({  pagination }));
      // this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>

<style></style>
