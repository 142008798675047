<template>
  <v-container fluid>
    <!-- <v-card class="mx-auto">
      <v-toolbar dark color="primary"
        ><h4>Serviços - Nfse</h4>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="servicos"
        item-key="name"
        class="custom-table"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length.sync="serverItemsLength"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
        @update:items-per-page="updateRowsPerPage"
        @update:page="updatePage"
        ref="dataTable"
      >
        <template v-slot:item.DataEmissao="{ item }">
          <span v-if="item.DataEmissao" v-date-format="item.DataEmissao"></span>
        </template>
      </v-data-table>
    </v-card> -->
    <v-card fluid>
      <v-toolbar dark color="primary"
        ><h4>Serviços - Nfse</h4>
        <v-spacer></v-spacer>
      </v-toolbar>
      <CardSearchNfse :process-ref="processRef"></CardSearchNfse>
    </v-card>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import CardSearchNfse from "../../components/dialogs/CardSearchNfse.vue";

export default {
  name: "ProcessNfses",
  props: ["processId", "processRef"], // object: quote, product ...
  components: {
    CardSearchNfse,
  },
  data() {
    return {
      nfses: [],
      servicos: [],
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 15,
      // headers: [
      //   {
      //     text: "Id",
      //     value: "id",
      //     width: "20px",
      //     sortable: true,
      //   },
      //   {
      //     text: "Prestador",
      //     value: "PrestadorRazaoSocial",
      //     align: "start",
      //     sortable: false,
      //   },
      //   {
      //     text: "Data emissão",
      //     value: "DataEmissao",
      //     align: "start",
      //     sortable: false,
      //   },
      //   { text: "Pdf link", value: "pdfLink", sortable: false },

      //   {
      //     text: "Valor Serviços",
      //     value: "ServicoValorServicos",
      //     sortable: false,
      //   },
      //   { text: "Número", value: "Numero", sortable: false },
      // ],
      headers: [
        {
          text: "Nfse",
          value: "Nfse",
          width: "20px",
          sortable: true,
        },
        {
          text: "Prestador",
          value: "Prestador",
          align: "start",
          sortable: false,
        },
        {
          text: "Data emissão",
          value: "DataEmissao",
          align: "start",
          sortable: false,
        },
        { text: "Descrição", value: "Descricao", sortable: false },

        {
          text: "Valor Serviços",
          value: "ValorServico",
          sortable: false,
        },
        //{ text: "Número", value: "Numero", sortable: false },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    await this.doSearch();
    //alert("mounted");
  },
  methods: {
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      const { values, pagination } = await strapiApi.nfses.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.processId
      );

      //alert(JSON.stringify({ values, pagination }));
      this.nfses = values;

      let servicos = [];

      for (var i = 0; i < values.length; i++) {
        let value = values[i];
        if (value?.nfse_servicos?.length > 0) {
          let nfse_servicos = value?.nfse_servicos;

          for (var j = 0; j < nfse_servicos.length; j++) {
            let nfse_servico = nfse_servicos[j];
            //Adicionar serviços
            servicos.push({
              Nfse: value.Numero,
              Prestador: value.PrestadorRazaoSocial,
              DataEmissao: value.DataEmissao,
              Descricao: nfse_servico.Descricao,
              ValorServico: nfse_servico.ValorServico,
            });
          }
        } else {
          //Adicionar serviços
          // servicos.push({
          //   Nfse: value.Numero,
          //   Prestador: value.PrestadorRazaoSocial,
          //   DataEmissao: value.DataEmissao,
          //   Descricao: value.ServicoDiscriminacao,
          //   ValorServico: value.ServicoValorServicos,
          // });
        }
      }

      this.servicos = servicos;

      //alert(JSON.stringify({  pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>

<style></style>
