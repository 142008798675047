<template>
  <div>
    <v-dialog v-model="showDialog" width="600px" class="pa-0 ma-0">
      <v-card class="ma-0 pa-0">
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orimary" text @click="closeModal"> Fechar </v-btn>
        </v-card-actions> -->
        <v-card-text class="pa-0 ma-0">
          <v-toolbar class="primary" dark
            >Deseja realmente ativar o tracking ?

            <v-spacer></v-spacer>
            <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
          >
        </v-card-text>
        <div v-if="blContainersRef && containers && containers.length > 0">
          <v-card-text>
            <!-- <v-row>
              <GridField
                label="N° do House"
                :value="blContainersRef"
              ></GridField>
            </v-row> -->

            <v-row>
              <GridField
                label="Container number"
                :value="
                  containers && containers[0] && containers[0].containerNumber
                "
              ></GridField>
            </v-row>
          </v-card-text>

          <v-card-text>
            <p @click="test">
              Ativando o tracking você aceita a cobrança adicional dessa demanda
            </p>
          </v-card-text>
          <v-alert
            type="error"
            icon="mdi-check"
            class="pa-2"
            v-show="showError"
          >
            <v-row>
              <v-col cols="10">
                <span> {{ errorMessage }} </span></v-col
              >
              <v-col cols="1">
                <v-btn @click="closeError" color="transparent">
                  X
                </v-btn></v-col
              >
            </v-row>
          </v-alert>
          <v-card-actions>
            <v-btn class="primary" @click="closeModal">
              Não, prefiro aguardar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn color="green" @click="yes"> Sim, desejo continuar</v-btn>
          </v-card-actions>
        </div>
        <div v-else>
          <v-card-text class="mt-2">
            <!-- <p style="color: red">
              É necessária a informação do N° do House e o número do container
              para prosseguir
            </p> -->
            <p style="color: red">
              É necessária a informação do número do container para prosseguir
            </p>
          </v-card-text>
          <v-card-text>
            <!-- <v-row v-if="blContainersRef">
              <GridField
                label="N° do House"
                :value="blContainersRef"
              ></GridField>
            </v-row>
            <v-row v-else>
              <GridField label="N° do House" value="Não informado"></GridField>
            </v-row> -->

            <v-row v-if="containers && containers.length > 0">
              <GridField
                label="Container number"
                :value="
                  containers && containers[0] && containers[0].containerNumber
                "
              ></GridField>
            </v-row>
            <v-row v-else>
              <GridField
                label="Número do container"
                value="Não informado
                "
              ></GridField>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="closeModal"> ok</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import GridField from "../fields/GridField.vue";

export default {
  name: "activate-tracking-dialog",
  props: [
    "value",
    "showError",
    "errorMessage",
    "containers",
    "blContainersRef",
  ],
  components: {
    GridField,
  },
  data() {
    return {};
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        // alert("emit");
        this.$emit("input", value);
        // this.$emit("update", value);
      },
    },
  },

  methods: {
    test() {
      alert("test");
      this.$emit("input", false);
    },
    closeError() {
      this.$emit("closeError");
    },
    closeModal() {
      //this.$emit("close");
      this.showDialog = false;
    },

    // yes() {
    //   // alert("clicked");
    //   if (
    //     this.containers[0] &&
    //     this.containers[0].containerNumber &&
    //     this.blContainersRef
    //   )
    //     this.$emit("yes", {
    //       containerNumber:
    //         this.containers[0] && this.containers[0].containerNumber,
    //       blContainersRef: this.blContainersRef,
    //     });
    //   else alert("Informar container e BL");
    // },
    yes() {
      // alert("clicked");
      if (this.containers[0] && this.containers[0].containerNumber)
        this.$emit("yes", {
          containerNumber:
            this.containers[0] && this.containers[0].containerNumber,
          blContainersRef: this.blContainersRef ? this.blContainersRef : "",
        });
      else alert("Informar container e BL");
    },
  },
};
</script>
