var conexosApi = {
  async imp216List(filCod, priCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      priCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arq020List`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async login() {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    // myHeaders["Authorization"] =
    //   "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      username: "API_CONEXOS",
      password: "apisainte23",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log(`url:${process.env.VUE_APP_CONEXOS_URL}/api/login`);

    let ans = await fetch(
      `${process.env.VUE_APP_CONEXOS_URL}/api/login`,
      requestOptions
    )
      .then((res) => {
        console.log(JSON.stringify(res));
        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      });

    return ans;
  },
};

export default conexosApi;
