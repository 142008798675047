import functions from "./functions";
var nfses = {
  async getWithPagination(page, pageSize, sort, sortDesc, processId, search) {
    const start = Date.now();

    var filters = {};

    if (processId) {
      filters = {
        process: {
          id: {
            eq: parseInt(processId),
          },
        },
      };
    }

    var letters = search?.replace(/[^A-Za-z]/g, "");
    var numbersAndSpecial = search?.replace(/[A-Za-z]/g, "");
    var numbers = numbersAndSpecial?.split("/");

    if (search && (letters || numbers?.length > 0)) {
      filters = {
        and: [{ provider: { id: { ne: null } } }],
        //and: [],
      };
      if (letters) {
        filters.and.push({
          processRef: {
            contains: letters,
          },
        });
      }
      for (let i = 0; i < numbers.length; i++) {
        let numberSearch = numbers[i];
        if (i == 0) {
          numberSearch = parseInt(numberSearch).toString();
        }

        filters.and.push({
          processRef: {
            contains: numberSearch,
          },
        });
      }

      // {
      //   processRef: {
      //     contains: letters,
      //   },
      // },
    }
    console.log(JSON.stringify({ filters }));

    var query = `query nfses($filters:NfseFiltersInput!){
      nfses( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            processRef
            provider{
              data{
                id
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination nfses time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.nfses?.meta?.pagination;
    const values = functions.sanitize(res.data.nfses.data);
    return { values, pagination };
  },
};

export default nfses;
