<template>
  <v-container>
    <v-card style="width: 100%" v-if="user?.role?.id == 6" fluid>
      <v-toolbar class="primary" dark> Notificações</v-toolbar>

      <v-card-text
        mx-4
        v-for="(notification, index) in notifications"
        :key="index"
      >
        <!-- <v-checkbox :label="notification.description"></v-checkbox> -->
        <v-switch v-model="notification.active" class="custom-label-color">
          <template v-slot:label>
            <span style="color: black">{{ notification.description }}</span>
          </template>
        </v-switch>
      </v-card-text>

      <v-card-actions>
        <v-alert
          type="success"
          icon="mdi-check"
          class="pa-2"
          style="width: 100%"
          v-model="saveAlert"
        >
          Notificações atualizadas com sucesso
        </v-alert>
        <v-alert
          type="error"
          icon="mdi-alert"
          class="pa-2"
          v-model="errorAlert"
          style="width: 100%"
          >{{ errorMsg }}</v-alert
        >
      </v-card-actions>
      <v-card-actions mx-4 px-4>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="saving"
          mx-4
          px-4
          class="primary"
          @click="saveUserData"
          >Salvar
          <v-progress-circular
            v-show="saving"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
      <!-- <v-btn @click="testLogin">Test</v-btn> -->
    </v-card>

    <v-container v-else>
      <p>Acesso não autorizado</p>
    </v-container>
  </v-container>
</template>

<script>
import strapiApi from "../../../../libraries/strapi/strapiApi";
import conexosApi from "../../../../libraries/conexos/conexosApi";

import { mapState } from "vuex";
export default {
  name: "UserNotifications",
  data() {
    return {
      notifications: [],
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 5,
      serverItemsLength: 15,
      saving: false,
      errorMsg: "",
      errorAlert: false,
      errorAlert2: false,
      successAlert: false,
      saveAlert: false,

      headers: [
        {
          text: "Data de criação",
          value: "createdAt",
          width: "20px",
          sortable: true,
        },
        {
          text: "Usuário",
          value: "username",
          align: "start",
          sortable: false,
        },
        {
          text: "BL",
          value: "blContainersRef",
          align: "start",
          sortable: false,
        },
        { text: "Container", value: "containerNumber", sortable: false },
        { text: "Encomendante", value: "enterpriseName", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    await this.doSearch();
    //alert("mounted");
  },
  methods: {
    async testLogin() {
      let ans = await conexosApi.login();
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      const { values, pagination } = await strapiApi.notifications.getAll();

      let notifications = values.map((notification) => ({
        active: this?.user?.notifications?.find(
          (el) => parseInt(el.id) == parseInt(notification.id)
        )
          ? true
          : false,
        ...notification,
      }));

      this.notifications = notifications;
      this.serverItemsLength = pagination && pagination.total;

      //fullfill the notifications with the user information
    },
    async saveUserData() {
      //alert("saveUserData");
      this.saving = true;
      let ans;
      let notifications = this.notifications
        ?.filter((el) => el.active)
        ?.map((el) => el.id);
      //alert(JSON.stringify({ notifications }));
      ans = await strapiApi.users.updateNotifications(
        this.user.id,
        notifications
      );
      //console.log(JSON.stringify({ ans }));

      this.saving = false;
      // console.log(JSON.stringify({ ans }));

      await this.openAlertsSave(ans);
    },
    async openAlertsSave(ans) {
      if (ans.id) {
        this.saveAlert = true;
      } else if (ans?.error) {
        this.errorAlert = true;
        this.errorMsg = ans?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },

    closeAlerts() {
      this.saveAlert = false;
      this.errorAlert = false;
      this.errorAlert2 = false;
      this.successAlert = false;
    },
  },
};
</script>

<style>
.custom-label-color .v-label {
  color: red; /* Set the label color */
}
</style>
