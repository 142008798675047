<template>
  <div>
    <v-card class="ma-0 pa-0">
      <v-card-text>
        <v-row>
          <v-text-field
            label="Processo ref"
            placeholder="Referência de processo"
            v-model="search"
          ></v-text-field>

          <v-btn class="primary" @click="doSearch">Buscar</v-btn>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="nfses"
          item-key="name"
          class="custom-table"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
          <template v-slot:item.action="{ item }">
            <v-btn class="primary" @click="selectNfse(item)">Selecionar</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import GridField from "../fields/GridField.vue";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "card-search-nfse",
  props: ["processRef", "showAction"],
  components: {
    GridField,
  },
  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 50,
      serverItemsLength: 15,
      nfses: [],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    headers: {
      get() {
        let headers = [
          {
            text: "Id",
            value: "id",
            width: "20px",
            sortable: true,
          },
          {
            text: "Prestador",
            value: "PrestadorRazaoSocial",
            align: "start",
            sortable: false,
          },
          {
            text: "Data emissão",
            value: "DataEmissao",
            align: "start",
            sortable: false,
          },
          //{ text: "Pdf link", value: "pdfLink", sortable: false },

          { text: "Ref", value: "processRef", sortable: false },

          {
            text: "Valor Serviços",
            value: "ServicoValorServicos",
            sortable: false,
          },
          { text: "Número", value: "Numero", sortable: false },
          // { text: "Ação", value: "action", sortable: false },
        ];

        if (this.showAction) {
          headers.push({ text: "Ação", value: "action", sortable: false });
        }
        return headers;
      },
    },
  },

  async mounted() {
    this.search = this.processRef;

    await this.doSearch();
  },
  methods: {
    selectNfse(nfse) {
      this.$emit("select", nfse);
    },

    closeError() {
      this.$emit("closeError");
    },

    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("do search");
      const { values, pagination } = await strapiApi.nfses.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.processId,
        this.search
      );

      //alert(JSON.stringify({ values, pagination }));
      this.nfses = values;
      //alert(JSON.stringify({  pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>
