<template>
  <div>
    <v-row align="center" class="ma-0">
      <v-col align="left" cols="6" class="ma-0">
        <p class="ma-0">{{ label1 }}</p></v-col
      >
      <v-col align="right" cols="6" class="ma-0"
        ><p class="ma-0">{{ label2 }}</p></v-col
      >
    </v-row>
    <v-row align="center" class="ma-0">
      <v-progress-linear
        height="25"
        :value="valueFormated"
        color="#229dce"
      ></v-progress-linear>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["value", "max", "label1", "label2", "color"],
  data() {
    return {
      //value: 12,
    };
  },
  computed: {
    valueFormated() {
      var value = this.value ? this.value : 0;
      var max = this.max ? this.max : 0;

      if (max > 0) return (value * 100) / max;
      else return 0;
    },
  },
};
</script>
