<template>
  <v-container fluid>
    <ActivateTrackingDialog
      v-model="showDialogTracking"
      :showError="showTrackingError"
      @closeError="closeTrackingError"
      :errorMessage="trackinErrorMessage"
      :containers="
        vb_containers &&
        vb_containers.map((i) => ({
          containerNumber: i.CNT_ESP_NUMERO,
        }))
      "
      :blContainersRef="transport && transport.CNT_ESP_NUMERO_HOUSE"
      @yes="createTracking"
    ></ActivateTrackingDialog>
    <v-container fluid style="min-height: 100vh" v-if="loadingProcess">
      <v-progress-circular
        v-show="loadingProcess"
        indeterminate
        color="secondary"
      ></v-progress-circular>

      <v-spacer></v-spacer>
    </v-container>
    <!-- <a
      href="https://assets-global.website-files.com/63235e08d659e25502559daf/63235e44d40246d10737645b_logo-corsolar-negativo.svg"
      title="test"
      target="_blank"
      >Download</a
    > -->
    <v-container
      fluid
      v-if="!loadingProcess && error"
      style="height: 100vh; width: 100%"
    >
      <v-row>
        <h4 style="margin: auto">Acesso não autorizado</h4>
      </v-row>
      <v-row>
        <p style="margin: auto">Por favor entre em contato com o suporte</p>
      </v-row>
      <v-row>
        <img
          width="600px"
          src="@/assets/img/banner4.png"
          style="margin: auto"
        />
      </v-row>
    </v-container>

    <v-container fluid style="" v-if="!loadingProcess && !error">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-card class="mx-auto ma-0 pa-0" style="height: 100%">
            <v-card-text>
              <p class="text-h5">Referência</p>

              <v-divider></v-divider>
              <p class="text-h4 mt-2">
                {{ process?.PRI_ESP_REFERENCIA ?? "" }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-card class="mx-auto mt-0" style="height: 100%">
            <v-card-text>
              <p class="text-h5">Referência Sainte Marie</p>
              <v-divider></v-divider>
              <p class="text-h4 mt-2">
                {{ process?.PRI_ESP_REFCLIENTE }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto mt-0" style="height: 100%">
            <v-card-text>
              <p class="text-h5">Pessoa</p>

              <v-divider></v-divider>
              <p class="text-h4 mt-2">
                {{ process && process.DPE_NOM_PESSOA }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card class="mx-auto mt-0" style="height: 100%">
            <v-card-text>
              <p class="text-h5">Encomendante</p>
              <v-divider></v-divider>
              <p
                v-if="user?.email !== 'demo@stmarie.com.br'"
                class="text-h4 mt-2"
              >
                {{ process && process.DPE_NOM_PESSOA_LIG }}
              </p>
              <p v-else class="text-h4 mt-2">
                {{
                  process &&
                  (process.DPE_NOM_PESSOA_LIG.includes("ARTEX")
                    ? "DEMO"
                    : process.DPE_NOM_PESSOA_LIG)
                }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Tracking -->
    <v-container fluid style="" v-if="!loadingProcess && !error">
      <v-card class="my-0"
        ><v-tabs color="primary accent-4" active-class="primary white--text">
          <v-tab style="background-color: lightgray">Timeline</v-tab>
          <v-tab style="background-color: lightgray" @click="mapTabClicked"
            >Map</v-tab
          >
          <v-tab-item style="">
            <HorizontalTimeline :items="timelineEvents"></HorizontalTimeline>
          </v-tab-item>

          <v-tab-item>
            <iframe
              v-if="tracking && tracking.containerNumber"
              :src="`https://shipsgo.com/iframe/where-is-my-container/${tracking.containerNumber}`"
              id="IframeShipsgoLiveMap"
              :key="iframeUpdateKey"
              style="height: 550px; width: 100%"
            ></iframe>

            <v-container v-else fluid style="min-height: 360px">
              <v-row class="my-5">
                <v-spacer></v-spacer>
                <span>Ative o tracking para rastrear seu container</span>
                <v-spacer></v-spacer>
              </v-row>

              <v-row class="my-5">
                <v-spacer></v-spacer>
                <v-img
                  style="max-width: 200px"
                  src="https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/tracking.png"
                ></v-img>
                <v-spacer></v-spacer>
              </v-row>

              <v-row v-if="!simpleProcessesView">
                <v-spacer></v-spacer>
                <v-btn class="primary" @click="openTrackingDialog"
                  >Ativar</v-btn
                >
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </v-tab-item> </v-tabs
      ></v-card>
    </v-container>

    <v-container fluid v-if="!loadingProcess && !error && !simpleProcessesView">
      <v-card class="my-0">
        <v-tabs
          style="min-height: 600px"
          color="primary accent-4"
          active-class="primary white--text"
        >
          <v-tab style="background-color: lightgray">Invoices</v-tab>
          <v-tab style="background-color: lightgray">Conhecimento</v-tab>
          <v-tab style="background-color: lightgray">Câmbio</v-tab>
          <v-tab style="background-color: lightgray">Documentos</v-tab>

          <v-tab v-if="adminHomolog" style="background-color: lightgray"
            >Despesas (adm-homolog)
          </v-tab>
          <v-tab v-if="adminHomolog" style="background-color: lightgray"
            >NFSE's (adm-homolog)</v-tab
          >

          <!-- Invoices -->
          <v-tab-item>
            <ProcessInvoices
              :invoices="invoices"
              :invoices_items="invoices_items"
            ></ProcessInvoices>
          </v-tab-item>

          <!-- Conhecimento -->
          <v-tab-item>
            <ProcessConhecimento
              :transport="transport"
              :vb_containers="vb_containers"
            ></ProcessConhecimento>
          </v-tab-item>

          <!-- Câmbio -->
          <v-tab-item>
            <div
              v-for="(invoice, indexInvoice) in invoices"
              :key="indexInvoice"
            >
              <ProcessParcelas
                :fil-cod="filCod"
                :inv-cod="invoice.INV_COD"
                :invoice="invoice"
              ></ProcessParcelas>
            </div>
          </v-tab-item>

          <!-- Documentos -->
          <v-tab-item>
            <v-container fluid style="display: flex" class="ma-0">
              <v-row>
                <v-col cols="3">
                  <v-card class="ma-0" style="height: 100%">
                    <v-toolbar dark color="primary"><h4>Pastas</h4> </v-toolbar>
                    <v-progress-circular
                      v-show="loadingFolders"
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                    <v-card-text class="mt-0">
                      <v-list-item
                        v-for="(arq, index2) in folders"
                        :key="index2"
                        :value="arq.label"
                        active-color="primary"
                        variant="plain"
                        @click="listFiles(arq)"
                      >
                        <v-row>
                          <v-col cols="2" class="pa-1">
                            <v-icon v-text="'mdi-folder'"></v-icon>
                          </v-col>
                          <v-col cols="10" class="pa-1 mb-5">
                            <span>{{ arq.label }}</span>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="9">
                  <v-card
                    class="ma-0 ml-6"
                    style="margin-bottom: 20px; height: 100%"
                  >
                    <v-toolbar dark color="primary">
                      <h4>Arquivos</h4>
                    </v-toolbar>
                    <v-progress-circular
                      v-show="loadingFiles"
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                    <v-card-text v-if="!loadingFiles && files">
                      <v-list-item
                        v-for="(file, indexFile) in files"
                        :key="indexFile"
                        :value="file.binEspName"
                        active-color="primary"
                        variant="plain"
                        @click="requestFile(file)"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="'mdi-file-download'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-title>
                          <span>{{ file.binEspName }}</span>
                        </v-list-item-title>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
            <v-progress-circular
              v-show="loadingFile"
              indeterminate
              color="secondary"
            ></v-progress-circular>
            <v-container fluid v-show="!loadingFile && fileSrc && showFile">
              <div class="holds-the-iframe">
                <iframe
                  style="min-height: 600px; width: 100%; zoom: 100%"
                  :src="fileSrc"
                ></iframe>
              </div> </v-container
          ></v-tab-item>

          <!-- Despesas -->
          <v-tab-item v-if="adminHomolog">
            <ProcessExpenses
              :pri-cod="priCod"
              :fil-cod="filCod"
              :nfses="nfses"
              :servicos="servicos"
              :process="process"
            ></ProcessExpenses>
          </v-tab-item>

          <!-- Nfses -->
          <v-tab-item v-if="adminHomolog">
            <ProcessNfses
              :process-id="process?.id"
              :process-ref="process?.PRI_ESP_REFCLIENTE"
            ></ProcessNfses>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import conexosStrapiApi from "../../../../libraries/conexos/conexosStrapiApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import { VueHorizontalTimeline } from "vue-horizontal-timeline";
import moment from "moment";
import Map from "../../components/maps/Map.vue";
import GridField from "../../components/fields/GridField.vue";
import { mapState } from "vuex";
import ActivateTrackingDialog from "../../components/dialogs/ActivateTrackingDialog.vue";
import shipsgoApi from "../../../../libraries/shipsgo/shipsgoApi";
import HorizontalTimeline from "../../components/timelines/HorizontalTimeline.vue";
import ProcessExpenses from "./ProcessExpenses.vue";
import ProcessNfses from "./ProcessNfses.vue";
import ProcessParcelas from "./ProcessParcelas.vue";
import ProcessInvoices from "./ProcessInvoices.vue";
import ProcessConhecimento from "./ProcessConhecimento.vue";
export default {
  name: "Process",
  props: ["priCod"],
  components: {
    VueHorizontalTimeline,
    Map,
    GridField,
    ActivateTrackingDialog,
    HorizontalTimeline,
    ProcessExpenses,
    ProcessNfses,
    ProcessParcelas,
    ProcessInvoices,
    ProcessConhecimento,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    filCod() {
      return this.$route.query.filCod;
    },

    simpleProcessesView() {
      return this.user?.role?.id == 9;
    },

    adminHomolog() {
      return (
        `${process.env.VUE_APP_ENV}` == "HOMOLOG" && this.user?.role?.id == 6
      );
    },
    homolog() {
      return `${process.env.VUE_APP_ENV}` == "HOMOLOG";
    },
  },
  data() {
    return {
      events: [1, 2, 3, 4],
      error: false,
      enterprise: {},
      emails: [],
      tracking: {},
      showDialogTracking: false,
      showTrackingError: false,
      trackinErrorMessage: "",
      items: [],
      timelineEvents: [],
      srcMap: "",
      showMap: false,
      invoices: [],
      invoices_items: [],

      process: {},
      transport: {},
      vb_containers: [],
      info: {},
      arq020: [],
      files: [],

      dcmCod: "",
      fileData64: "",
      fileSrc: "",
      fileInfo: {},
      adfCodSeq: 0,
      loadingProcess: true,
      loadingFolders: true,
      loadingFiles: false,

      folders: [],
      loadingFile: false,
      showFile: false,
      unsubscribeArq020: {},
      unsubscribeArqDocFiles: {},
      unsubscribeDownload: {},
      iframeUpdateKey: false,
      di: {},
      nfses: [],
      events: [],
      servicos: [],
    };
  },
  async mounted() {
    window.conexosUsnCod = 659;

    let process = await strapiApi.processes.getByCod(
      parseInt(this.priCod),
      parseInt(this.$route.query.filCod)
    );

    let ansImp215 = await conexosStrapiApi.imp216List(this.priCod, this.filCod);

    console.log(JSON.stringify({ ansImp215 }));

    this.events = ansImp215?.rows;

    await this.loadNfses(process?.id);

    const adminView =
      this.user?.role?.id == 1 ||
      this.user?.role?.id == 6 ||
      this.user?.role?.id == 7;

    if (this.simpleProcessesView) {
      let foundProcess = this.user?.processes.find((el) => el.id == process.id);
      if (!foundProcess) {
        this.loadingProcess = false;
        this.error = true;
        return;
      }
    } else {
      if (!adminView) {
        let foundEnterprise = this.user?.enterprises.find(
          (el) => el.dpeNomPessoa == process.DPE_NOM_PESSOA_LIG
        );
        if (!foundEnterprise) {
          this.loadingProcess = false;
          this.error = true;
          return;
        }
      }
    }

    this.process = process;

    this.enterprise = await strapiApi.enterprises.getByName(
      process.DPE_NOM_PESSOA_LIG
    );

    let usersToNotify = this.enterprise?.users.filter(
      (el) => el.emailNotificationActive
    );
    this.emails = usersToNotify?.map((el) => el.email);

    this.transport = this.process.transports && this.process.transports[0];
    if (this.transport && this.transport.CNT_COD) {
      this.vb_containers = this.transport.containers;
      this.tracking = await strapiApi.trackings.getByPriCodAndFilCod(
        this.priCod,
        this.filCod
      );
    }

    this.di = this.process.dis?.[0];

    if (this.di?.DEPACHO_DTADESEMBARACO)
      this.process.DEPACHO_DTADESEMBARACO = this.di.DEPACHO_DTADESEMBARACO;
    //alert("di");
    let myEvent = this.events?.find(
      (el) => el?.etpDesnome == "DESEMBARAÇO D.I."
    );
    //alert(JSON.stringify({ myEvent }));
    if (myEvent) this.process.DEPACHO_DTADESEMBARACO = myEvent?.etpDtaOcorr;
    this.generateTimeLineItems(this.process);
    let invoices_items = [];
    let invoices = [];
    if (this.process) {
      invoices = process.invoices;
      invoices.sort((a, b) => a?.INV_COD - b?.INV_COD);
      for (var i = 0; i < invoices.length; i++) {
        const invoice = invoices[i];
        invoice.items.sort((a, b) => a?.INI_ITEM - b?.INI_ITEM);
        invoice.items.sort((a, b) => a?.INV_COD - b?.INV_COD);
        const items = invoice.items;

        if (items.length > 0) {
          invoices_items = invoices_items.concat(items);
        }
      }

      // invoices_items.sort((a, b) => a?.INI_ITEM - b?.INI_ITEM);
      // invoices_items.sort((a, b) => a?.INV_COD - b?.INV_COD);
      this.invoices = invoices;
      this.invoices_items = invoices_items;
      this.loadingProcess = false;
      // this.loadFolders();
      this.arq020 = {};
      //GET FROM NEW BACKEND
      var data = await conexosStrapiApi.arq020List(
        this.process.FIL_COD,
        this.process.PRI_COD
      );

      this.arq020 = data;
      let folders = data && data[0] && data[0].children;

      //29/11/23-Filter folders

      this.folders = folders?.filter(
        (folder) =>
          folder?.label?.toUpperCase()?.includes("EXTRATOS SISCARGA") ||
          folder?.label?.toUpperCase()?.includes("EXTRATO:") ||
          folder?.label?.toUpperCase() == "NFE" ||
          folder?.label?.toUpperCase()?.includes("CI:")
      );

      // this.folders = folders;

      this.loadingFolders = false;
    } else {
      this.loadingFolders = false;
      this.loadingProcess = false;
    }
  },
  methods: {
    async loadNfses(processId) {
      //nfses

      //alert("load:" + processId);
      const { values, pagination } = await strapiApi.nfses.getWithPagination(
        1,
        100,
        null,
        null,
        processId
      );

      this.nfses = values;

      let servicos = [];

      for (var i = 0; i < values.length; i++) {
        let value = values[i];
        if (value?.nfse_servicos?.length > 0) {
          let nfse_servicos = value?.nfse_servicos;

          for (var j = 0; j < nfse_servicos.length; j++) {
            let nfse_servico = nfse_servicos[j];
            //Adicionar serviços
            servicos.push({
              Nfse: value.Numero,
              Prestador: value.PrestadorRazaoSocial,
              DataEmissao: value.DataEmissao,
              Descricao: nfse_servico.Descricao,
              ValorServico: nfse_servico.ValorServico,
            });
          }
        } else {
          // //Adicionar serviços
          // servicos.push({
          //   Nfse: value.Numero,
          //   Prestador: value.PrestadorRazaoSocial,
          //   DataEmissao: value.DataEmissao,
          //   Descricao: value.ServicoDiscriminacao,
          //   ValorServico: value.ServicoValorServicos,
          // });
        }
      }

      this.servicos = servicos;
    },

    mapTabClicked() {
      this.iframeUpdate();
    },
    iframeUpdate() {
      this.iframeUpdateKey = !this.iframeUpdateKey;
    },

    closeTrackingError() {
      this.showTrackingError = false;
      this.trackinErrorMessage = "";
    },

    closeAlerts() {
      this.showTrackingError = false;
      this.trackinErrorMessage = "";
    },

    async createTracking(data) {
      var emailAddress = this.user?.email;
      if (this.emails.length > 0) emailAddress += `,${this.emails.join(",")}`;

      emailAddress += ",lucas.issayama@grupomelocordeiro.com.br";
      const { containerNumber, blContainersRef } = data;

      let requestId = await shipsgoApi.createTrackingWithContainer(
        containerNumber,
        emailAddress
      );
      if (requestId.Message) {
        this.showTrackingError = true;
        this.trackinErrorMessage = requestId.Message;
        setTimeout(this.closeAlerts, 2000);
        return;
      }
      var enterpriseId = parseInt(this.process?.PES_COD_LIG);
      //Salvar no banco
      var tracking = {
        filCod: this.process.FIL_COD,
        priCod: this.process.PRI_COD,
        containerNumber,
        blContainersRef,
        emailAddress,
        requestId,
        user: this.user?.id,
        enterprise: enterpriseId,
        process: parseInt(this.process?.id),
      };
      var ans = await strapiApi.trackings.post(tracking);
      this.showDialogTracking = false;
      this.tracking = tracking;
    },

    openTrackingDialog() {
      /// alert("called");
      this.showDialogTracking = true;
    },
    formatDate(value) {
      if (value) return moment(value).format("DD/MM/YYYY");
      else return "";
    },

    async listFiles(data) {
      this.dcmCod = data.data.dcmCod;
      this.loadingFiles = true;
      this.showFile = false;

      this.files = [];
      this.arq020 = {};
      let ans = await conexosStrapiApi.arqDocFilesList(
        this.process.FIL_COD,
        this.dcmCod
      );
      this.files = ans && ans && ans.rows;
      this.loadingFiles = false;
    },

    async requestFile(fileInfo) {
      this.fileInfo = fileInfo;
      this.dcmCod = fileInfo.dcmCod;
      this.adfCodSeq = fileInfo.adfCodSeq;

      var self = this;
      this.loadingFile = true;
      this.showFile = false;
      this.fileSrc = "";

      this.arq020 = {};
      // let ans = await conexosStrapiApi.arqDocFilesRequest(
      //   this.process.FIL_COD,
      //   this.dcmCod,
      //   this.adfCodSeq
      // );

      // this.fileData64 = ans;
      // //alert(JSON.stringify({ ans }));
      // let filename = ans?.responseData?.filename;
      // let url = ans?.responseData?.url;
      // //alert(JSON.stringify({ filename, url }));
      // if (filename.toLowerCase().includes(".pdf"))
      //   //this.fileSrc = `data:application/pdf;base64,${this.fileData64}#view=FitH`;
      //   this.fileSrc = url;
      // else {
      //   window.open(url);
      //   // let ans = await this.getData64File(url);

      //   // var reader = new FileReader();

      //   // reader.onload = function () {
      //   //   var base64data = reader.result; // The base64-encoded file data
      //   //   var a = document.createElement("a"); //Create <a>
      //   //   a.href = base64data; //Image Base64 Goes here
      //   //   a.download = `${filename}`;
      //   //   a.click();
      //   // };
      //   // reader.readAsDataURL(ans);
      // }
      let ans = await conexosStrapiApi.arqDocFilesDownload(
        this.process.FIL_COD,
        this.dcmCod,
        this.adfCodSeq
      );

      console.log(JSON.stringify({ ans }));

      this.fileData64 = ans?.data;
      let filename = ans?.filename;

      if (filename?.toLowerCase().includes(".pdf"))
        this.fileSrc = `data:application/pdf;base64,${this.fileData64}#view=FitH`;
      else {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:application;base64," + this.fileData64; //Image Base64 Goes here
        a.download = `${filename}`;

        a.click();
      }

      this.loadingFile = false;
      this.showFile = true;
    },

    async getData64File(url) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      let ans = await fetch(url, requestOptions)
        .then((res) => {
          console.log(JSON.stringify({ res }));
          return res.blob();
        })
        .then((json) => {
          console.log(json);
          return json;
        });
      console.log(JSON.stringify({ ans }));
      return ans;
    },

    generateTimeLineItems(process) {
      var items = [];
      //Abertura

      let events = [
        {
          text: "Abertura do processo",
          dateField: "PRI_DTA_ABERTURA",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/folder-open.png",
        },

        {
          text: "Embarque",
          dateField: "DTA_EMBARQUE_BL",
          expectedDateField: "DTA_PREV_EMBARQUE_BL",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/ship.png",
        },
        {
          text: "Chegada",
          dateField: "DTA_CHEGADA_BL",
          expectedDateField: "DTA_PREV_CHEGADA_BL",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/finish.png",
        },

        {
          text: "Registro DI",
          dateField: "REGISTRO_DI",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/file-check.png",
        },
        //Desembaraço

        {
          text: "Desembaraço",
          dateField: "DEPACHO_DTADESEMBARACO",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/stamp.png",
        },
        {
          text: "NF de Entrada",
          dateField: "DTA_EMISS_1ENTRADA",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/qr-code.png",
        },
        {
          text: "NF de Saída",
          dateField: "DTA_EMISS_1SAIDA",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/truck.png",
        },
        {
          text: "Fechamento do processo",
          dateField: "DTA_FECH_OPERACIONAL",
          expectedDateField: "",
          imagePath:
            "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/stmarie/icons/handshake.png",
        },
      ];

      for (var a = 0; a < events.length; a++) {
        var dateValue = new Date(
          process && process[events[a].dateField]
        ).getTime();

        if (dateValue) {
          events[a].date = dateValue;
        }
        var expectedDate = new Date(
          process && process[events[a].expectedDateField]
        ).getTime();

        if (expectedDate) {
          events[a].expectedDate = expectedDate;
        }
      }

      this.timelineEvents = events;
    },
  },
};
</script>

<style scoped>
.tabActive {
  background-color: #3e8ac7;
}

.holds-the-iframe {
  background: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif")
    center center no-repeat;
}

.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}
</style>
