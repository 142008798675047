var countries = [
  {
    code: "AD",
    value: "ANDORRA",
  },
  {
    code: "AE",
    value: "EMIRADOS ARABES UNIDOS",
  },
  {
    code: "AF",
    value: "AFEGANISTAO",
  },
  {
    code: "AG",
    value: "ANTIGUA E BARBUDA",
  },
  {
    code: "AI",
    value: "ANGUILLA",
  },
  {
    code: "AL",
    value: "ALBANIA",
  },
  {
    code: "AM",
    value: "ARMENIA",
  },
  {
    code: "AO",
    value: "ANGOLA",
  },
  {
    code: "AQ",
    value: "ANTARTIDA",
  },
  {
    code: "AR",
    value: "ARGENTINA",
  },
  {
    code: "AS",
    value: "SAMOA AMERICANA",
  },
  {
    code: "AT",
    value: "AUSTRIA",
  },
  {
    code: "AU",
    value: "AUSTRALIA",
  },
  {
    code: "AW",
    value: "ARUBA",
  },
  {
    code: "AZ",
    value: "AZERBAIJAO",
  },
  {
    code: "BA",
    value: "BOSNIA E HERZEGOVINA",
  },
  {
    code: "BB",
    value: "BARBADOS",
  },
  {
    code: "BD",
    value: "BANGLADESH",
  },
  {
    code: "BE",
    value: "BELGICA",
  },
  {
    code: "BF",
    value: "BURKINA FASO",
  },
  {
    code: "BG",
    value: "BULGARIA",
  },
  {
    code: "BH",
    value: "BAHREIN",
  },
  {
    code: "BH",
    value: "BAHREIN, ILHAS",
  },
  {
    code: "BI",
    value: "BURUNDI",
  },
  {
    code: "BJ",
    value: "BENIM",
  },
  {
    code: "BL",
    value: "SAO BARTOLOMEU",
  },
  {
    code: "BM",
    value: "BERMUDAS",
  },
  {
    code: "BN",
    value: "BRUNEI",
  },
  {
    code: "BO",
    value: "BOLIVIA",
  },
  {
    code: "BQ",
    value: "PAISES BAIXOS CARIBENHOS",
  },
  {
    code: "BR",
    value: "BRASIL",
  },
  {
    code: "BS",
    value: "BAHAMAS",
  },
  {
    code: "BT",
    value: "BUTAO",
  },
  {
    code: "BW",
    value: "BOTSWANA",
  },
  {
    code: "BY",
    value: "BIELORRUSSIA",
  },
  {
    code: "BZ",
    value: "BELIZE",
  },
  {
    code: "CA",
    value: "CANADA",
  },
  {
    code: "CC",
    value: "ILHAS COCOS",
  },
  {
    code: "CD",
    value: "REPUBLICA DEMOCRATICA DO CONGO",
  },
  {
    code: "CF",
    value: "REPUBLICA CENTRO-AFRICANA",
  },
  {
    code: "CG",
    value: "REPUBLICA DO CONGO",
  },
  {
    code: "CH",
    value: "SUICA",
  },
  {
    code: "CI",
    value: "COSTA DO MARFIM",
  },
  {
    code: "CK",
    value: "ILHAS COOK",
  },
  {
    code: "CL",
    value: "CHILE",
  },
  {
    code: "CM",
    value: "CAMAROES",
  },
  {
    code: "CN",
    value: "CHINA, REPUBLICA POPULAR",
  },
  {
    code: "CO",
    value: "COLOMBIA",
  },
  {
    code: "CR",
    value: "COSTA RICA",
  },
  {
    code: "CU",
    value: "CUBA",
  },
  {
    code: "CV",
    value: "CABO VERDE",
  },
  {
    code: "CW",
    value: "CURACAO",
  },
  {
    code: "CX",
    value: "ILHA CHRISTMAS",
  },
  {
    code: "CY",
    value: "CHIPRE",
  },
  {
    code: "CZ",
    value: "TCHEQUIA",
  },
  {
    code: "DE",
    value: "ALEMANHA",
  },
  {
    code: "DJ",
    value: "DJIBOUTI",
  },
  {
    code: "DK",
    value: "DINAMARCA",
  },
  {
    code: "DM",
    value: "DOMINIQUE",
  },
  {
    code: "DO",
    value: "REPUBLICA DOMINICANA",
  },
  {
    code: "DZ",
    value: "ARGELIA",
  },
  {
    code: "EC",
    value: "EQUADOR",
  },
  {
    code: "EE",
    value: "ESTONIA",
  },
  {
    code: "EG",
    value: "EGITOE",
  },
  {
    code: "EG",
    value: "EGITO",
  },
  {
    code: "EH",
    value: "REPUBLICA DEMOCRATICA ARABE SAARIANA",
  },
  {
    code: "ER",
    value: "ERITREIA",
  },
  {
    code: "ES",
    value: "ESPANHA",
  },
  {
    code: "ET",
    value: "ETIOPIA",
  },
  {
    code: "FI",
    value: "FINLANDIA",
  },
  {
    code: "FI",
    value: "FINLÂNDIA",
  },

  {
    code: "FJ",
    value: "FIJI",
  },
  {
    code: "FK",
    value: "ILHAS MALVINAS",
  },
  {
    code: "FM",
    value: "MICRONESIA",
  },
  {
    code: "FO",
    value: "ILHAS FEROE",
  },
  {
    code: "FR",
    value: "FRANCA",
  },
  {
    code: "FR",
    value: "FRANÇA",
  },
  {
    code: "GA",
    value: "GABAO",
  },
  {
    code: "GB",
    value: "REINO UNIDO",
  },
  {
    code: "GD",
    value: "GRENADA",
  },
  {
    code: "GE",
    value: "GEORGIA",
  },
  {
    code: "GF",
    value: "GUIANA FRANCESA",
  },
  {
    code: "GG",
    value: "GUERNSEY",
  },
  {
    code: "GH",
    value: "GANA",
  },
  {
    code: "GI",
    value: "GIBRALTAR",
  },
  {
    code: "GL",
    value: "GROELANDIA",
  },
  {
    code: "GM",
    value: "GAMBIA",
  },
  {
    code: "GN",
    value: "GUINE",
  },
  {
    code: "GP",
    value: "GUADALUPE",
  },
  {
    code: "GQ",
    value: "GUINE EQUATORIAL",
  },
  {
    code: "GR",
    value: "GRECIA",
  },
  {
    code: "GS",
    value: "ILHAS GEORGIA E SANDWICH DO SUL",
  },
  {
    code: "GT",
    value: "GUATEMALA",
  },
  {
    code: "GU",
    value: "GUAM",
  },
  {
    code: "GW",
    value: "GUINE-BISSAU",
  },
  {
    code: "GY",
    value: "GUIANA",
  },
  {
    code: "HK",
    value: "HONG KONG",
  },
  {
    code: "HN",
    value: "HONDURAS",
  },
  {
    code: "HR",
    value: "CROACIA",
  },
  {
    code: "HR",
    value: "CROACIA (REPUBLICA DA)",
  },
  {
    code: "HT",
    value: "HAITI",
  },
  {
    code: "HU",
    value: "HUNGRIA",
  },
  {
    code: "HU",
    value: "HUNGRIA, REPUBLICA DA",
  },
  {
    code: "ID",
    value: "INDONESIA",
  },
  {
    code: "IE",
    value: "IRLANDA",
  },
  {
    code: "IL",
    value: "ISRAEL",
  },
  {
    code: "IM",
    value: "ILHA DE MAN",
  },
  {
    code: "IN",
    value: "INDIA",
  },
  {
    code: "IO",
    value: "TERRITORIO BRITANICO DO OCEANO INDICO",
  },
  {
    code: "IQ",
    value: "IRAQUE",
  },
  {
    code: "IR",
    value: "IRAO",
  },
  {
    code: "IS",
    value: "ISLANDIA",
  },
  {
    code: "IT",
    value: "ITALIA",
  },
  {
    code: "JE",
    value: "JERSEY",
  },
  {
    code: "JM",
    value: "JAMAICA",
  },
  {
    code: "JO",
    value: "JORDANIA",
  },
  {
    code: "JP",
    value: "JAPAO",
  },
  {
    code: "KE",
    value: "QUENIA",
  },
  {
    code: "KG",
    value: "QUIRGUISTAO",
  },
  {
    code: "KH",
    value: "CAMBOJA",
  },
  {
    code: "KI",
    value: "KIRIBATI",
  },
  {
    code: "KM",
    value: "COMORES",
  },
  {
    code: "KN",
    value: "SAO CRISTOVAO E NEVIS",
  },
  {
    code: "KP",
    value: "COREIA DO NORTE",
  },
  {
    code: "KP",
    value: "COREIA (DO NORTE), REP.POP.DEMOCRATICA",
  },
  {
    code: "KR",
    value: "COREIA DO SUL",
  },
  {
    code: "KR",
    value: "COREIA (DO SUL), REPUBLICA DA",
  },
  {
    code: "KW",
    value: "KUWAIT",
  },
  {
    code: "KY",
    value: "ILHAS CAYMAN",
  },
  {
    code: "KZ",
    value: "CAZAQUISTAO",
  },
  {
    code: "LA",
    value: "LAOS",
  },
  {
    code: "LB",
    value: "LIBANO",
  },
  {
    code: "LC",
    value: "SANTA LUCIA",
  },
  {
    code: "LI",
    value: "LIECHTENSTEIN",
  },
  {
    code: "LK",
    value: "SRI LANKA",
  },
  {
    code: "LR",
    value: "LIBERIA",
  },
  {
    code: "LS",
    value: "LESOTO",
  },
  {
    code: "LT",
    value: "LITUANIA",
  },
  {
    code: "LT",
    value: "LITUANIA, REPUBLICA DA",
  },
  {
    code: "LU",
    value: "LUXEMBURGO",
  },
  {
    code: "LV",
    value: "LETONIA",
  },
  {
    code: "LY",
    value: "LIBIA",
  },
  {
    code: "MA",
    value: "MARROCOS",
  },
  {
    code: "MC",
    value: "MONACO",
  },
  {
    code: "MD",
    value: "MOLDAVIA",
  },
  {
    code: "ME",
    value: "MONTENEGRO",
  },
  {
    code: "MF",
    value: "SAO MARTINHO",
  },
  {
    code: "MG",
    value: "MADAGASCAR",
  },
  {
    code: "MH",
    value: "ILHAS MARSHALL",
  },
  {
    code: "MK",
    value: "MACEDONIA",
  },
  {
    code: "ML",
    value: "MALI",
  },
  {
    code: "MM",
    value: "MIANMAR",
  },
  {
    code: "MN",
    value: "MONGOLIA",
  },
  {
    code: "MO",
    value: "MACAU",
  },
  {
    code: "MP",
    value: "ILHAS MARIANAS SETENTRIONAIS",
  },
  {
    code: "MQ",
    value: "MARTINICA",
  },
  {
    code: "MR",
    value: "MAURITANIE",
  },
  {
    code: "MS",
    value: "MONTSERRAT",
  },
  {
    code: "MT",
    value: "MALTA",
  },
  {
    code: "MU",
    value: "MAURICIA",
  },
  {
    code: "MV",
    value: "MALDIVAS",
  },
  {
    code: "MW",
    value: "MALAWI",
  },
  {
    code: "MX",
    value: "MEXICO",
  },
  {
    code: "MY",
    value: "MALASIA",
  },
  {
    code: "MZ",
    value: "MOCAMBIQUE",
  },
  {
    code: "NA",
    value: "NAMIBIA",
  },
  {
    code: "NC",
    value: "NOVA CALEDONIA",
  },
  {
    code: "NE",
    value: "NIGER",
  },
  {
    code: "NF",
    value: "ILHA NORFOLK",
  },
  {
    code: "NG",
    value: "NIGERIA",
  },
  {
    code: "NI",
    value: "NICARAGUA",
  },
  {
    code: "NL",
    value: "PAISES BAIXOS",
  },
  {
    code: "NL",
    value: "HOLANDA",
  },

  {
    code: "NO",
    value: "NORUEGA",
  },
  {
    code: "NP",
    value: "NEPAL",
  },
  {
    code: "NR",
    value: "NAURU",
  },
  {
    code: "NU",
    value: "NIUE",
  },
  {
    code: "NZ",
    value: "NOVA ZELANDIA",
  },
  {
    code: "OM",
    value: "OMA",
  },
  {
    code: "PA",
    value: "PANAMA",
  },
  {
    code: "PA",
    value: "ZONA DO CANAL DO PANAMA",
  },
  {
    code: "PE",
    value: "PERU",
  },
  {
    code: "PF",
    value: "POLINESIA FRANCESA",
  },
  {
    code: "PG",
    value: "PAPUA-NOVA GUINE",
  },
  {
    code: "PH",
    value: "FILIPINAS",
  },
  {
    code: "PK",
    value: "PAQUISTAO",
  },
  {
    code: "PL",
    value: "POLONIA",
  },
  {
    code: "PL",
    value: "POLONIA, REPUBLICA DA",
  },
  {
    code: "PM",
    value: "SAO PEDRO E MIQUELAO",
  },
  {
    code: "PN",
    value: "ILHAS PITCAIRN",
  },
  {
    code: "PR",
    value: "PUERTO RICO",
  },
  {
    code: "PS",
    value: "PALESTINA",
  },
  {
    code: "PT",
    value: "PORTUGAL",
  },

  {
    code: "PU",
    value: "PACIFICO,ILHAS DO (POSSESSAO DOS EUA)",
  },
  {
    code: "PW",
    value: "PALAU",
  },
  {
    code: "PY",
    value: "PARAGUAI",
  },
  {
    code: "QA",
    value: "CATAR",
  },
  {
    code: "RE",
    value: "REUNIAO",
  },
  {
    code: "RO",
    value: "ROMENIA",
  },
  {
    code: "RS",
    value: "SERVIA",
  },
  {
    code: "RU",
    value: "RUSSIA",
  },
  {
    code: "RU",
    value: "RUSSIA, FEDERACAO DA",
  },
  {
    code: "RW",
    value: "RUANDA",
  },
  {
    code: "SA",
    value: "ARABIA SAUDITA",
  },
  {
    code: "SB",
    value: "ILHAS SALOMAO",
  },
  {
    code: "SC",
    value: "SEICHELES",
  },
  {
    code: "SD",
    value: "SUDAO",
  },
  {
    code: "SE",
    value: "SUECIA",
  },
  {
    code: "SG",
    value: "SINGAPURA",
  },
  {
    code: "SG",
    value: "SINGAPORE",
  },
  {
    code: "SH",
    value: "SANTA HELENA, ASCENSAO E TRISTAO DA CUNHA",
  },
  {
    code: "SI",
    value: "ESLOVENIA",
  },
  {
    code: "SI",
    value: "ESLOVENIA, REPUBLICA DA",
  },
  {
    code: "SK",
    value: "ESLOVAQUIA",
  },
  {
    code: "SL",
    value: "SERRA LEOA",
  },
  {
    code: "SM",
    value: "SAN MARINO",
  },
  {
    code: "SN",
    value: "SENEGA",
  },
  {
    code: "SO",
    value: "SOMALIA",
  },
  {
    code: "SR",
    value: "SURINAME",
  },
  {
    code: "SS",
    value: "SUDAO DO SUL",
  },
  {
    code: "ST",
    value: "SAO TOME E PRINCIPE",
  },
  {
    code: "SV",
    value: "EL SALVADOR",
  },
  {
    code: "SX",
    value: "SINT-MAARTEN",
  },
  {
    code: "SY",
    value: "SIRIA",
  },
  {
    code: "SZ",
    value: "ESWATANI",
  },
  {
    code: "TC",
    value: "ILHAS TURCAS E CAICOS",
  },
  {
    code: "TD",
    value: "CHADE",
  },
  {
    code: "TF",
    value: "TERRAS AUSTRAIS E ANTARTICAS FRANCESAS",
  },
  {
    code: "TG",
    value: "TOGO",
  },
  {
    code: "TH",
    value: "TAILANDIA",
  },
  {
    code: "TJ",
    value: "TAJIQUISTAO",
  },
  {
    code: "TK",
    value: "TOKELAU",
  },
  {
    code: "TL",
    value: "TIMOR-LESTE",
  },
  {
    code: "TM",
    value: "TURQUEMENISTAO",
  },
  {
    code: "TN",
    value: "TUNISIA",
  },
  {
    code: "TO",
    value: "TONGA",
  },
  {
    code: "TR",
    value: "TURQUIA",
  },
  {
    code: "TT",
    value: "TRINDADE E TOBAG",
  },
  {
    code: "TV",
    value: "TUVALU",
  },
  {
    code: "TW",
    value: "FORMOSA (TAIWAN)",
  },
  {
    code: "TW",
    value: "TAIWAN",
  },
  {
    code: "TZ",
    value: "TANZANIA",
  },
  {
    code: "UA",
    value: "UCRANIA",
  },
  {
    code: "UG",
    value: "UGANDA",
  },
  {
    code: "US",
    value: "ESTADOS UNIDOS",
  },
  {
    code: "UY",
    value: "URUGUAI",
  },
  {
    code: "UZ",
    value: "UZBEQUISTAO",
  },
  {
    code: "VA",
    value: "VATICANO",
  },
  {
    code: "VC",
    value: "SAO VICENTE E GRANADINAS",
  },
  {
    code: "VE",
    value: "VENEZUELA",
  },
  {
    code: "VG",
    value: "ILHAS VIRGENS BRITANICAS",
  },
  {
    code: "VI",
    value: "ILHAS VIRGENS DOS ESTADOS UNIDOS",
  },
  {
    code: "VN",
    value: "VIETNA",
  },
  {
    code: "VN",
    value: "VIETNAM",
  },
  {
    code: "VU",
    value: "VANUATU",
  },
  {
    code: "WF",
    value: "WALLIS E FUTUNA",
  },
  {
    code: "WS",
    value: "SAMOA",
  },
  {
    code: "XK",
    value: "KOSOVO",
  },
  {
    code: "YE",
    value: "IEMEN",
  },
  {
    code: "YT",
    value: "MAIOTE",
  },
  {
    code: "ZA",
    value: "AFRICA DO SUL",
  },
  {
    code: "ZM",
    value: "ZAMBIA",
  },
  {
    code: "ZW",
    value: "ZIMBABWE",
  },
];

export default countries;
