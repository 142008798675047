var conexosStrapiApi = {
  async arq020List(filCod, priCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      priCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arq020List`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesList(filCod, dcmCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesList`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesDownload(filCod, dcmCod, adfCodSeq) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
      adfCodSeq,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesDownload`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesRequest(filCod, dcmCod, adfCodSeq) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
      adfCodSeq,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesRequest`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async log009ParcelasList(invCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/log009ParcelasList`;

    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;

    var raw = JSON.stringify({
      invCod: parseInt(invCod),
      filCod: parseInt(filCod),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async imp216List(priCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    console.log(JSON.stringify({ url }));
    // var myHeaders = {};
    // myHeaders["Content-Type"] = "application/json";
    // myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";
    // myHeaders["fil_cod"] = parseInt(filCod);
    // myHeaders["filcod"] = parseInt(filCod);

    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";

    let raw = {
      filCod: parseInt(filCod),
      url: "/api/imp216/list",
      fieldList: [],
      filterList: {
        filCod: parseInt(filCod),
        priCod: parseInt(priCod),
        etpVldVisivel: 1,
      },
      pageNumber: 1,
      pageSize: 20,
    };

    // var raw = JSON.stringify({
    //   filCod: 3,
    //   url: "/api/imp216/list",
    //   fieldList: [],
    //   filterList: {
    //     filCod: 3,
    //     priCod: 16287,
    //     etpVldVisivel: 1,
    //   },
    //   pageNumber: 1,
    //   pageSize: 20,
    // });

    console.log("raw");
    console.log(JSON.stringify(raw));
    console.log(JSON.stringify(raw));
    console.log("ans");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      "https://backend.stmarie.com.br/api/conexos/proxy",
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    console.log(JSON.stringify({ ans }));
    return ans;
  },
};

export default conexosStrapiApi;
