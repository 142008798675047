<template>
  <div>
    <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :label="label"
          persistent-hint
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="value"
        no-title
        @input="menu1 = false"
      ></v-date-picker>
    </v-menu>
    <p>
      <!-- Date in ISO format: <strong>{{ date }}</strong> -->
    </p>
  </div>
</template>

<script>
//  //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
export default {
  name: "date-picker",
  props: ["label", "value"],
  emits: ["update:modelValue"],
  computed: {
    value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  data: (vm) => ({
    dateFormatted: "",
    menu1: false,
    menu2: false,
  }),
  ////vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.myDate);
    },
    myDate: {
      get() {
        return this.value;
      },
      set(myDate) {
        this.$emit("input", myDate);
      },
    },
  },
  watch: {
    myDate(val) {
      this.dateFormatted = this.formatDate(this.myDate);
      //this.dateFormatted = this.formatDate(val)
      // this.value = this.dateFormatted;
    },
  },
  created() {
    this.dateFormatted = this.formatDate(this.myDate);
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped></style>
